import React from "react";
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const DataTable = ({props}) => {
    const getMuiTheme = () => createTheme(props.themeOptions);

    return(
        <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                data={props.data}
                columns={props.columns}
                options={props.options}
            />
        </ThemeProvider>
    )

};

export default DataTable;