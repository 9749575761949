import React from "react";
import { render } from "react-dom";
import { PublicClientApplication, EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { MSAL_CONFIG } from "./app.config";
import { AppContainer } from "react-hot-loader";
import configureStore, { history } from "./store/configureStore";
import Root from "./components/Root";
const store = configureStore();
// Chekcing in code as is for testing refresh token - test in dev with more files uploaded
// clean up after 

const msalInstance = new PublicClientApplication(MSAL_CONFIG);
// Default to using the first account if no account is active on page load
// if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//   // Account selection logic is app dependent. Adjust as needed for different use cases.
//   //console.log(msalInstance.getAllAccounts());
//   //msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
// }

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if ((event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account) {
    const account = event.payload.account;
    // console.log(`Logged in as ${account.username}`);
    msalInstance.setActiveAccount(account);
    // console.log("account in callback", account);
    // Extract expiration time from the `exp` claim in the ID token
    const expiresOn = account.idTokenClaims?.exp * 1000; // Convert to milliseconds   
    if (expiresOn) {
      // Schedule token refresh
      console.log("Scheduling token refresh...");
      scheduleTokenRefresh(account, expiresOn);
    } else {
      console.warn("Expiration time (exp) not found in ID token claims.");
    }
  }
});

function scheduleTokenRefresh(account, expiresOn) {
  const now = Date.now();
  const timeout = expiresOn - now - 5 * 60 * 1000;
  // Set to refresh 5 minutes before expiration

  // For testing, let's set the timeout to 10 seconds
  //const timeout = 3 * 1000; // 10 seconds
  if (timeout > 0) {
    setTimeout(() => refreshAccessToken(account), timeout);
  } else {
    console.warn("Token expiration is too soon or already passed. Immediate refresh recommended.");
    refreshAccessToken(account);
  }
}

function refreshAccessToken(account) {
  const request = {
    // scopes: ["User.Read"], // Replace with your required scopes
    account: account,
    //forceRefresh: true,
  };

  msalInstance.acquireTokenSilent(request)
    .then(response => {
      console.log("Token refreshed silently:", response);

      // Reschedule the next refresh using the new expiration time
      const newExpiresOn = response.idTokenClaims?.exp * 1000; // Convert to milliseconds
      if (newExpiresOn) {
        scheduleTokenRefresh(account, newExpiresOn);
      }
    })
    .catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(request);
        // No further code execution here because the redirect will change the page
      } else {
        console.error("Silent token acquisition error:", error);
        // Handle error (e.g., prompt user to log in again)
      }
    });
}


render(
  <AppContainer>
    <MsalProvider instance={msalInstance}>
      <Root store={store} history={history} />
    </MsalProvider>
  </AppContainer>,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept("./components/Root", () => {
    const NewRoot = require("./components/Root").default;
    render(
      <AppContainer>
        <MsalProvider instance={msalInstance}>
          <NewRoot store={store} history={history} />

        </MsalProvider>
      </AppContainer>,
      document.getElementById("app")
    );
  });
}