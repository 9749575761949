const i18nConfig = {
  locale: "en",
  messages: {
    "g.currentValue": "Current Value",
    "g.send": "Send",
    "g.yes": "Yes",
    "g.no": "No",
    "g.close": "Close",
    "g.cancel": "Cancel",
    "g.delete": "Delete",
    "g.required": "Required",
    "pipeline.cancelled": "Pipeline successfully cancelled.",

    "home.invalid.login": "Invalid login!",
    "home.login": "Login",
    "user.401": "Invalid or expired session, please login again",
    "user.registered": "User is registered successfully",

    "feedback.title": "Feedback",
    "feedback.description":
      "Looking for a feature? Find a bug? We want to hear from you. Please submit your comments using the form below.",
    "feedback.label": "Message",
    "feedback.placeholder": "Provide feedback",
    "feedback.sent": "Message was sent successfully",
    "feedback.notsent": "Message was not sent successfully",

    "tag.title": "Tag Management",
    "tag.label": "Tag Name",
    "tag.placeholder": "Provide a new tag name.",
    "tag.save": "Save",
    "tag.added": "Tag added successfully.",
    "tag.deleted": "Tag deleted successfully.",
    "tag.409": "Tag already exists",
    "tag.max": "Max of 20 tags are allowed per experiment",

    "profile.title": "Profile Information",
    "profile.username": "User Name",
    "profile.name": "Name",
    "profile.email": "Email",

    "pipeline.cancel": "Pipeline cancelled successfuly",

    "experiment.list.title": "Samples",
    "experiment.list.name": "Name",
    "experiment.list.owner": "Owner",
    "experiment.list.created": "Created",
    "experiment.list.pipeline": "Pipeline",
    "experiment.list.tags": "Tags",
    "experiment.list.actions": "Actions",
    "experiment.list.viewInactive": "View Inactive Samples",
    "experiment.list.viewActive": "View Active Samples",

    "experiment.add.createNew": "Create New Sample",
    "experiment.add.dropFiles":
      "Click or drop your FASTQ files here to upload.",
    "experiment.add.uploadSuccess":
      "{num, plural, one {# File} other {# Files}} uploaded successfully!",
    "experiment.add.uploadFailure":
      "Error: SeqSense was unable to complete sample file preparation. Select the files again and retry.",
    "experiment.add.copySuccess":
      "Success! Your files are ready for your next experiment.",
    "experiment.save.in.progress":
      "Saving your experiment ....",
    "experiment.add.bioinfopipeline": "Bioinformatics Pipeline",
    "experiment.add.start": "Start Pipeline",
    "experiment.add.cancel": "Cancel Process",
    "experiment.add.status": "Pipeline status",
    "experiment.add.finalOutput": "Final output has been generated.",
    "experiment.add.viewDetails": "View Sample Details",
    "experiment.add.error":
      "Experiment Name is already taken, please use a different Experiment Name",

    "experiment.detail.title": "Sample Detail",
    "experiment.detail.sampleStatus": "Status",
    "experiment.detail.retrievingStatus": "retrieving status...",
    "experiment.detail.sampleCreated": "Created",
    "experiment.detail.sampleName": "Name",
    "experiment.detail.pipelineInformation": "Pipeline Information",
    "experiment.detail.files": "Files",
    "experiment.detail.fileName": "File Name",
    "experiment.detail.fileSize": "File Size",
    "experiment.detail.downloadReports": "Download Reports",

    "experiment.modal.deleteTitle": "Sample Deletion",
    "experiment.modal.deleteConfirm":
      "Are you sure you want to delete this experiment?",
    "experiment.name": "Sample Name",

    "experiment.deleted": "Experiment deleted successfully",
    "experiment.updated": "Updated experiment successfully",
    "experiment.recovered": "Sample recovered successfully",
    "experiment.recovery.error": "Error recovering Sample",
    "experiment.tagAdded": "Successfully added tag to this experiment",
    "experiment.tagRemoved": "Successfully removed tag from this experiment",
    "experiment.409": "Experiment already exists",
    "experiment.404": "Status not available.",

    "sample.tagRemoved": "Successfully removed tag from this sample",
    "sample.tagAdded": "Successfully added tag to this sample",
    "sample.deleted": "Sample deleted successfully",

    "upload.removal": "File removed successfully!",
    "upload.error": "The upload has failed",
    "file.exist": "File(s) with the same name cannot be uploaded. Change the filename before uploading",
    "auth.error": "Authentication error. Please refresh the page and try again.",
    "large.file": "Depending on the file size, you may see 0% progress for a while. The upload is still processing in the background",

    "2d.skipUmi": "Skip UMI Processing? ",
    "2d.minMapqToCount": "Minimum MapQ Score To Count ",
    "2d.minBp": "Min Base Pairs Per Read ",
    "2d.noTrim": "Skip Read Trimming? ",
    "2d.species": "Species ",
    "2d.fivePrimeQualCutoff": "5' Read Quality Cutoff ",
    "2d.spikeType": "Spike in type ",
    "2d.threePrimeQualCutoff": "3' Read Quality Cutoff ",
    "2d.minGeneCutoff": "Minimum Threshold Genes Reported ",
    "2d.minGeneType": "Gene Expression Units ",
    "2d.seqType": "Sequencing Read Type Used ",
    "Sc.include_introns": "Include Introns ",

    "group.error": "Invalid user group. Please contact administrator",
  },
};

export default i18nConfig;
