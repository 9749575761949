import _ from "underscore";
//import { getAccessToken } from "../actions/Okta";
import { getAccessToken } from "../actions/Azure";

export function formatBytes(bytes, decimals) {
  if (bytes === 0) return "0 Bytes";
  var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

//EDIT: Null validation/default values for the function
export function uploadSpeed(endTime, startTime, fileSize, bitsLoaded) {
  var duration = (endTime - startTime) / 1000;
  var speedBps = (bitsLoaded / duration).toFixed(2);
  var speedKbps = (speedBps / 1024).toFixed(2);
  //var speedMbps = (speedKbps / 1024).toFixed(2);
  return parseInt(speedKbps / 1000, 10);
}

export function transferTimeLeft(fileSize, transferSpeed) {
  var timeSeconds = (fileSize * 1024) / (transferSpeed / 8);
  timeSeconds = parseFloat(Math.round(timeSeconds * 100) / 100, 10);
  return timeSeconds;
}

export default class {
  static hasProp(state, search) {
    var foundIt = _.has(state, search);
    return foundIt;
  }
}

export const parsePath = (url) => {
  return url.split("/")[1] || "home";
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const parsePageTitle = (url) => {
  let output = "";
  const urlArray = _.compact(url.split("/"));
  urlArray.map((slice) => {
    var temp = toTitleCase(slice);
    return (output = output + `${temp} `);
  });
  if (!output) {
    return "Home";
  }
  return output;
};

export function setHeaders() {
  const accessToken = getAccessToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  return headers;
}

export const flattenObject = (x, result, prefix) => {
  if (_.isObject(x)) {
    _.each(x, function (v, k) {
      flattenObject(v, result, prefix ? k : k);
    });
  } else {
    result[prefix] = x;
  }
  return result;
};

function descendingComparator(a, b, orderBy) {
  let sortA, sortB;
  if (orderBy === "createdDt") {
    sortA = new Date(b[orderBy]).getTime();
    sortB = new Date(a[orderBy]).getTime();
  } 
  else if(orderBy === "size") {
    sortA = Math.trunc(b[orderBy]);
    sortB = Math.trunc(a[orderBy]);
  }
  else  {
    sortA = b[orderBy];
    sortB = a[orderBy];
  }

  if (sortB < sortA) {
    return -1;
  }
  if (sortB > sortA) {
    return 1;
  }
  return 0;
}
export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function formatDate(date) {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(date).toLocaleString("en-us", options);
}

export function handleAddDeleteCheckbox(selectedIndex, newSelected = [], row, selected) {

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, row);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }
  return newSelected;
}