// This components needs to be refactored too many things in one file. 

import React from "react";
import { useImmerReducer } from "use-immer";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "scss/App.scss";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import StylesProvider from "@mui/styles/StylesProvider";
import {
  Chip,
  Button,
  Box,
  Paper,
  Modal,
  LinearProgress,
  TableCell,
  FormControl,
  Tooltip,
} from "@mui/material";
import TopNav from "components/TopNav";
import SideNav from "components/SideNav";
import DataTable from "components/common/DataTable/DataTable";
import useFetchRecentExperiments from "components/Hooks/Experiment/useFetchRecentExperiment";
import CancelIcon from "images/icons-close-x-icon-small.svg";
import BRShapes from "images/B-Rshapes.png";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/AddCircleOutlineRounded";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import useDeleteExperiment from "components/Hooks/Experiment/useDeleteExperiment";
import {
  TableOptions,
  RecentExperimentsTableOptions,
} from "components/common/Utils/DataTableOptions";
import ExperimentDetail from "components/Experiment/Details";
import ExperimentAdd from "components/Experiment/Add";
import useFetchExperimentDetailById from "components/Hooks/Experiment/useFetchExperimentDetailById";
import DateFormatter from "components/common/Utils/DateFormatter";
import { Form } from "components/common/ReactHookForm/Form";
import { Input } from "components/common/ReactHookForm/Input";
import { useForm } from "react-hook-form";
import useMutateExperimentTagAdd from "components/Hooks/Tags/useMutateExperimentTagAdd";
import useMutateExperimentTagDelete from "components/Hooks/Experiment/useMutateExperimentTagDelete";
import { USER_MESSAGE } from "actions/User";
import { useDispatch } from "react-redux";
import { MAXTAGLIMIT } from "components/common/Constants/appConstants";
import ExperimentIcon from "images/icons-table-atgc-experiment-2.svg";
import ExperimentIconHover from "images/icons-table-atgc-experiment-2-hover.svg";
import { commonReducer, initExperimentState, initHomeState } from "reducers/localReducers/commonReducer";

import * as config from "../../../app.config";
// Not needed for now
//import _ from "underscore";
import { ExperimentContext } from "contexts/ExperimentContext";

const schema = yup.object().shape({
  tagName: yup
    .string()
    .trim()
    .max(20, "The tag name cannot be more than 20 characters.")
    .required(),
});

const Experiments = ({ mode }) => {
  const [state, setState] = useImmerReducer(
    commonReducer,
    mode === "experimentsMode" ? initExperimentState : initHomeState
  );
  const pollingStatus = false;
  const resolver = yupResolver(schema);
  const expDetail = useFetchExperimentDetailById(state.expId, pollingStatus);

  const list = useFetchRecentExperiments();
  const deleteExperiment = useDeleteExperiment();
  const addExperimentTag = useMutateExperimentTagAdd();
  const tagExperimentDelete = useMutateExperimentTagDelete();
  const dispatch = useDispatch();

  // list?.data?.data.forEach((key, index) => {
  //   console.log("key", key);
  //   key.tagSearch = (key.tags && _.pluck(key.tags, "tagName").join(" ")) || "";
  //   // key.tags = key.tags;
  //   key.id = crypto.randomUUID(); //Need to add an id for the MUI Datagrid to work
  //   key.createdAt = DateFormatter(key.createdAt);
  //   key.modifiedAt = DateFormatter(key.modifiedAt);
  //   key.status = key.status === "SUCCEEDED" ? "COMPLETED" : key.status;
  //   key.pipelineName = key.pipelineName === 'Omnition' ? `ddSEQ 3' scRNA-seq Omnition` : key.pipelineName;
  // });

  //changed the previous in-place mutation of the list 
  const transformedList = list?.isSuccess ? list?.data?.data?.map((item) => ({
    id: crypto.randomUUID(),  // Add unique ID for MUI DataGrid
    tagSearch: item?.tags?.map(tag => tag.tagName).join(" ") || "",  // Create tag search string
    createdAt: DateFormatter(item?.createdAt),  // Format createdAt
    modifiedAt: DateFormatter(item?.modifiedAt),  // Format modifiedAt
    status: item?.status === "SUCCEEDED" ? "COMPLETED" : item?.status,  // Map status
    pipelineName: item?.pipelineName?.trim().toLowerCase() === 'omnition' ? `ddSEQ 3' scRNA-seq Omnition` : item?.pipelineName, // Format pipeline name
    experimentId: item?.experimentId,  // Keep experimentId
    tags: item?.tags,  // Keep the original tags array
    experimentName: item?.experimentName
  })) : [];

  const {
    register,
    handleSubmit,
    errors,
    formState: { isDirty, isValid },
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    resolver,
  });
  const columnsOfExperiments = [
    {
      name: "experimentId",
      label: "",
      options: {
        filter: false,
        display: false,
        sort: true,
        viewColumns: false,
      },
    },
    {
      name: "experimentName",
      label: "Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const experimentId = tableMeta.rowData[0];
          return (
            <Tooltip
              title={`${config.ENDPOINTS["main"]}/experiment-detail/${experimentId}`}
              placement="top"
            >
              <div
                style={{ cursor: "pointer" }}
                className="orange-link"
                onClick={() => {
                  setState({ type: "expId", payload: experimentId });
                }}
                onMouseOver={() => {
                  setState({ type: "image", payload: ExperimentIconHover });
                }}
                onMouseOut={() => {
                  setState({ type: "image", payload: ExperimentIcon });
                }}
                data-testid="experimentName"
              >
                <div className="icon baseline">
                  <img alt="" src={state.image} />
                </div>
                <p
                  style={{
                    display: "inline-block",
                    paddingLeft: "10px",
                    color: "#d44300",
                    textTransform: "capitalize",
                  }}
                >
                  {value}
                </p>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "pipelineName",
      label: "Prep Kit Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "createdAt",
      label: "Created",
      options: {
        filter: true,
        sortCompare: (order) => {
          return (a, b) => {
            let x = Date.parse(a.data);
            let y = Date.parse(b.data);
            return (x - y) * (order === "asc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "modifiedAt",
      label: "Modified",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tags",
      label: "Tags",
      options: {
        filter: false,
        sort: false,
        searchable: true,
        customBodyRender: (tags, tableMeta) => {
          return (
            <div>
              {tags?.slice(0, 5).map((tag) => (
                <Chip
                  key={tag?.id}
                  label={tag?.tagName?.toUpperCase()}
                  disabled={tagExperimentDelete.status === "loading"}
                  onDelete={() => handleDelete(tag, tableMeta.rowData[0])}
                  deleteIcon={
                    <img
                      src={CancelIcon}
                      id={tag?.id}
                      name={tag?.tagName}
                      style={{ height: "12px", width: "12px" }}
                    />
                  }
                />
              ))}
              <AddIcon
                style={{
                  color: "#0064b0",
                  cursor: "pointer",
                  verticalAlign: "middle",
                }}
                onClick={() =>
                  handleTagOpen(tableMeta.rowData, tableMeta.rowData[0])
                }
              />
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <DeleteIcon
              style={{ color: "#0064b0", cursor: "pointer" }}
              onClick={() => handleOpen(tableMeta.rowData[0])}
            />
          );
        },
      },
    },
    {
      name: "tagSearch",
      label: "Tags",
      options: {
        filter: true,
        display: true,
        searchable: true,
        sort: false,
        viewColumns: true,
        customBodyRender: (tagSearch, tableMeta) => {
          return <div style={{ display: "none" }}>{tagSearch}</div>;
        },
        customHeadRender: () => null,
      },
    },
  ];
  const columnsOfHome = [
    {
      name: "experimentId",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "experimentName",
      label: "RECENT EXPERIMENTS",
      options: {
        filter: true,
        sort: false,
        customHeadRender: (columnMeta) => {
          return (
            <TableCell key="test" align="left">
              <strong> RECENT EXPERIMENTS </strong>
            </TableCell>
          );
        },
        customBodyRender: (value, tableMeta) => {
          const experimentId = tableMeta.rowData[0];
          return (
            <Tooltip
              title={`${config.ENDPOINTS["main"]}/experiment-detail/${experimentId}`}
              placement="top"
            >
              <div
                style={{ cursor: "pointer" }}
                className="orange-link"
                onClick={() => {
                  setState({ type: "expId", payload: experimentId });
                }}
                onMouseOver={() => {
                  setState({ type: "image", payload: ExperimentIconHover });
                }}
                onMouseOut={() => {
                  setState({ type: "image", payload: ExperimentIcon });
                }}
              >
                <div className="icon baseline">
                  <img alt="" src={state.image} />
                </div>
                <p
                  style={{
                    display: "inline-block",
                    paddingLeft: "10px",
                    color: "#d44300",
                    textTransform: "capitalize",
                  }}
                >
                  {value}
                </p>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "modifiedAt",
      label: "UPDATED",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return DateFormatter(value);
        },
      },
    },
    {
      name: "tags",
      label: "TAGS",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (tags, tableMeta) => {
          return (
            <div>
              {tags.slice(0, 5).map((tag) => (
                <Chip
                  key={tag?.id}
                  label={tag?.tagName.toUpperCase()}
                  disabled={tagExperimentDelete.status === "loading"}
                  onDelete={() => handleDelete(tag, tableMeta.rowData[0])}
                  deleteIcon={
                    <img
                      src={CancelIcon}
                      id={tag?.id}
                      name={tag?.tagName}
                      style={{ height: "12px", width: "12px" }}
                    />
                  }
                />
              ))}
              <AddIcon
                style={{
                  color: "#0064b0",
                  cursor: "pointer",
                  verticalAlign: "middle",
                }}
                onClick={() =>
                  handleHomeTagOpen(tableMeta.tableData[tableMeta.rowIndex])
                }
              />
            </div>
          );
        },
      },
    },
  ];
  const gridData = {
    columns: mode === "experimentsMode" ? columnsOfExperiments : columnsOfHome,
    options:
      mode === "experimentsMode"
        ? TableOptions.options
        : RecentExperimentsTableOptions.options,
    data:
      mode === "experimentsMode"
        ? (transformedList) || []
        : transformedList?.slice(0, 5),
    themeOptions:
      mode === "experimentsMode"
        ? TableOptions.themeOptions
        : RecentExperimentsTableOptions.themeOptions,
  };

  const newTagName = (e) => {
    setState({ type: "tagName", payload: e.currentTarget.value });
  };

  const onSubmit = (data, e) => {
    const experimentId = state.experimentId;
    const tagName = data.tagName;
    const values = { experimentId, tagName };
    addExperimentTag.mutate(values);
    handleClose();
  };

  const handleOpen = (experimentId) => {
    gridData.options.rowsPerPage =
      parseInt(localStorage.getItem("rowsPerPage")) ||
      gridData.options.rowsPerPage;
    gridData.options.sortOrder =
      JSON.parse(localStorage.getItem("sortOrder")) ||
      gridData.options.sortOrder;
    setState([
      { type: "open", payload: true },
      { type: "experimentId", payload: experimentId },
    ]);
  };

  // Migrated from Home component : creating new Tags
  const handleHomeTagOpen = (tableData) => {
    if (tableData[4].length > MAXTAGLIMIT) {
      dispatch({
        type: USER_MESSAGE,
        msgType: "error",
        text: "tag.max",
        key: Math.random(),
      });
    } else {
      const experimentId = tableData[0] || tableData.experimentId;
      setState([
        { type: "openTag", payload: true },
        { type: "experimentId", payload: experimentId },
      ]);
    }
  };

  const handleTagOpen = (tableData, experimentId) => {
    if (tableData[6].length > MAXTAGLIMIT) {
      dispatch({
        type: USER_MESSAGE,
        msgType: "error",
        text: "tag.max",
        key: Math.random(),
      });
    } else {
      gridData.options.sortOrder =
        JSON.parse(localStorage.getItem("sortOrder")) ||
        gridData.options.sortOrder;
      setState([
        { type: "openTag", payload: true },
        { type: "experimentId", payload: experimentId },
      ]);
    }
  };

  const handleClose = () => {
    setState([
      { type: "open", payload: false },
      { type: "openTag", payload: false },
    ]);
  };

  const handleClick = () => {
    return (
      <ExperimentContext>
        <ExperimentAdd />
      </ExperimentContext>
    );
  };

  const handleDelete = (data, experimentId) => {
    gridData.options.sortOrder =
      JSON.parse(localStorage.getItem("sortOrder")) ||
      gridData.options.sortOrder;
    const variables = {
      experimentId: experimentId,
      ...data,
    };
    if (data) {
      tagExperimentDelete.mutate(variables);
    }
  };

  const deleteSelectedExperiment = () => {
    deleteExperiment.mutate(state.experimentId);
    setState({ type: "open", payload: false });
  };

  if (state.expId && expDetail?.data?.data?.experimentMeta.status) {
    const experimentId = expDetail?.data?.data?.experimentMeta.experimentId;

    window.history.pushState(
      "Seqsense-2.0",
      "Experiment Details",
      expDetail?.data?.data?.experimentMeta.status !== "Not Started" ? `/experiment-detail/${expDetail?.data?.data?.experimentMeta.experimentId}` : `/create-experiment/pipeline-selection/${expDetail?.data?.data?.experimentMeta.experimentId}`
    );
    return expDetail?.data?.data?.experimentMeta.status !== "Not Started" ? (
      <ExperimentDetail experimentId={experimentId} mode="edit-experiment" />
    ) : (
      <ExperimentContext>
        <ExperimentAdd experimentId={experimentId} mode="edit-experiment" />
      </ExperimentContext>
    );
  }

  return (
    <StylesProvider injectFirst>
      <div id="wrapper">
        <TopNav />
        <div className="home-backdrop">
          <SideNav
            step="130vh"
            page={mode === "experimentsMode" ? "Experiment" : "Home"}
          />
          <Box
            style={{
              backgroundImage: `url("${BRShapes}")`,
              display: "flex",
              flexDirection: "column",
            }}
            id="home-wrapper"
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 1% 2% 2%",
              }}
            >
              {mode === "experimentsMode" ? (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <h3>Experiments</h3>
                </Box>
              ) : (
                <Box />
              )}
              <Link to="/create-experiment/pipeline-selection" style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleClick}
                  type="submit"
                  className="home-button green-button"
                  data-testid="AddCircleOutlineRoundedIcon"
                >
                  Create New Experiment
                </Button>
              </Link>
            </Box>
            <Box
              id="recent-experiments-grid"
              style={{ height: "400px", width: "95%", alignSelf: "center" }}
            >
              <Box>
                {(list?.isFetching || expDetail.isFetching) && (
                  <LinearProgress color="success" />
                )}
                {list?.isSuccess ? (
                  <DataTable id={1} props={gridData} />
                ) : list?.isFetching ? (
                  <LinearProgress color="success" />
                ) : (
                  <DataTable id={1} props={gridData} />
                )}
              </Box>
            </Box>
          </Box>
          <Modal open={state.openTag || false} style={{ display: "flex" }}>
            <Paper id="addtag-modal">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <p className="eula-header">Add Tag</p>
                </Box>
                <Box className="top-border small-padding-top">
                  <FormControl variant="outlined" fullWidth={true}>
                    <Input
                      ref={register}
                      name="tagName"
                      id="addtag-field"
                      variant="outlined"
                      label="Tag Name"
                      onChange={newTagName}
                      error={!!errors.tagName}
                      helperText={
                        errors && errors.tagName && errors.tagName.message
                          ? errors.tagName.message
                          : "Max. 20 Characters"
                      }
                    />
                  </FormControl>
                </Box>
                <Box id="addtag-footer">
                  <Button
                    style={{ cursor: "pointer" }}
                    className="blue-link medium-text link2"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="text"
                    style={{ cursor: "pointer" }}
                    className="blue-link medium-text"
                    disabled={!isDirty || !isValid}
                    name="submit"
                    type="submit"
                  >
                    OK
                  </Button>
                </Box>
              </Form>
            </Paper>
          </Modal>
          <Modal open={state.open || false}>
            <Paper className="" id="delete-experiment-modal">
              <Box id="delete-header-box">
                <ErrorOutlineOutlinedIcon
                  className="delete-header delete-icon"
                  style={{ fontSize: "48px" }}
                />
                <h3
                  className="info-title delete-header"
                  style={{ marginLeft: "5px" }}
                >
                  Delete Experiment?
                </h3>
              </Box>
              <Box style={{ paddingLeft: "10px" }}>
                <p>You will not be able to recover it.</p>
              </Box>
              <Box id="addtag-footer">
                <a
                  className="blue-link medium-text link2"
                  onClick={handleClose}
                >
                  CANCEL
                </a>
                <a
                  href="#"
                  className="blue-link medium-text"
                  onClick={deleteSelectedExperiment}
                  data-testid="DeleteIcon"
                >
                  DELETE
                </a>
              </Box>
            </Paper>
          </Modal>
        </div>
      </div>
    </StylesProvider>
  );
};

Experiments.propTypes = {
  mode: PropTypes.string,
};
export default Experiments;
