/* eslint-disable no-unused-vars */
// This code is inherited code from the original contractor/contributor, so the tech debt is inherited too.
// some of them have been cleaned up, but still, there are some tech debt left in the code.
import React, { useReducer, useEffect, useState } from "react";
import "scss/App.scss";
import { useDropzone } from "react-dropzone";
import { formatBytes, setHeaders } from "utils/basic";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import LinearProgress from "@material-ui/core/LinearProgress";
import WarningIcon from "@material-ui/icons/Warning";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "underscore";
import "./theme.css";
import * as config from "../../../app.config";
import axios from "axios";
import DoneIcon from "@material-ui/icons/Done";
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from "react-intl";
import * as experimentActions from "actions/Experiments";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "components/common/Toaster.jsx";
//import { bindActionCreators, compose } from "redux";
import { useIsAuthenticated } from "@azure/msal-react";
import FileIcon from "images/icons-table-generic.svg";
import CancelIcon from "images/icons-close-x-icon-black-small.svg";
import useDeleteExistingFile from "components/Hooks/SampleFiles/useDeleteExistingFile";

import {
    useExperimentsContext,
    useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import * as ExperimentAddActions from "actions/ExperimentAdd";
import { useIsMutating } from "react-query";
import { handleFileUploadError } from "./FileUploadError";


const SLICE_SIZE = 4768 * 1024 * 1024; // 5 GB per part
//const SLICE_SIZE = 1 * 1024 * 1024 * 1024; // 1 GB per part - try smaller sizes for faster user feedback


function FileUpload() {

    const isMutatingSaveExp = useIsMutating(['saveExp']);

    const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
        accept: ".fastq.gz, .fq.gz",
        noClick: true,
    });

    const dispatchAction = useDispatch();
    const isAuthenticated = useIsAuthenticated();
    const dispatchEvent = useExperimentsDispatchContext();
    const experimentState = useExperimentsContext();
    const deleteExistingFile = useDeleteExistingFile();
    const { experimentId, pipelineId, pipelineKit, experimentTitle, fileExists, disable, selected, fileNameError } = experimentState;
    const headerConfig = setHeaders();

    // Redux-managed state for file uploads
    const state = useSelector((state) => state.fileReducer);
    const saveFileStatusUrl = `${config.WS_FILE_SAVE_STATUS_LINK}`;
    // const showInitialUploadDelayMessage = useSelector(
    //     (state) => state.fileUpload.showInitialUploadDelayMessage
    // );
    // console.log({ showInitialUploadDelayMessage });

    //TODO: Old Code Tech Debt Here based off the pipeline chosen while creating the Experiment , retrieve the region

    useEffect(() => {
        dispatchEvent({
            type: ExperimentAddActions.SET_FILE_NAME_ERROR,
            payload: false,
        });

        // Convert the existing fileUploads object to a set of file names for O(1) lookup
        const existingFiles = new Set(Object.keys(state?.fileUploads ?? {}));

        // Filter out any accepted files that already exist in the state
        const nonDuplicateFiles = acceptedFiles.filter((file) => {
            if (existingFiles.has(file.name)) {
                // File already exists, dispatch action to set duplicate file warning
                dispatchEvent({
                    type: ExperimentAddActions.SET_FILE_EXISTS,
                    payload: true,
                });
                return false;
            }
            return true;
        });

        // Extract names of the non-duplicate accepted files
        const fileNames = nonDuplicateFiles.map(file => file.name);
        //  const fileNames = _.pluck(acceptedFiles, "name");
        let getStrArr = [];
        let newStrArr = [];
        let check;
        fileNames.forEach(function (item, index, arr) {
            getStrArr = item.split("_");
            newStrArr = getStrArr.slice(-4);
            check = newStrArr[0].startsWith("S");
            if (!check) {
                arr.length = index + 1;
            }
        });
        const regex = /_S.*_L?([0-9]{3})_R(1|2)_001\.(fastq|fq)\.gz$/i;
        let fileNameCheck = regex.test(fileNames) && check;
        if (fileNames.length > 0) {
            if (fileNameCheck) {
                dispatchAction({ type: experimentActions.SET_FILES, files: fileNames });
                acceptedFiles.forEach((file) => {
                    handleFileUpload(file);
                });
            } else {
                dispatchEvent({
                    type: ExperimentAddActions.SET_FILE_NAME_ERROR,
                    payload: true,
                });
            }
        }

        if (fileNameError) {
            removeAll();
        }

        return () => {
            dispatchEvent({
                type: ExperimentAddActions.SET_FILE_EXISTS,
                payload: false,
            });
            dispatchEvent({
                type: ExperimentAddActions.SET_FILE_NAME_ERROR,
                payload: false,
            });
        };
    }, [acceptedFiles, isAuthenticated, fileNameError]);

    const removeAll = () => {
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
    }

    // Function to handle file uploads - AWS multi part file upload
    // TODO: Refactor this function to a separate component when time permits. 
    async function handleFileUpload(file) {
        // Check if the delay message has been set globally, before dispatching START_FILE_UPLOAD
        if (!state.showInitialUploadDelayMessage && file.size >= SLICE_SIZE) { // Files larger than 5GB
            dispatchAction({
                type: experimentActions.SET_INITIAL_UPLOAD_DELAY_MESSAGE,
                payload: true,
            });
        }
        try {
            const initiateResponse = await axios.post(`${config.ENDPOINTS.main}/initiateMultipartUpload`,
                { fileName: file.name, pipelineId: pipelineId },  // data payload
                { headers: headerConfig }); // headers           
            // Add file metadata to Redux state
            const { upload_id, databank_file_key } = initiateResponse.data;
            dispatchAction({
                type: experimentActions.START_FILE_UPLOAD,
                payload: {
                    fileId: upload_id,
                    fileName: file.name,
                    size: file.size,
                    totalParts: Math.ceil(file.size / SLICE_SIZE),
                    createdDt: Date.now(),

                },
            });
            dispatchEvent({
                type: ExperimentAddActions.SET_DISABLE,
                payload: true
            })
            dispatchEvent({
                type: ExperimentAddActions.SET_DISABLE_UPLOAD,
                payload: true
            })


            // Step 2: Get Presigned URLs for each part
            const totalParts = Math.ceil(file.size / SLICE_SIZE);
            const presignedUrlsResponses = await axios.post(`${config.ENDPOINTS.main}/getPresignedUrls`,
                {
                    upload_id,
                    databank_file_key,
                    fileName: file.name,
                    totalParts: totalParts,
                },

                { headers: headerConfig }

            );

            const presignedUrls = presignedUrlsResponses?.data?.presigned_urls;
            // dispatchAction({
            //     type: experimentActions.FILES_S3,
            //     payload: file
            // });


            // Step 3: Concurrently Upload Parts using Presigned URLs
            // Store the progress for each part in an object
            // Initialize progress tracking for each part
            let partProgress = Array(totalParts).fill(0);
            let previousOverallProgress = 0;

            const uploadPromises = presignedUrls.map((presignedUrlData, index) => {
                const start = index * SLICE_SIZE;
                const end = Math.min(start + SLICE_SIZE, file.size);
                const filePart = file.slice(start, end);
                const startTime = Date.now();

                return axios.put(presignedUrlData.presigned_url, filePart, {
                    headers: {
                        "Content-Type": "application/octet-stream",
                    },
                    onUploadProgress: (progressEvent) => {
                        const partPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                        // Update progress for this specific part
                        partProgress[index] = partPercentage;

                        // Calculate the overall progress as the average of all parts' progress
                        const totalProgress = partProgress.reduce((acc, curr) => acc + curr, 0) / totalParts;

                        // Only dispatch if progress increases by more than 5%
                        const roundedTotalProgress = Math.min(100, Math.round(totalProgress));
                        const elapsedTime = (Date.now() - startTime) / 1000; // Time in seconds
                        const uploadSpeed = progressEvent.loaded / elapsedTime / 1024; // Speed in KB/s
                        if (Math.abs(totalProgress - previousOverallProgress) >= 5) {
                            previousOverallProgress = totalProgress;

                            // Dispatch action to update the progress for this part
                            dispatchAction({
                                type: experimentActions.UPDATE_UPLOAD_PROGRESS,
                                payload: {
                                    fileName: file.name,
                                    partNumber: index + 1, // Use 1-based index for partNumber as in backend
                                    partProgress: partPercentage,
                                    overallProgress: roundedTotalProgress,
                                    uploadSpeed: Math.round(uploadSpeed)
                                },
                            });

                            console.log(`Overall Upload Progress: ${Math.round(roundedTotalProgress)}%`);
                        }
                    }
                })
                    .then(response => {
                        if (response.status !== 200) {
                            throw new Error(`Failed to upload part ${index + 1}`);
                        }
                        return {
                            ETag: response.headers["etag"],
                            PartNumber: presignedUrlData.part_number,
                            // Set this part's progress to 100% upon completion
                        };
                    })
                    .catch(error => {
                        console.error(`Error uploading part ${index + 1}:`, error);
                        throw error;
                    });
            });

            let etags;
            try {
                etags = await Promise.all(uploadPromises);
            } catch (error) {
                dispatchAction({
                    type: experimentActions.FILE_UPLOAD_FAILED,
                    payload: file.name,
                });
                return;
            }

            // Dispatch action to mark the upload as complete for each part
            etags.forEach(uploadedPart => {
                dispatchAction({
                    type: experimentActions.UPDATE_UPLOAD_PROGRESS,
                    payload: {
                        fileName: file.name,
                        partNumber: uploadedPart.PartNumber,
                        partProgress: 100, // Mark each part as 100% completed
                    },
                });
            });

            // Step 4: Complete Multipart Upload
            const completeMultipartUpload = await axios.post(
                `${config.ENDPOINTS.main}/completeMultipartUpload`,
                {

                    upload_id,
                    databank_file_key,
                    etags: etags,
                },
                {
                    headers: headerConfig
                }
            );
            console.log(completeMultipartUpload.data.message);
            // Dispatch action to mark the upload as complete
            // dispatchAction({
            //     type: experimentActions.FILE_UPLOAD_COMPLETED,
            //     payload: file.name,
            // });

            console.log({ completeMultipartUpload });

            dispatchAction({
                type: experimentActions.MULTI_PART_UPLOAD_DATABANK_COMPLETED,
                payload: file.name
            })
            if (completeMultipartUpload.data?.exists) {
                triggerWebSocketNotification(file);
            }
        } catch (error) {
            handleFileUploadError(error, dispatchEvent, dispatchAction, file);
        }
    }

    const remove = (file) => {
        console.log(`Removing file: ${file.name}`);
        acceptedFiles.splice(file, 1);
        const { fileName, fileId } = file;
        const str = `${experimentId}**${fileName}**${fileId}`;
        console.log({ str });
        deleteExistingFile.mutate(str, {
            onSuccess: () => {
                dispatchEvent({
                    type: ExperimentAddActions.SET_DISABLE,
                    payload: false,
                })
                dispatchEvent({
                    type: ExperimentAddActions.SET_DISABLE_UPLOAD,
                    payload: !acceptedFiles.length,
                });
                dispatchEvent({
                    type: ExperimentAddActions.SET_FILTER_UPLOADED_FILES,
                    payload: fileId,
                });
                dispatchEvent({
                    type: ExperimentAddActions.SET_FILTER_SELECTED,
                    payload: fileId,
                });
                dispatchEvent({
                    type: ExperimentAddActions.SET_FILTER_ALL_FILES,
                    payload: fileId,
                });
                dispatchEvent({
                    type: ExperimentAddActions.SET_FILTER_ALL_FILES_BY_NAME,
                    payload: fileName,
                });
            },
        });
        dispatchAction({ type: experimentActions.REMOVE_FILE_UPLOAD, file });
    };

    function sendMessage(socket, message) {
        if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
            // socket.close();
        } else {
            console.error('WebSocket connection is not open.');
        }
    }

    // Function to trigger WebSocket notification after a file is fully uploaded
    const triggerWebSocketNotification = (file) => {
        console.log("websocket notification triggered for file:", file.name);
        if (!state.savedFiles.includes(file.name)) {
            const socket = new WebSocket(saveFileStatusUrl);
            console.log("fielUpload state:", state.fileUploads);

            // Derive `fileData` from `fileUploads`
            // const fileData = Object.values(state.fileUploads).map(({ fileName, size }) => ({
            //     fileName: fileName,
            //     fileSize: size,
            // }));
            // state updates still in progress so state?.fileUploads may not be updated just yet

            const fileData = [{ fileName: file.name, fileSize: file.size }];  // For now, assuming there's only one file [file.name, file.size]

            const postData = {
                experimentId: experimentId,
                experimentName: experimentTitle,
                fileData: fileData,  // Array with fileName and fileSize attributes
                fileList: [file?.name],        // Can be removed if redundant
                pipelineId: pipelineId,
                tags: [],
                headers: headerConfig.Authorization,
            };

            // Heartbeat interval in milliseconds (e.g., 10 seconds)
            const heartbeatIntervalTime = 10000;
            let heartbeatInterval;

            // Function to start heartbeat
            const startHeartbeat = () => {
                heartbeatInterval = setInterval(() => {
                    if (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING) {
                        console.log("Sending heartbeat to keep the WebSocket connection alive.");
                        socket.send(JSON.stringify({ action: "heartbeat" }));
                    }
                }, heartbeatIntervalTime);
            };

            // Function to stop heartbeat
            const stopHeartbeat = () => {
                if (heartbeatInterval) {
                    clearInterval(heartbeatInterval);
                    heartbeatInterval = null;
                }
            };

            socket.addEventListener("open", () => {
                sendMessage(socket, { action: "SendMessageFileSave", message: postData });
                startHeartbeat(); //

                // call function to verify file exists in S3 input folder for the experiment id 
                // verifyFileInExperimentInfolders();
            });

            // Listen for messages from the WebSocket
            socket.addEventListener("message", (event) => {
                console.log("WebSocket event data:", event.data);
                try {
                    const response = JSON.parse(event.data);
                    console.log("WebSocket message:", response);

                    // Handle the structured message from the sendMessage Lambda
                    if (response.action === "CopyFileComplete" && response.status === "success") {
                        console.log("File copy complete for:", response.fileNames);
                        // Call function to verify if the file exists in S3 input folder for the experiment ID
                        //verifyFileInExperimentInfolders();
                        dispatchAction({
                            type: experimentActions.MARK_FILE_SAVED,
                            payload: response.fileNames[0],
                        });
                        stopHeartbeat();
                    }
                } catch (error) {
                    // Handle any error in parsing JSON, which might indicate the original message format
                    console.error("Error parsing WebSocket message:", error);
                }
            });


            // socket.addEventListener("close", (event) => {
            //     console.warn("WebSocket connection closed:", event);
            //     // verifyFileInExperimentInfolders();
            //     //socket.close();
            // });
            // Handle WebSocket errors
            socket.addEventListener("error", (error) => {
                console.error("WebSocket encountered an error:", error);
                stopHeartbeat(); // Stop the heartbeat in case of an error
                // Optionally, start polling( dont prefer) for file existence - not implemented here
                // verifyFileInExperimentInfolders();
            });
        }
    };

    // useEffect to check if all files are fully uploaded
    useEffect(() => {
        console.log("State", state?.fileUploads);
        const areAllPartsCompleted = Object.values(state?.fileUploads ?? {}).every((file) => {
            return file.completedParts === file.totalParts;
        });
        console.log("areAllPartsCompleted:", areAllPartsCompleted);  // Log the result of the check

        const doFilesMatch =
            state.files?.length === state.savedFiles?.length &&
            [...state.files].sort().every((fileName, index) => {
                return fileName === [...state.savedFiles].sort()[index];
            });

        console.log('doFilesMatch:', doFilesMatch);

        const allFilesCompleted =
            Object.keys(state?.fileUploads ?? {}).length > 0 &&
            areAllPartsCompleted &&
            doFilesMatch;

        console.log("All files completed:", allFilesCompleted);  // Log the result of the check

        // Set uploadComplete to true if all files are completed
        if (allFilesCompleted) {
            dispatchAction({
                type: experimentActions.FILE_UPLOAD_COMPLETED,
                payload: true,
            });
            dispatchEvent({
                type: ExperimentAddActions.SET_DISABLE_UPLOAD,
                payload: false,
            })
            dispatchEvent({
                type: ExperimentAddActions.SET_DISABLE,
                payload: false,
            })
        }
    }, [state?.fileUploads, state?.savedFiles, state?.files, dispatchEvent]);


    // Render file upload progress
    const filesHtml = Object.values(state?.fileUploads ?? {}).map((file) => (
        <div className="fileStatus" key={file.fileId}>
            <div className="grid-x grid-margin-x align-middle">
                <div className="cell small-5">
                    <img className="link-image" alt="File Icon" src={FileIcon} />
                    <span className={state.errorFiles.includes(file.fileName) ? "error-file" : null}>{file.fileName}</span>
                </div>
                <div className="cell small-1">{formatBytes(file.size)}</div>
                <div className="cell small-2">{`${file.progress} %`}</div>
                <div className="cell small-3">
                    <Tooltip title={file.speed ? `Uploading at ${file.speed} KB/s` : "Calculating speed..."} placement="top">
                        <LinearProgress variant="determinate" value={file.progress} />
                    </Tooltip>
                </div>
                {file.authError && (
                    <div>
                        <Toaster text="auth.error" autoHideDuration={15000} type={"error"} />
                    </div>

                )}
                {file.reUploadRequired && (
                    <div>
                        <Toaster
                            text={`Upload Failed. Re-upload required for file: ${file.fileName}.`}
                            autoHideDuration={15000}
                            type={"warning"}
                        />
                    </div>
                )}

                {/* <div className="cell small-2">
                    <span>{file.completedParts}/{file.totalParts} parts completed</span>
                </div> */}
                <div data-testid={`${file.fileName}-remove`} onClick={() => remove(file)}>
                    <img className="link-image" alt="Cancel Icon" src={CancelIcon} />
                </div>
            </div>
        </div>
    ));

    // Render alerts and messages
    const uploadComplete = Object.keys(state?.fileUploads ?? {}).length > 0 &&
        Object.values(state?.fileUploads ?? {}).every((file) => {
            return file.completedParts === file.totalParts
            // Log each file object
        });

    const callOutAlert = state.errorFiles.length > 0 && (
        <div className="callout alert">
            <div className="grid-x grid-margin-x align-middle">
                <div className="cell small-1"><WarningIcon /></div>
                <div className="cell auto">{state.errorFiles.join(', ')}</div>
            </div>
        </div>
    );

    const callOutSuccess = uploadComplete && state?.errorFiles?.length === 0 && isMutatingSaveExp === 0 && (
        <div className="callout success">
            <div className="grid-x grid-margin-x align-middle">
                <div className="cell small-1"><DoneIcon /></div>
                <div className="cell auto">
                    <FormattedMessage id="experiment.add.uploadSuccess" values={{ num: Object.keys(state.fileUploads).length }} />
                </div>
            </div>
        </div>
    );

    const callOutToaster = fileExists && (
        <Toaster text="file.exist" autoHideDuration={15000} type={"error"} />
    );

    const callOutSaving = isMutatingSaveExp !== 0 && (
        <div className="callout primary">
            <div className="grid-x grid-margin-x align-middle">
                <div className="cell small-1"><CircularProgress /></div>
                <div className="cell auto">
                    <FormattedMessage id="experiment.save.in.progress" />
                </div>
            </div>
        </div>
    );

    // Render the full component
    return (
        <section className="container">
            <div {...getRootProps({ className: "dropzone" })}>
                <input data-testid="dropzone" {...getInputProps()} />
                {!_.isEmpty(state?.fileUploads) && (
                    <div className="callout secondary">
                        <ul>{filesHtml}</ul>
                    </div>
                )}
                <div onClick={open} style={{ marginTop: filesHtml?.length? "0px" : "80px", display: "flex" }}>
                    <span style={{ cursor: "pointer" }}>Click or drop your FASTQ files here to upload.</span>
                    <CloudUploadOutlinedIcon style={{ color: "#green", height: 20, width: 20, marginLeft: 10, marginTop: 0, verticalAlign: "bottom", cursor: "pointer" }} />
                </div>
            </div>

            {callOutAlert}
            {callOutSuccess}
            {callOutToaster}
            {callOutSaving}
            {state.showInitialUploadDelayMessage && (
                <Toaster text="large.file" autoHideDuration={3500} type={"info"} />
            )}
        </section>
    );


}
export default FileUpload;
