import React from 'react'

export const EulaText = () => {
    return (
        <>
            <br />
            <p>
                <span>
                    This Bio-Rad Customer Agreement (this "Agreement")
                    contains the terms and conditions that govern your access to
                    and use of the Bio-Rad Service Offerings (as defined below)
                    and is an agreement between Bio-Rad Laboratories, Inc. (also
                    referred to as "Bio-Rad", "we,"
                    "us," or "our") and you or the entity
                    you represent, including any End Users to which you delegate
                    any rights or access to the Service Offerings
                    ("you" or "your"). This Agreement
                    takes effect when you click an "I Accept" button
                    or checkbox presented with these terms or, alternatively, once
                    you begin using the Service Offerings (the "Effective
                    Date"). You represent to us that you are at least 18
                    years old, and if you are entering into this Agreement on
                    behalf of a company or other entity, that you have authority
                    to bind that entity. Please see Section 13 for definitions of
                    certain capitalized terms used in this Agreement.
                </span>
            </p>

            <p className="h5">WEB-HOSTED SERVICE OFFERINGS.</p>

            <p>
                <span className="c0">1 Your Account.</span>
                <span className="c1">
                    To access the Services, you must have an account associated
                    with a valid email address. You are responsible for all
                    activities that occur under your account, regardless of
                    whether the activities are authorized by you or undertaken by
                    you, your employees or a third party. We will assume that any
                    person validly logged in under your account is authorized to
                    further direct Bio-Rad regarding the destruction or other
                    management of Your Content.
                </span>
            </p>

            <p className="h6">2. Security and Data Privacy.</p>

            <p>
                <span className="c0">2.1 Security.</span>
                <span className="c1">
                    Without limiting Section 9 or your obligations under Section
                    7, we will implement reasonable and appropriate measures
                    designed to help you secure Your Content against accidental or
                    unlawful loss, access or disclosure.
                </span>
            </p>

            <p className="h6">2.2 Data Privacy.</p>

            <p>
                <span className="c0">2.2.1 Your Content.</span>
                <span className="c1">
                    Beyond the information you provide to purchase the Service
                    Offerings, we do not require any personally identifiable
                    information in order to provide Services to you. Your Content
                    is stored in a secure location depending on where you reside.
                    We will not access or use Your Content except: (a) to provide
                    the Service Offerings; (b) to comply with the law or a court
                    (or similar) legal order; or (c) for internal business
                    purposes, for example, analysis of aggregated user statistics.
                    We will not disclose Your Content to any government or third
                    party except as necessary to comply with the law or a binding
                    order of a governmental body. Unless it would violate the law
                    or a binding order of a governmental body, we will give you
                    notice of any legal requirement or order referred to in this
                    Section 2.
                </span>
            </p>

            <p>
                <span className="c0">2.2.2 Your Account Information.</span>
                <span className="c1">
                    We will only use your Account Information in accordance with
                    the Privacy Policy, and you consent to such usage. The Privacy
                    Policy does not apply to Your Content.
                </span>
            </p>

            <p>
                <span className="c0">
                    2.2.3 De-Identified Information Only.
                </span>
                <span className="c1">
                    You agree and acknowledge that, while using or accessing the
                    Service Offerings, you will only upload de-identified
                    information for research use only (RUO) purposes. You will not
                    upload or transmit any personally identifying information,
                    personal health information, or other personal data as defined
                    by applicable law, including under HIPAA. You are required to
                    notify Bio-Rad immediately in writing in the event of any
                    disclosure of any personally identifiable information to
                    Bio-Rad, at which time, Bio-Rad shall either return or destroy
                    such information. Bio-Rad disclaims any and all liability
                    resulting from its receipt, destruction, or other handling or
                    management of all personally identifiable information that you
                    provide to us, whether inadvertent or otherwise, under this
                    Agreement.
                </span>
            </p>

            <p className="h6">3. Your Responsibilities.</p>

            <p>
                <span className="c0">3.1 Your Content.</span>
                <span className="c1">
                    You will ensure that Your Content (and any End Users'
                    use of Your Content) will not violate any of Bio-Rad's
                    policies or any applicable law, or otherwise infringe upon,
                    misappropriate, or violate any third party rights. You are
                    solely responsible for the development, operation,
                    maintenance, and use of Your Content.
                </span>
            </p>

            <p>
                <span className="c0">
                    3.2 Log-In Credentials and Account Keys.
                </span>
                <span className="c1">
                    Any log-in credentials or other private keys provided by
                    Bio-Rad are for your internal use and you will not sell,
                    transfer or sublicense them to any other entity or person.
                </span>
            </p>

            <p className="h5">ALL SERVICE OFFERINGS.</p>

            <p className="h6">4. Use of the Service Offerings.</p>

            <p>
                <span className="c0">4.1 Generally.</span>
                <span className="c1">
                    You may access and use the Service Offerings in accordance
                    with this Agreement. You will comply with the terms of this
                    Agreement and all laws, rules and regulations applicable to
                    your use of the Service Offerings.
                </span>
            </p>

            <p>
                <span className="c0">4.2. Changes to the Services.</span>
                <span className="c1">
                    We may change or discontinue any of the Services from time to
                    time but will provide you with reasonable notice if we
                    discontinue material functionality of a Service, unless such
                    action is due to: (a) a security or intellectual property
                    issue, (b) is economically or technically burdensome, or (c)
                    would cause us to violate applicable laws. We will also keep
                    you updated of any major bug fixes or any new
                    features/updates.
                </span>
            </p>

            <p>
                <span className="c0">4.3 Patient Care.</span>
                <span className="c1">
                    You hereby represent and warrant that you will not use or
                    access any Services for the purpose of generating or analyzing
                    clinical information or otherwise diagnosing or treating
                    patients.
                </span>
            </p>

            <div>
                <span className="c0">5. Temporary Suspension.</span>

                <span className="c1">
                    To the extent that the Service Offerings involve a Bio-Rad
                    hosted platform, then we may suspend your access to the
                    Service Offerings immediately if we determine that you:
                </span>


                <ul className="no-bullet">
                    <li>
                        (a) pose a security risk to, or otherwise adversely impact,
                        the Service Offerings or a third party; (ii) could subject
                        us, our affiliates, or any third party to liability;
                    </li>
                    <li>(b) breach this Agreement;</li>
                    <li>
                        (c) fail to make payments due for these Service Offerings or
                        any products purchased from Bio-Rad related to the Service
                        Offerings;
                    </li>
                    <li>
                        (d) cease to operate in the ordinary course, make an
                        assignment for the benefit of creditors or similar
                        disposition of your assets, or become the subject of any
                        bankruptcy, reorganization, liquidation, dissolution or
                        similar proceeding.
                    </li>
                </ul>

            </div>

            <p>
                <span className="c0">6. Term; Termination.</span>
                <span className="c1">
                    This Agreement will commence on the Effective Date and will
                    continue indefinitely until termination. You may terminate
                    this Agreement subject to thirty (30) days advance notice to
                    us. We may terminate subject to six (6) months advance notice
                    to you, except in the case of a material breach, in which we
                    must give you thirty (30) days notice to cure before
                    terminating the Agreement. Finally, we may terminate this
                    Agreement immediately if we experience a change in the way we
                    provide the software or other technology that renders
                    continued provision of the Service Offerings impracticable, or
                    otherwise to comply with the law or a court (or similar) legal
                    order.
                </span>
            </p>

            <div>
                <span className="c0">6.3 Effect of Termination.</span>

                <ul className="no-bullet">
                    <li>
                        (a) Generally. Upon termination, all of your rights will
                        terminate; however, Sections 4, 7 (except the license
                        granted to you in Section 7.3), 8, 9, 10, 11, and 12 will
                        survive.
                    </li>
                    <li>
                        (b) Post-Termination. For 30 days following Termination, to
                        the extent that we receive any of Your Content, we will not
                        remove Your Content as a result of the termination, and will
                        allow you to retrieve Your Content.
                    </li>
                </ul>

            </div>

            <p className="h5">7. Proprietary Rights.</p>

            <p>
                <span className="c0">7.1 Your Content.</span>
                <span className="c1">
                    Except as provided in this Agreement, we obtain no rights
                    under this Agreement to Your Content, except that we can use
                    Your Content to provide the Service Offerings to you to the
                    extent that we receive any of Your Content. In such cases, you
                    hereby grant us a limited, non-exclusive, royalty-free,
                    license to use and display Your Content to provide Services to
                    you. You will ensure that Your Content and any End User's use
                    of Your Content will not violate any policy or terms
                    referenced in or incorporated into this Agreement or any
                    applicable law. You are solely responsible for the
                    development, content, operation, maintenance, and use of Your
                    Content.
                </span>
            </p>

            <p>
                <span className="c0">7.2 Title to Your Content.</span>
                <span className="c1">
                    You represent and warrant to us that: (a) you or your
                    licensors own all right, title, and interest in and to Your
                    Content; and (b) none of Your Content or End Users' use
                    of Your Content will violate applicable Bio-Rad policies.
                </span>
            </p>

            <p>
                <span className="c0">7.3 Feedback.</span>
                <span className="c1">
                    Bio-Rad owns all Feedback and shall use such information for
                    internal business purposes. You hereby assign to us, and shall
                    cause your employees and agents, to assign all right, title,
                    and interest in, and we are free to use, without any
                    attribution or compensation to you or any third party, any
                    ideas, know-how, concepts, techniques, or other intellectual
                    property rights contained in the Feedback for any purpose
                    whatsoever.
                </span>
            </p>

            <p>
                <span className="c0">7.4 Service Offerings License.</span>
                <span className="c1">
                    We or our licensors own all right, title, and interest in and
                    to the Service Offerings, and all related technology and
                    intellectual property rights, including as related to
                    Aggregated Statistics that we may generate as a result of your
                    use of or access to the Service Offerings. During the Term of
                    this Agreement, we grant you a limited, revocable,
                    non-exclusive, non-sublicensable, non-transferable license to:
                    (a) access and use the Services in accordance with this
                    Agreement; and (b) copy and use any Bio-Rad Content, such as
                    documentation, solely in connection with your permitted use of
                    the Services.
                </span>
            </p>

            <p>
                <span className="c0">7.5 License Restrictions.</span>
                <span className="c1">
                    Neither you nor any End User will use the Service Offerings
                    contrary to that which is permitted herein. Neither you nor
                    any End User will: (a) modify, distribute, alter, tamper with,
                    repair, or otherwise create derivative works of any Content,
                    in whole or in part, included in the Service Offerings; (b)
                    reverse engineer, disassemble, or decompile the Service
                    Offerings or apply any other process or procedure to derive
                    the source code of any software included in the Service
                    Offerings (except to the extent applicable law doesn't
                    allow this restriction); or (d) rent, lease, assign,
                    distribute, public, transfer, resell or sublicense the Service
                    Offerings; (e) remove any proprietary notices from the Service
                    Offerings or any Bio-Rad Content; or (f) use the Services or
                    any Bio-Rad Content in any manner or for any purpose that
                    infringes, misappropriates or otherwise violates any
                    intellectual property rights or other rights of any person, or
                    that violates any applicable law.
                </span>
            </p>

            <p>
                <span className="c0">7.6 Reservation of Rights</span>
                <span className="c1">
                    Bio-Rad reserves all rights not expressly granted to you in
                    this Agreement. Except for the limited rights and licenses
                    expressly granted to you herein, nothing in this Agreement
                    grants, by implication, waiver, estoppel, or otherwise, to you
                    or any third party any intellectual property rights or other
                    right, title, or interest in or to the Bio-Rad Content.
                </span>
            </p>

            <p className="h5">8. Indemnification.</p>

            <p>
                <span className="c0">8.1 General Indemnification.</span>
                <span className="c1">
                    You will defend, indemnify, and hold harmless us, our
                    affiliates and licensors, and each of their respective
                    employees, officers, directors, and representatives from and
                    against any losses arising out of or relating to any
                    third-party claim concerning: (a) your or any End Users'
                    use of the Service Offerings (including any activities under
                    your Bio-Rad account); (b) breach of this Agreement or
                    violation of applicable law; (c) a dispute between you and any
                    End User; (d) a claim related to infringement of Your Content;
                    or (e) any disclosure of personally identifiable information
                    by you to Bio-Rad; or (f) any violation of your obligations
                    under Section 7 of this Agreement. You will reimburse us for
                    reasonable attorneys' fees, as well as our
                    employees' and contractors' time and materials
                    spent responding to any third party subpoena or other legal
                    order or process associated with third party claims described
                    in (a) through (c) above.
                </span>
            </p>

            <p>
                <span className="c0">
                    8.2 Intellectual Property Indemnification by Bio-Rad.
                </span>
                <span className="c1">
                    Subject to the limitations in this Section 8, Bio-Rad will
                    defend you and your employees, officers, and directors against
                    any third-party intellectual property infringement claim. For
                    any such claim, Bio-Rad will, at its election, either: (i)
                    procure the rights to use that portion of the Services alleged
                    to be infringing; (ii) replace the alleged infringing portion
                    of the Services with a non-infringing alternative; (iii)
                    modify the alleged infringing portion of the Services to make
                    it non-infringing; or (iv) terminate the allegedly infringing
                    portion of the Services or this Agreement.
                </span>
            </p>

            <p>
                <span className="c0">8.3 Process.</span>
                <span className="c1">
                    The obligations under this Section 8 will apply only if the
                    party seeking defense or indemnity: (a) gives the other party
                    prompt written notice of the claim; (b) permits the other
                    party to control the defense and settlement of the claim; and
                    (c) reasonably cooperates with the other party (at the other
                    party's expense) in the defense and settlement of the
                    claim. In no event will a party agree to any settlement of any
                    claim that involves any commitment, other than the payment of
                    money, without the written consent of the other party.
                </span>
            </p>

            <p>
                <span className="c0">9. Disclaimers.</span>
                <span className="c1">
                    The Service Offerings are provided "as is". Except
                    to the extent prohibited by law or statute, we make no
                    representations or warranties of any kind, whether express or
                    implied, and we expressly disclaim the same (including as
                    related to warranties of merchantability, fitness, or
                    non-infringement, and whether arising out of any course of
                    dealing or usage of trade). We do not guarantee that the
                    service offerings (or any third-party content) will be
                    uninterrupted or error free.
                </span>
            </p>

            <p>
                <span className="c0">10. Limitations of Liability.</span>
                <span className="c1">
                    We and our affiliates and licensors will not be liable to you
                    for any direct, indirect, incidental, special, consequential
                    or exemplary damages (including lost profits, opportunities,
                    goodwill, or data), even if a party has been advised of the
                    possibility of such damages. Neither we nor any of our
                    affiliates or licensors will be responsible for any
                    compensation, reimbursement, or damages arising out of: (a)
                    your inability to use the services, including as a result of
                    (i) termination or suspension of this Agreement, (ii)
                    discontinuation of the Service Offerings, or (iii) any
                    unanticipated or unscheduled downtime; (b) the cost of
                    procurement of substitute goods or services or (c) any
                    unauthorized access to, alteration of, or deletion,
                    destruction, damage, loss of failure to store any of Your
                    Content or other data. In any case, except for any associated
                    payment obligations, our and our affiliates'
                    licensors' aggregate liability under this Agreement will
                    not exceed the amount you actually pay us under this Agreement
                    for the service that gave rise to the claim during the 12
                    months before the liability arose. The limitations in this
                    Section apply only to the maximum extent permitted by law.
                </span>
            </p>

            <p className="h5">11. Modifications to the Agreement.</p>

            <p>
                <span className="c0">
                    We may modify this Agreement including any policies at any
                    time by posting a revised version on the Bio-Rad Site or by
                    otherwise notifying you with the contact information you
                    provide when registering your account. By continuing to use
                    the Service Offerings after the effective date of any
                    modifications to this Agreement, you agree to be bound by the
                    modified terms.
                </span>
            </p>

            <p className="h5">12. Miscellaneous.</p>

            <p>
                <span className="c0">12.1 Assignment.</span>
                <span className="c1">
                    This Agreement may not be transferred or assigned by a party
                    without the prior written consent of the other party;
                    provided, that either party may transfer or assign this
                    Agreement without consent in connection with a merger,
                    reorganization, change of control or ownership, or transfer or
                    sale of assets or product lines. Additionally, you agree that
                    Bio-Rad may delegate or subcontract any or all of its rights
                    and obligations under this Agreement to one or more of its
                    affiliates or subsidiaries, or any other third party.
                </span>
            </p>

            <p>
                <span className="c0">12.2 Entire Agreement; No Waiver. </span>
                <span className="c1">
                    This Agreement contains the entire agreement between the
                    parties with respect to the subject matter hereof, and no
                    modification or waiver of any of the provisions, or any future
                    representation, promise or addition, shall be binding upon the
                    parties unless agreed to in writing. Waiver of any term,
                    condition, or provision of this Agreement on one occasion
                    shall not constitute a waiver for the purpose of any other
                    occasion.
                </span>
            </p>

            <p>
                <span className="c0">12.3 Force Majeure.</span>
                <span className="c1">
                    We will not be liable or responsible for any failure to
                    perform due to unforeseen circumstances or to causes beyond
                    their reasonable control, including but not limited to acts of
                    God, war, riot, embargoes, acts of civil or military
                    authorities, fire, floods, earthquakes, accidents, strikes, or
                    shortages of transportation, facilities, fuel, energy, labor
                    or materials.
                </span>
            </p>

            <p>
                <span className="c0">12.4 Governing Law.</span>
                <span className="c1">
                    Any dispute arising under this Agreement shall be governed
                    exclusively by the laws of the State of California, with venue
                    in San Francisco, California. The United Nations Convention on
                    Contracts for the International Sale of Goods shall not apply
                    to this Agreement.
                </span>
            </p>

            <p>
                <span className="c0">12.5 Arbitration. </span>
                <span className="c1">
                    In the event a dispute arises with respect to any provision
                    hereof or any alleged breach hereof and the parties are unable
                    to resolve the dispute, the parties agree to resolve such
                    dispute through arbitration, which shall be processed and
                    determined exclusively in Contra Costa County, California, in
                    accordance with the rules of the American Arbitration
                    Association then in effect.
                </span>
            </p>

            <p>
                <span className="c0">
                    12.6 Independent Contractors; Authority to Bind.
                </span>
                <span className="c1">
                    Neither party is an agent of the other party. The relationship
                    between the parties is that of independent contractors.
                    Neither party has any right or authority to assume or create
                    any obligations or to make any representations or warranties
                    on behalf of the other party, whether express or implied, or
                    to bind the other party in any respect whatsoever.
                </span>
            </p>

            <p>
                <span className="c0">12.7 Confidentiality and Publicity.</span>
                <span className="c1">
                    You may use Bio-Rad Confidential Information only in
                    connection with your use of the Service Offerings as permitted
                    under this Agreement. You will not disclose Bio-Rad
                    Confidential Information during the Term or at any time during
                    the 5-year period following the end of the Term. You will take
                    all reasonable measures to avoid disclosure, dissemination or
                    unauthorized use of Bio-Rad Confidential Information,
                    including, at a minimum, those measures you take to protect
                    your own confidential information of a similar nature. You
                    will not issue any press release or make any other public
                    communication with respect to this Agreement or your use of
                    the Service Offerings.
                </span>
            </p>
            <div>
                <span className="c0">12.8 Notice.</span>

                <ul className="no-bullet">
                    <li>
                        <span className="c0">(a) To You.</span>
                        <span className="c1">
                            We may provide any notice to you under this Agreement by:
                            (i) posting a notice on the Bio-Rad Site; or (ii) sending
                            a message to the email address then associated with your
                            account. Notices we provide by posting on the Bio-Rad Site
                            will be effective upon posting and notices we provide
                            otherwise will be sent at the time of transmission. It is
                            your responsibility to keep your contact information
                            current.
                        </span>
                    </li>
                    <li>
                        <span className="c0">(b) To Us.</span>
                        <span className="c1">
                            To give us notice under this Agreement, you must contact
                            us via any means designated in writing (including
                            electronically) by Bio-Rad.
                        </span>
                    </li>
                </ul>
            </div>

            <p>
                <span className="c0">12.9 No Third-Party Beneficiaries.</span>
                <span className="c1">
                    This Agreement does not create, and will not be construed as
                    creating, obligations to or rights enforceable by any person
                    or entity that is not a party to this Agreement.
                </span>
            </p>

            <p>
                <span className="c0">12.10 Severability.</span>
                <span className="c1">
                    If any provision of this Agreement shall be held to be
                    prohibited or unenforceable in any jurisdiction, such
                    provision shall be deemed ineffective only in such
                    jurisdiction. The remaining provisions of this Agreement shall
                    remain in full force and effect. The parties shall use their
                    best efforts to replace the provision prohibited or held
                    unenforceable with a legal provision approximating the
                    original intent of the parties as far as possible.
                </span>
            </p>

            <p>
                <span className="c0">12.11 Export Regulations.</span>
                <span className="c1">
                    To the extent applicable, you are prohibited from exporting,
                    re-exporting, or releasing the Services outside the US or
                    otherwise in contravention of any country or other
                    jurisdiction's laws prohibiting export, re-export, or
                    release of such Services. You agree to comply with all export
                    or re-export laws, including with respect to securing any
                    applicable licenses, permits, or other approvals, in order to
                    export, re-export, or release the Services outside the US.
                </span>
            </p>

            <p>
                <span className="c0">12.12 US Government Rights.</span>
                <span className="c1">
                    To the extent that: (a) the Services fall within the meaning
                    of "commercial item," as defined by 48 C.F.R. &sect; 2.101,
                    and include "commercial computer software" and "commercial
                    computer software documentation" as defined by 48 C.F.R.
                    &sect; 12.212; and (b) you are a US government agency or
                    contractor, then: you are entitled to the rights granted to
                    other Bio-Rad customers pursuant to 48 C.F.R. &sect; 227.7201
                    through .7204, with respect to the Department of Defense and
                    their contractors, or alternatively, 48 C.F.R. &sect; 12.212,
                    with respect to all other US Government customers and their
                    contractors.
                </span>
            </p>

            <p className="h5">13. Definitions.</p>

            <p>
                <span className="c0">"Account Information"</span>
                <span className="c1">
                    means information about you that you provide to us in
                    connection with the creation or administration of your Bio-Rad
                    account. For example, Account Information includes names,
                    usernames, phone numbers, email addresses and billing
                    information associated with your Bio-Rad account.
                </span>
            </p>

            <p>
                <span className="c0">"Aggregated Statistics"</span>
                <span className="c1">
                    means data which is generated by Bio-Rad, including its
                    affiliates, agents, or subcontractors, as a result of
                    monitoring your use of the Service Offerings. Such data is
                    used by Bio-Rad in an aggregated and anonymized manner,
                    including to compile statistical and performance information
                    related to the provision and operation of the Service
                    Offerings.
                </span>
            </p>

            <p>
                <span className="c0">
                    "Bio-Rad Confidential Information"
                </span>
                <span className="c1">
                    means all nonpublic information disclosed by us, our
                    affiliates, business partners or our or their respective
                    employees, contractors or agents that is designated as
                    confidential or that, given the nature of the information or
                    circumstances surrounding its disclosure, reasonably should be
                    understood to be confidential. Bio-Rad Confidential
                    Information includes: (a) nonpublic information relating to
                    our or our affiliates or business partners' technology,
                    customers, business plans, promotional and marketing
                    activities, finances and other business affairs; (b)
                    third-party information that we are obligated to keep
                    confidential; and (c) the nature, content and existence of any
                    discussions or negotiations between you and us or our
                    affiliates. Bio-Rad Confidential Information does not include
                    any information that: (i) is or becomes publicly available
                    without breach of this Agreement; (ii) can be shown by
                    documentation to have been known to you at the time of your
                    receipt from us; (iii) is received from a third party who did
                    not acquire or disclose the same by a wrongful or tortious
                    act; or (iv) can be shown by documentation to have been
                    independently developed by you without reference to the
                    Bio-Rad Confidential Information.
                </span>
            </p>

            <p>
                <span className="c0">"Bio-Rad Content"</span>
                <span className="c1">
                    means Content we or any of our affiliates make available in
                    connection with the Services or on the Bio-Rad Site to allow
                    access to and use of the Services, including APIs; WSDLs; user
                    or other instructional guides or documentation supplied or
                    made accessible by Bio-Rad; sample code; software libraries;
                    command line tools; proofs of concept; templates; and other
                    related technology (including any of the foregoing that are
                    provided by our personnel) and intellectual property supplied
                    by Bio-Rad. Bio-Rad Content does not include Third-Party
                    Content. Bio-Rad content includes Aggregated Statistics, as
                    well as any Feedback.
                </span>
            </p>

            <p>
                <span className="c0">"Bio-Rad Site"</span>
                <span className="c1">
                    means {document.location.host} (and any successor or related
                    site designated by us), as may be updated by us from time to
                    time.{" "}
                    <a
                        href="https://www.bio-rad.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Bio-Rad Site
                    </a>{" "}
                    also includes, as applicable.
                </span>
            </p>

            <p>
                <span className="c0">"Content"</span>
                <span className="c1">
                    means software (including machine images), data, text, audio,
                    video, images, files, or other information.
                </span>
            </p>

            <p>
                <span className="c0">"End User"</span>
                <span className="c1">
                    means any individual or entity that directly or indirectly
                    through another user: (a) accesses or uses Your Content; or
                    (b) otherwise accesses or uses the Service Offerings under
                    your account. The term "End User" does not include
                    individuals or entities when they are accessing or using the
                    Services or any Content under their own Bio-Rad account,
                    rather than under your account.
                </span>
            </p>

            <p>
                <span className="c0">"Feedback"</span>
                <span className="c1">
                    means any recommended changes or other suggestions that you
                    provide Bio-Rad regarding changes to the Services, including
                    without limitation, new features or functionality related
                    thereto, or any comments, questions, suggestions or the like.
                </span>
            </p>

            <p>
                <span className="c0">"Policies"</span>
                <span className="c1">
                    means the Privacy Policy, any applicable service terms
                    associated with the Service Offerings or Bio-Rad products
                    associated with the use of the Service Offerings, all
                    restrictions described in the Bio-Rad Content and on the
                    Bio-Rad Site.
                </span>
            </p>

            <p>
                <span className="c0">"Privacy Policy"</span>
                <span className="c1">
                    means the privacy policy located at [insert hyperlink/AP to
                    review] as it may be updated by us from time to time.
                </span>
            </p>

            <p>
                <span className="c0">"Service"</span>
                <span className="c1">
                    means each of the services, including all Service Offerings,
                    made available by us or our affiliates, including those web
                    services described in the Service Terms, including Bio-Rad
                    Content, as well as any Bio-Rad trademarks, service marks,
                    service or trade names, logos, and other designations, and any
                    other product or service provided by us under this Agreement.
                    Services do not include Third-Party Content.
                </span>
            </p>

            <p>
                <span className="c0">"Service Offerings"</span>
                <span className="c1">
                    means the services (including associated APIs) that we make
                    available to you in order to analyze, review, aggregate,
                    compile, or otherwise review Your Content. Service Offerings
                    are customarily provided in conjunction with Bio-Rad's
                    provision of other equipment or consumables to you which
                    generate portions of Your Content. Your use or access to
                    Service Offerings are governed by this Agreement as well as
                    any Service Offering-specific Service Terms that we may
                    provide to you. Service Offerings do not include Third-Party
                    Content.
                </span>
            </p>

            <p>
                At this time, Bio-Rad provides two (2) different Service
                Offerings, either A) via a web-hosted platform, in which you
                access Bio-Rad Content through the Bio-Rad Site, or B) via
                Bio-Rad's provision to you of a script or library which
                you will download to your own hardware in order to access
                Bio-Rad's Content and associated Services.
            </p>

            <p>
                <span className="c0">"Service Terms" </span>
                <span className="c1">
                    means the rights and restrictions for particular Service
                    Offerings to the extent provided to you before registering
                    your account.
                </span>
            </p>

            <p>
                <span className="c0">"Term" </span>
                <span className="c1">
                    means the term of this Agreement described in Section 6.
                </span>
            </p>

            <p>
                <span className="c0">"Third-Party Content" </span>
                <span className="c1">
                    means Content made available to you by any third party on the
                    Bio-Rad Site or in conjunction with the Services.
                </span>
            </p>

            <p>
                <span className="c0">"Your Content" </span>
                <span className="c1">
                    means Content that you or any End User transfers to us for
                    processing, storage or hosting by the Services in connection
                    with your Bio-Rad account and any computational results that
                    you or any End User derive from the foregoing through their
                    use of the Services, including any further analysis or other
                    data generated by you as a result of uploading your Content to
                    the Bio-Rad Site and using Bio-Rad's Services. Your
                    Content does not include Account Information or Aggregated
                    Statistics
                </span>
            </p>
        </>
    )
}