import React from "react";
import Button from "@material-ui/core/Button";

export const SubmitButton = ({ color, disabled, children, ...props }) => {

    return (
        <Button
            type="submit"
            variant="contained"
            color={color}
            disabled={disabled}
            style={{ justifyContent: 'center', fontWeight: 'bold' }}
            {...props}
        >
            {children}
        </Button>
    );
};
