import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { USER_MESSAGE } from "../../../actions/User";
import moment from "moment";
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';

const deleteTagBySampleId = async (variables) => {
    const { fileId, id } = variables;
    const headerConfig = setHeaders();
    const { data } = await axios
        .delete(`${config.ENDPOINTS["main"]}/file/${fileId}/tagId/${id}`, {
            headers: headerConfig
        });

    return data;
}

const useMutateSampleTagDelete = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(deleteTagBySampleId, {

        onSuccess: (data, variables) => {
            queryClient.invalidateQueries("tags");
            queryClient.invalidateQueries(["ExperimentDetail", variables.experimentId]);
            dispatch({
                type: USER_MESSAGE,
                msgType: "success",
                text: "sample.tagRemoved",
                key: moment().unix()
            });

        },
        onError: () => {
            dispatch({
                type: USER_MESSAGE,
                msgType: "error",
                text: "tag.409",
                key: moment().unix()
            });
        }
    })
}

export default useMutateSampleTagDelete;
