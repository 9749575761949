import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { getAccessToken } from "actions/Azure";
import { useQuery } from 'react-query';
import { useMsal } from "@azure/msal-react";


const getPipelines = async () => {
    const headerConfig = setHeaders();
    let getPipelinesUrl = `${config.ENDPOINTS["main"]}/pipelines`;

    const { data } = await axios
        .get(getPipelinesUrl, {
            headers: headerConfig
        })
    return data;
}

export default function useFetchPipelines() {

    const { instance } = useMsal();
    const accessToken = getAccessToken();

    return useQuery("Pipelines", getPipelines, {
        onSuccess: () => {
            //success handler
        },

        onError: (error) => {
            if (error && error.response && error.response.status === 401) {
                instance.logoutRedirect({ idTokenHint: accessToken });
            }
        }
    })
}