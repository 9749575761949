import React from "react";
import AddIcon from "images/add-circle-big.svg";
import AddIconHover from "images/add-circle-big-hover.svg";
import { Chip, Box, Paper, Modal } from "@mui/material";
import * as actions from "actions/ExperimentAdd";
import CancelIcon from "images/icons-close-x-icon-black-small.svg";
import CancelIconHover from "images/icons-close-x-icon-black-small-hover.svg";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";

export function TagsModal({ mode, action }) {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const { allTags, newTags, openAddedTags, open } = experimentState;
  const modalType = mode !== "All Tags" ? {
    actionType: actions.SET_OPEN_ADDED_TAGS,
    isOpen: openAddedTags,
    tags: newTags,
  } : {
    actionType: actions.SET_OPEN,
    isOpen: open,
    tags: allTags,
  };

  const { actionType, isOpen, tags } = modalType;
  return (
    <Modal
      open={isOpen}
      onClose={() =>
        dispatchEvent({
          type: actionType,
          payload: false,
        })
      }
      style={{ display: "flex" }}
    >
      <Paper className="all-tags-modal">
        <Box>
          <p className="eula-header">{mode}</p>
        </Box>
        <Box className="top-border small-padding-top">
          <Paper elevation={0} className="tags-box">
            {tags?.map((tag, index) => (
              <Chip
                className="tag-chip"
                key={index}
                label={tag}
                onDelete={action}
                deleteIcon={
                  <img
                    src={mode !== "All Tags" ? CancelIcon : AddIcon}
                    name={index}
                    style={{ height: "12px", width: "12px" }}
                    id={index}
                    onMouseOver={(e) => {
                      e.target.src =
                        mode !== "All Tags" ? CancelIconHover : AddIconHover;
                    }}
                    onMouseOut={(e) => {
                      e.target.src = mode !== "All Tags" ? CancelIcon : AddIcon;
                    }}
                  />
                }
              />
            ))}
          </Paper>
        </Box>
        <Box id="addtag-footer">
          <a
            className="blue-link medium-text"
            onClick={() =>
              dispatchEvent({
                type: actionType,
                payload: false,
              })
            }
          >
            OK
          </a>
        </Box>
      </Paper>
    </Modal>
  );
}
