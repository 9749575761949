import React, { useState } from "react";
import 'scss/App.scss';
// import "./Login.css";
import { useMsal } from "@azure/msal-react";
import { Box, Modal, Paper, FormControlLabel, Checkbox } from "@mui/material";
import CancelIcon from 'images/icons-close-x-icon-small.svg';
import CancelIconHover from 'images/icons-close-x-icon-black-small-hover.svg';
import useMutateEulaValues from "components/Hooks/Login/useMutateEulaValues";
import { EulaText } from "./EulaText";
import { getAccessToken } from "actions/Azure";

const EulaForm = () => {
  const sendEula = useMutateEulaValues();
  const accessToken = getAccessToken();
  const { instance } = useMsal();
  const [isHover, setIsHover] = useState(false);
  const handleLogOut = () => {
    instance.logoutRedirect({
      idTokenHint: accessToken
    });
  }

  const handleChange = () => {
    sendEula.mutate();
    setTimeout(() => {
      instance.loginRedirect();
    }, 100);
  }

  return (
    <Box style={{ marginTop: "40px" }} textAlign='center'>
      <Modal open>
        <Paper className="eula">
          <Box>
            <p className="eula-header"><b>End User License Agreement</b></p>
            <img
              className="blue-link right"
              onClick={handleLogOut}
              data-testid="eula-logout"
              alt={"logout"}
              src={isHover ? CancelIconHover : CancelIcon}
              onMouseOver={() => { setIsHover(true) }}
              onMouseOut={() => { setIsHover(false) }}
              role="button"
            />
          </Box>
          <Box className="eula-text top-border">
            <EulaText />
          </Box>
          <Box className="eula-footer">
            <FormControlLabel
              label="Accept Terms and Conditions"
              control={
                <Checkbox onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} color="primary"></Checkbox>
              }
            >
            </FormControlLabel>
          </Box>
        </Paper>
      </Modal>
    </Box>
  );
};

export default EulaForm;