import React from 'react';
import 'scss/App.scss';
import { Box } from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import TopNav from "../TopNav";
import SideNav from "../SideNav";
import LinearProgress from '@mui/material/LinearProgress';

function LoadingScreen() {

    return (
        <StylesProvider injectFirst>
            <div id='wrapper'>
                <TopNav />
                <div className='home-backdrop'>
                    <SideNav page="Home" />
                    <Box style={{ marginTop: '45px' }} sx={{ width: '100%' }}>
                        <LinearProgress color="success" />
                    </Box>
                </div>
            </div>
        </StylesProvider>
    );
}

export default LoadingScreen;
