import React from "react";
import { Link } from "react-router-dom";
import "scss/App.scss";
import StylesProvider from "@mui/styles/StylesProvider";
import { Button, Box, Paper, Grid } from "@mui/material";
import TopNav from "components/TopNav";
import SideNav from "components/SideNav";
import BRShapes from "images/B-Rshapes.png";
import SEQzyme from "images/SEQzyme-video-image.png";
import experimentIcon from "images/icons-table-atgc-experiment-2.svg";
import ExperimentAdd from "components/Experiment/Add/index.jsx";
import { ExperimentContext } from "contexts/ExperimentContext";

function WelcomeScreen() {
  const handleClick = () => {
    return (
      <ExperimentContext>
        <ExperimentAdd />
      </ExperimentContext>
    );
  };

  return (
    <StylesProvider injectFirst>
      <div id="wrapper">
        <TopNav />
        <div className="home-backdrop">
          <SideNav page="Home" />
          <form className="content">
            <Box
              style={{ backgroundImage: `url("${BRShapes}")` }}
              id="welcome-wrapper"
            >
              <Link to="/create-experiment/pipeline-selection" style={{ textDecoration: "none" }}>
                <Button
                  onClick={handleClick}
                  type="submit"
                  className="home-button green-button"
                >
                  Create New Experiment
                </Button>
              </Link>
              <Paper id="welcome">
                <Grid container style={{ height: "100%" }}>
                  <Grid item xs={9}>
                    <Box id="welcome-header-box">
                      <img src={experimentIcon} />
                      <h1 id="welcome-header">WELCOME TO SEQSENSE</h1>
                    </Box>
                    <Box id="welcome-text">
                      <p>
                        Your most recent experiments will display here for quick
                        and easy access.
                      </p>
                      <p>
                        To view all of your experiments, go to{" "}
                        <q>Experiments</q> in the left navigation panel.
                      </p>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box
                      style={{ backgroundImage: `url(${SEQzyme})` }}
                      id="welcome-panel"
                    >
                      <div style={{ marginLeft: "15px", marginRight: "10px" }}>
                        <div className="side-panel-header">
                          <p style={{ fontSize: "26px" }}>EXPLORE SEQSENSE</p>
                          <p>
                            SeqSense NGS Data Analysis provides a bioinformatics
                            pipeline uniquely optimized for mapping, aligning,
                            counting, and normalizing reads generated from
                            libraries containing both short and long RNA.
                          </p>
                        </div>

                        <div className="side-panel-text">
                          <p
                            style={{ fontWeight: "500", marginBottom: "10px" }}
                          >
                            SEQSENSE GUIDES
                          </p>
                          <a
                            target="_blank"
                            className="light-orange-link"
                            href="https://www.bio-rad.com/SeqSenseGettingStarted"
                          >
                            Getting Started
                          </a>
                          <a
                            target="_blank"
                            className="light-orange-link"
                            href="https://www.bio-rad.com/SeqSenseFAQs"
                          >
                            Frequently Asked Questions
                          </a>
                          <a
                            target="_blank"
                            className="light-orange-link"
                            href="https://www.bio-rad.com/SeqSenseCloudManual"
                          >
                            SeqSense Manual
                          </a>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </form>
        </div>
      </div>
    </StylesProvider>
  );
}

export default WelcomeScreen;
