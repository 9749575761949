import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useQuery } from 'react-query';

const getReportsById = async (queryExperiment) => {
    const experimentId = queryExperiment.queryKey[1];
    const headerConfig = setHeaders();

    const { data } = await axios
        .get(`${config.ENDPOINTS["main"]}/experiment/${experimentId}/reports`, {
            headers: headerConfig
        });
    return data;

};

export default function useFetchExperimentReportsById(experimentId, pollingStatus) {

    return useQuery(["experimentReports", experimentId], getReportsById, {
        enabled : !pollingStatus,
    });
}