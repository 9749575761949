import React from "react";
import { Link } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import BRLogo from '../images/icons-header-bio-rad-logo.svg';
import { useIsAuthenticated } from '@azure/msal-react';


const TitleLogo = () => {
    const isAuthenticated = useIsAuthenticated();
    const destination = isAuthenticated ? '/home' : '/login';

    return (
        <Grid item xs={8}>
            <Link to={destination} style={{ textDecoration: 'none' }}>
                <Button style={{ backgroundColor: 'white' }}>
                    <img src={BRLogo} alt="Bio-Rad" />
                </Button>
                <h4 className={'nav-text'}>
                    SeqSense
                </h4>
            </Link>
        </Grid >
    );
}

export default TitleLogo;
