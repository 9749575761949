import { Box, Paper, Table, TableBody, TableContainer, LinearProgress } from "@mui/material";
import Toaster from "components/common/Toaster.jsx";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions/ExperimentAdd";
import { REMOVE_FILE_UPLOAD } from "actions/Experiments";
import { EnhancedTableHead } from "components/common/ExistingTab/EnhancedTableHead";
import useFetchExistingSampleFilesByPipeline from "components/Hooks/Pipelines/useFetchExistingSampleFilesByPipeline";
import useMutateCopyExistingFilesFromAWS from "components/Hooks/Pipelines/useMutateCopyExistingFilesFromAWS";
import useDeleteExistingFile from "components/Hooks/SampleFiles/useDeleteExistingFile";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";



import React, { useEffect } from "react";
import "scss/App.scss";
import { ExistingTabFooter } from "components/common/ExistingTab/ExistingTabFooter";
import { ExistingTabBody } from "components/common/ExistingTab/ExistingTabBody";
import { useDispatch } from "react-redux";
import ExistingDialog from "./ExistingDialog";
import { useQueryClient } from "react-query";



const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: 50,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 7px !important",
    marginBottom: "0",
  },
  tableFooter: {
    backgroundColor: "#f1f1f1",
    display: "flex !important",
    justifyContent: "flex-end",
    border: "1px solid #dfe0df",
  },
}));

const headerCells = [
  { id: "name", label: "NAME" },
  { id: "size", label: "SIZE" },
  { id: "createdDt", label: "DATE" },
  { id: "progress", label: "PROGRESS", disable: true },
];

function ExistingTab() {
  const classes = useStyles();
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const copyFiles = useMutateCopyExistingFilesFromAWS();
  const deleteExistingFile = useDeleteExistingFile();
  const { isSuccess } = deleteExistingFile;
  const queryClient = useQueryClient();
  const {
    tabValue,
    pipelineId,
    experimentId,
    cancelEvents,
    uploadFiles,
  } = experimentState;

  const dispatch = useDispatch();

  const fetchExistingParams = { pipelineId, experimentId };

  const { data, isFetching } = useFetchExistingSampleFilesByPipeline(
    tabValue === "existingSampleFiles" ? fetchExistingParams : undefined
  );

  useEffect(() => {

    if (uploadFiles?.length < 1) {
      dispatchEvent({
        type: actions.SET_DISABLE_UPLOAD,
        payload: true,
      });
    } else {
      dispatchEvent({
        type: actions.SET_DISABLE_UPLOAD,
        payload: false,
      });

      //refetch();
    }

  }, [uploadFiles]);

  const removeFromExistingFiles = (fileId, name) => {

    dispatchEvent({
      type: actions.SET_DISABLE,
      payload: false,
    });
    dispatchEvent({
      type: actions.SET_FILTER_UPLOADED_FILES,
      payload: fileId,
    });
    dispatchEvent({
      type: actions.SET_FILTER_SELECTED,
      payload: fileId,
    });
    dispatchEvent({
      type: actions.SET_FILTER_ALL_FILES,
      payload: fileId,
    });
    // this was done to remove any files that didnt have database file Id yet.
    // Otherwise those files still get included in the save experiment payload, hence not actually removed from the experiment
    // and reapear in the list after refetch
    dispatchEvent({
      type: actions.SET_FILTER_ALL_FILES_BY_NAME,
      payload: name,
    });
  };

  const addFile = (row, newSelected) => {

    if (!row || !newSelected) return;
    const { size, name, createdDt, fileId } = row;

    const file = {
      fileId: fileId,
      size: Math.trunc(size),
      name: name,
      timeLeft: "",
      percent: 100,
      speed: "999",
      startedAt: new Date(createdDt).getTime() / 1000,
      parts: [{ partName: name, uploaded: Math.trunc(size) }],
    };

    dispatchEvent({
      type: actions.SET_FILTER_RETRY,
      payload: name,
    });

    dispatchEvent({
      type: actions.SET_DISABLE_UPLOAD,
      payload: true,
    });

    dispatchEvent({ type: actions.SET_SELECTED, payload: newSelected });

    copyFiles.mutate({
      experimentId,
      fileNameList: [name],
      pipelineId: pipelineId,
      fileId: fileId,
      addCancelProgressBarToken: (source) => {
        const events = Object.assign({}, cancelEvents);
        events[file.fileId] = source;
        dispatchEvent({ type: actions.SET_CANCEL_EVENTS, payload: events });
      },
      onSuccess: () => {
        dispatchEvent({
          type: actions.SET_ALL_FILES_PUSH,
          payload: file,
        });

        dispatchEvent({
          type: actions.SET_UPLOADED_FILES,
          // payload: name,
          payload: fileId
        });

        dispatchEvent({
          type: actions.SET_DISABLE,
          payload: false,
        });
      },
      onError: (err) => {
        if (err.message !== "user-canceled") {
          dispatchEvent({
            type: actions.SET_RETRY,
            //payload: name,
            payload: fileId
          });
          removeFromExistingFiles(fileId, name);
        }
        dispatchEvent({
          type: actions.SET_DISABLE_UPLOAD,
          payload: false,
        });

        dispatchEvent({
          type: actions.SET_DISABLE,
          payload: true,
        });
      },
    });
  };

  const removeFile = (row) => {
    console.log("Remove file: ", row);
    if (!row) return;
    const { name, fileId } = row;

    if (cancelEvents[fileId]?.cancel) {
      cancelEvents[fileId].cancel("user-canceled");
    }
    const str = `${experimentId}**${name}**${fileId}`;

    deleteExistingFile.mutate(str, {
      onSuccess: () => {
        //refetch();
        removeFromExistingFiles(fileId, name);
        queryClient.invalidateQueries("existingSampleFilesByPipeline");

      },
    });

    dispatch({ type: REMOVE_FILE_UPLOAD, file: row });
  };

  const callOutToaster = isSuccess && (
    <Toaster
      text={"upload.removal"}
      autoHideDuration={10000}
      type={"success"}
    />
  );

  if (tabValue !== "existingSampleFiles") return null;

  return (
    <Paper style={{ display: "flex", flexDirection: "column" }}>
      <ExistingDialog />
      <Box style={{ flex: "1", margin: "8px 16px", height: "400px" }}>
        <Box style={{ display: "inline-block", height: "40px" }}>
          <p
            style={{ display: "inline", marginLeft: "15px", fontSize: "14px" }}
          >
            The <strong>Existing Sample Files</strong> panel displays recently uploaded sample
            files from your previous experiments.
            You can use any of these samples in your new experiment.
          </p>

        </Box>
        {isFetching ? <Box sx={{ width: '100%' }}>
          <LinearProgress style={{ marginTop: "50px" }} color="success" />
        </Box> :
          <Box className="dragndrop-box-large">
            <Box className="dragndrop-rows">

              <TableContainer style={{ height: "300px" }}>
                <Table
                  className={classes.table}
                  aria-labelledby="Existing Sample Files Tab"
                  size={"small"}
                  aria-label="Existing Sample Files Tab"
                  stickyHeader
                >
                  <EnhancedTableHead
                    classes={classes}
                    headerCells={headerCells}
                  />

                  <TableBody>

                    <ExistingTabBody
                      data={data}
                      addFile={addFile}
                      removeFile={removeFile}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>}
      </Box>
      <ExistingTabFooter classes={classes} data={data} />
      {callOutToaster}
    </Paper>
  );
}

export default ExistingTab;
