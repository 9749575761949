import { produce } from 'immer';

export const UPDATE_SERVICEWORKER = "UPDATE_SERVICEWORKER";

export function updateServiceworker() {
  return {
    type: UPDATE_SERVICEWORKER
  };
}

const initialState = {
  serviceWorkerUpdated: false
};

const serviceWorker = produce((draft = initialState, action) => {
  const { type } = action;
  switch (type) {
    case UPDATE_SERVICEWORKER: {
      draft.serviceWorkerUpdated = true;
      return draft;
    }
    default:
      return draft;
  }
});

export default serviceWorker;
