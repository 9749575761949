import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { getAccessToken } from "actions/Azure";
import { useQuery } from 'react-query';
import { useMsal } from "@azure/msal-react";

const fetchAllSamples = async () => {
    const headerConfig = setHeaders();
    let listUrl = `${config.ENDPOINTS["main"]}/files/list`;

    const { data } = await axios
        .get(listUrl, {
            headers: headerConfig
        })

    return data;
}

export default function useFetchAllSampleFiles() {

    const { instance } = useMsal();
    const accessToken = getAccessToken();
    return useQuery("ALLSAMPLEFILES", fetchAllSamples, {
        onSuccess: () => {
            //success handler
        },

        onError: (err) => {
            if (err && err.response && err.response.status === 401) {
                instance.logoutRedirect({
                    idTokenHint: accessToken
                });
            }
        }
    })
}