const getOriginalFileName = (fileNameWithExt) => {
    let fileName = fileNameWithExt.split(".")[0];
    const re = /(.*)_(.*)/;
    const filenameParts = re.exec(fileName);
    const firstPart = filenameParts[1];
    const secondPart = filenameParts[2];
    // check if second part has leading 0s
    if(/^0[0-9].*$/.test(secondPart)){
      return fileName; // last part has leading 0s (001, 002) so no numeric increment appended to the file
    }else{
      return firstPart;
    }
}

export default getOriginalFileName;