import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    useNextVariants: true
  },
  shape: {
    borderRadius: 8
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#00a650"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#ccc",
      contrastText: "#fff",
      background: "#ff0000"
    }
    // error: will use the default color
  },
  button: {
    background: "#ff0000"
  },

  overrides: {
    MuiDrawer: {
      paper: {
        minWidth: 256
      },
      paperAnchorDockedLeft: {
        borderRight: "none"
      }
    }
  }
});

export default theme;
