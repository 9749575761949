import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch,  } from 'react-redux';
import { USER_MESSAGE } from "../../../actions/User";
import moment from "moment";

const deleteSample = async(fileId) => {
    const headerConfig = setHeaders();
    let listUrl = `${config.ENDPOINTS["main"]}/file/delete/${fileId}`;
    const { data } = await axios
        .delete(listUrl,{
            headers: headerConfig
    })

    return data;
}

function useDeleteSampleFiles(fileId) {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(["DELETESAMPLEFILE", fileId], deleteSample, {
        onSuccess: () => {
            dispatch({
                type: USER_MESSAGE,
                msgType: "success",
                text: "sample.deleted",
                key: moment().unix()
            });
            queryClient.invalidateQueries( "ALLSAMPLEFILES" )
        },

        onError: () => {
            dispatch({
                type: USER_MESSAGE,
                msgType: "error",
                text: "sample.deleted",
                key: moment().unix()
            });        }
    })
}

export default useDeleteSampleFiles;