// this reducer is no longer required once the ability to add multiple pipeines is finalized thru the front end

import { reducer as formReducer } from "redux-form";
import { produce } from 'immer';

export default formReducer.plugin({
    sampleFrm: produce((draft, action) => {
        switch (action.type) {
            default: {
                return draft
            }
        }

    })

});
