import React from "react";
import { Chip, Box, Paper, Grid, Modal } from "@mui/material";
import * as actions from "actions/ExperimentAdd";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import useFetchTags from "components/Hooks/Tags/useFetchTags";
import AddIcon from "images/add-circle-big.svg";
import AddIconHover from "images/add-circle-big-hover.svg";
import { MAXTAGLIMIT } from "components/common/Constants/appConstants";
import { TagsModal } from "./TagsModal";

const AvailableTags = () => {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const { allTags, newTags, addedTagsLength, open } = experimentState;
  const tagsData = useFetchTags();

  const handleAddTag = (e) => {
    if (newTags.length > MAXTAGLIMIT) {
      dispatchEvent({
        type: actions.SET_ADDED_TAGS_LENGTH,
        payload: newTags.length + addedTagsLength,
      });
      return;
    }
    const tempAllTags = [...allTags];
    const tempNewTags = [...newTags];
    const movingTag = tempAllTags.splice(e.target.name, 1);
    dispatchEvent({ type: actions.SET_ALL_TAGS, payload: tempAllTags });
    tempNewTags.push(movingTag[0]);
    dispatchEvent({ type: actions.SET_NEW_TAGS, payload: tempNewTags });
    dispatchEvent({ type: actions.SET_DISABLE, payload: false });
  };

  return (
    <>
      <Grid item xs={3}>
        <p className="tags-header">
          AVAILABLE TAGS({allTags.length}){" "}
          <a
            className="blue-link"
            onClick={() =>
              dispatchEvent({ type: actions.SET_OPEN, payload: true })
            }
          >
            View All
          </a>
        </p>
        {tagsData.isSuccess &&
          tagsData?.data?.data.length > 0 &&
          allTags.slice(0, 5).map((tag, index) => (
            <Chip
              className="tag-chip"
              key={index}
              label={tag}
              onDelete={handleAddTag}
              deleteIcon={
                <img
                  data-testid={`${tag}-add-icon`}
                  src={AddIcon}
                  name={index}
                  onMouseOver={(e) => {
                    e.target.src = AddIconHover;
                  }}
                  onMouseOut={(e) => {
                    e.target.src = AddIcon;
                  }}
                />
              }
            />
          ))}
      </Grid>
      {tagsData.isSuccess && tagsData?.data?.data.length > 0 && (
        <TagsModal mode={"All Tags"} action={handleAddTag} />
      )}
    </>
  );
};

export default AvailableTags;
