import { nextFlowInputMap } from "components/Experiment/Add/config/nextFlowInputMap";
import { pipelineConfigs } from "components/Experiment/Add/config/pipelineConfigs";

const useGetPipelineDetails = () => {
  return {
    nextFlowInputMap,
    pipelineConfigs,
  };
};

export default useGetPipelineDetails;
