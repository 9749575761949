import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation } from 'react-query';
import { VERSION }  from "../../../app.config";

const eulaSend = async () => {
    const postData = {
        acceptance: 1,
        ver: VERSION,
    };
    const headerConfig = setHeaders();
    let eulaUrl = `${config.ENDPOINTS["main"]}/user/eula`

    const { data } = await axios
        .post(eulaUrl, postData, {
            headers: headerConfig
        })
        
    return data;
}

export default function useMutateEulaValues() {
    return useMutation(eulaSend, {
        onError: () => {
            //success handler
        }
    })
}