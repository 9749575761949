import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch,  } from 'react-redux';
import { USER_MESSAGE } from "../../../actions/User";
import moment from "moment";

const deleteExperiment = async (experimentId) => {
    const headerConfig = setHeaders();
    let listUrl = `${config.ENDPOINTS["main"]}/experiment/delete/${experimentId}`;

    const { data } = await axios
        .delete(listUrl, {
            headers: headerConfig
        })

    return data;
}

function useDeleteExperiment(experimentId) {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return useMutation(["DELETEEXPERIMENT", experimentId], deleteExperiment, {
        onSuccess: () => {
            dispatch({
                type: USER_MESSAGE,
                msgType: "success",
                text: "experiment.deleted",
                key: moment().unix()
            });
            queryClient.invalidateQueries('RECENTEXPERIMENTS')
        },
        onError: () => {
            dispatch({
                type: USER_MESSAGE,
                msgType: "error",
                text: "experiment.deleted",
                key: moment().unix()
            });        
        }
    })
}

export default useDeleteExperiment;
