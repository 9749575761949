import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { USER_MESSAGE } from "../../../actions/User";
import moment from "moment";
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';

const addTagByExperimentId = async (variables) => {
    const { experimentId, tagName } = variables;
    const postData = {
        experimentId: experimentId,
        tagName: tagName
    };
    const headerConfig = setHeaders();

    const { data } = await axios
    .post(`${config.ENDPOINTS["main"]}/experiment/tag`, postData, {
        headers: headerConfig
    })

    return data;
}

const useMutateExperimentTagAdd = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(addTagByExperimentId, {
        onSuccess: (variables) => {
            queryClient.invalidateQueries(["ExperimentDetail", variables.experimentId]);
            queryClient.invalidateQueries("tags");
            dispatch({
                type: USER_MESSAGE,
                msgType: "success",
                text: "experiment.tagAdded",
                key: moment().unix()
            });
        },
        onError: () => {
            dispatch({
                type: USER_MESSAGE,
                msgType: "error",
                text: "tag.409",
                key: moment().unix()
            });
        }
    })
}

export default useMutateExperimentTagAdd;
