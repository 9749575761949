import ExperimentIcon from "images/icons-table-atgc-experiment-2.svg";

export const initExperimentState = {
  loading: false,
  error: false,
  open: false,
  experimentId: "",
  openTag: false,
  tagName: "",
  selectedRow: 0,
  expId: undefined,
  image: ExperimentIcon,
};

export const initHomeState = {
  open: false,
  tagName: "",
  selectedRow: 0,
  expId: undefined,
  image: ExperimentIcon,
};
// For New User component initial state
export const initNewUserState = {
  open: false,
  disable: true,
  emailExists: '',
  usernameExists: '',
  accountExists: '',
};

// common reducer pattern that is not connected with redux
export const commonReducer = (draft, action) => {
  if (action.length === undefined) {
    draft[action.type] = action.payload;
  } else {
    action.forEach((act) => {
      draft[act.type] = act.payload;
    });
  }
};
