/* eslint-disable no-unused-vars */

import React from "react";
import { useImmerReducer } from "use-immer";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { FormInput } from "components/common/ReactHookForm/FormInput";
import { Input } from "components/common/ReactHookForm/Input";
import { SelectInput } from "components/common/ReactHookForm/SelectInput";
import { useFormContext, Controller, useForm } from "react-hook-form";
import { Box, Grid, Slider, FormControl, Tooltip } from "@mui/material";
import Info from "images/icons-table-info.svg";
import InfoActive from "images/icons-table-info-active.svg";
import "scss/App.scss";
import FormHelperText from "@mui/material/FormHelperText";
import { commonReducer } from "reducers/localReducers/commonReducer";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import * as actionsAdd from "actions/ExperimentAdd";

const useStyles = makeStyles({
  marginBottom: "10px",
  sliderInput: {
    marginTop: "18px !important",
  },
  header: {
    marginTop: "10px",
  },
  text: {
    textAlign: "right",
    minWidth: "75px",
    marginTop: "10px",
  },
});

export const NextFlowInputs = (props) => {
  const { component, option } = props;
  const experimentState = useExperimentsContext();
  const dispatchExperimentsEvent = useExperimentsDispatchContext();
  const { defaultAnalysis } = experimentState;

  const methods = useFormContext();
  const { control } = useForm();
  const styles = useStyles();
  const [state, dispatchEvent] = useImmerReducer(
    commonReducer,
    defaultAnalysis
  );

  const onRestoreDefault = (name) => {
    switch (name) {
      case "minMapqToCount": {
        dispatchEvent({ type: name, payload: 1 });
        dispatchExperimentsEvent({
          type: actionsAdd.SET_DISABLE,
          payload: false,
        });
        break;
      }
      case "minBp": {
        dispatchEvent({ type: name, payload: 15 });
        dispatchExperimentsEvent({
          type: actionsAdd.SET_DISABLE,
          payload: false,
        });
        break;
      }
      case "threePrimeQualCutoff": {
        dispatchEvent({ type: name, payload: 0 });
        dispatchExperimentsEvent({
          type: actionsAdd.SET_DISABLE,
          payload: false,
        });
        break;
      }
      case "fivePrimeQualCutoff": {
        dispatchEvent({ type: name, payload: 0 });
        dispatchExperimentsEvent({
          type: actionsAdd.SET_DISABLE,
          payload: false,
        });
        break;
      }
    }
  };

  const onInputChange = (event, max) => {
    let { name, value } = event.target;
    event.target.value > max ? (value = max) : event.target.value;

    event.target.value < 0 ? (value = 0) : event.target.value;

    dispatchEvent({ type: name, payload: value });
    updateDefaultAnalysisData(event.target.name, event.target.value);
  };

  const onInputDropdownChange = (event) => {
    updateDefaultAnalysisData(event.target.name, event.target.value);
  };

  const onInputRadioChange = (event) => {
    updateDefaultAnalysisData(event.target.name, event.target.value);
  };

  const onSliderChange = (value, name) => {
    dispatchEvent({ type: name, payload: value });
    updateDefaultAnalysisData(name, value);
  };

  const updateDefaultAnalysisData = (name, value) => {
    const clone = Object.assign({}, defaultAnalysis);
    clone[name] = value;
    dispatchExperimentsEvent({
      type: actionsAdd.SET_DEFAULT_ANALYSIS,
      payload: clone,
    });
    dispatchExperimentsEvent({
      type: actionsAdd.SET_DISABLE,
      payload: false,
    });
  };

  const infoButton = (source) => {
    return (
      <Tooltip placement="top" title={source}>
        <img
          className="inline-image"
          src={Info}
          alt="Info Button"
          onMouseOver={(e) => (e.target.src = InfoActive)}
          onMouseOut={(e) => (e.target.src = Info)}
        />
      </Tooltip>
    );
  };

  let checkedValue = false;

  // generalised way to check the radio button - instead of earlier hardcoded fields checks for radio buttons

  if (component === "Toggle") {
    const value = defaultAnalysis[option?.name];
    // Check if the value is a string representation of a boolean
    const isBoolean = value === "true" || value === "false";// if it's "Toggle" it should always be boolean - but still check(passed incorrectly  etc)
    checkedValue = isBoolean ? JSON.parse(value) : value; //
  }

  switch (component) {
    case "Slider":
      return (
        <Box className="settings-field">
          <Box classame={styles.marginBottom}>
            <p className="settings-field-header-radio">
              <label>
                <FormattedMessage id={option.label} />
                {infoButton(option.usage)}
              </label>
            </p>
          </Box>
          <FormControl
            className={styles.header}
            variant="outlined"
            fullWidth={true}
          >
            <Grid container>
              <Grid item xs={2} className={styles.sliderInput}>
                <Input
                  className={styles.text}
                  type={"number"}
                  label={option.text}
                  variant="outlined"
                  size="small"
                  value={state[option.name]}
                  name={option.name}
                  ref={methods.register}
                  onChange={(event) => {
                    onInputChange(event, option.choices[1]);
                  }}
                />
              </Grid>
              <Grid className="slider" item xs={10}>
                <Grid container>
                  <Grid item xs>
                    <Box className="settings-field-box">
                      <div className="settings-field-link">
                        <p style={{ cursor: "auto" }}>
                          <span
                            style={{ cursor: "pointer" }}
                            className="settings-field-header"
                            onClick={(event, value) => {
                              onRestoreDefault(option.name);
                            }}
                          >
                            Reset to Default
                          </span>
                        </p>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ cursor: "default" }}
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    <p>{option.choices[0]}</p>
                  </Grid>
                  <Grid item xs>
                    <Controller
                      control={control}
                      name={option.label}
                      defaultValue={option.default_value}
                      render={(onChange, onBlur, name, ref) => (
                        <Slider
                          name={option.name}
                          value={state[option.name]}
                          onChange={(event, value) => {
                            onSliderChange(value, option.name);
                          }}
                          valueLabelDisplay="auto"
                          max={option.choices[1]}
                          min={option.choices[0]}
                          step={1}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <p>{option.choices[1]}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      );
    case "Toggle":
      return (
        <div key={option.name} className="toggle-input">
          <p className="settings-field-header-radio">
            <label>
              <FormattedMessage id={option.label} />
              {infoButton(option.usage)}
            </label>
          </p>
          <div className="grid-x grid-margin-x">
            <div className="cell shrink">
              <FormInput
                name={option.name}
                register={methods.register}
                type="radio"
                value={"true"}
                onChange={onInputRadioChange}
                checked={checkedValue === true}
              />{" "}
              <FormattedMessage id="g.yes" />
            </div>
            <div className="cell shrink">
              <FormInput
                name={option.name}
                register={methods.register}
                type="radio"
                value={"false"}
                onChange={onInputRadioChange}
                checked={checkedValue === false}
              />{" "}
              <FormattedMessage id="g.no" />
            </div>
          </div>
        </div>
      );
    case "SelectField":
      return (
        <Box className="settings-field">
          <FormControl required variant="outlined" fullWidth={true}>
            <p className="settings-field-header-radio">
              <label>
                <FormattedMessage id={option.label} />
                {infoButton(option.usage)}
              </label>
            </p>
            <SelectInput
              name={option.name}
              register={methods.register}
              options={option && option.choices}
              value={defaultAnalysis[option.name]}
              style={{ marginBottom: "0px" }}
              onChange={
                option.dependency
                  ? (event) => {
                    onInputDropdownChange(event, option.choices[0]);
                  }
                  : onInputRadioChange
              }
            />
            <FormHelperText>{option.text}</FormHelperText>
          </FormControl>
        </Box>
      );
    case "TextBox":
      return (
        <Box className="settings-field">
          <FormControl required variant="outlined" fullWidth={true}>
            <p className="settings-field-header-radio">
              <label>
                <FormattedMessage id={option.label} />
                {infoButton(option.usage)}
              </label>
            </p>
            <Input
              name={option.name}
              ref={methods.register}
              size="small"
              style={{ marginTop: "0px", marginBottom: "0px" }}
              type={option?.type === "integer" ? "number" : "string"}
              defaultValue={
                ["reads", "RPKM", "TPM"].includes(defaultAnalysis.minGeneType)
                  ? defaultAnalysis.minGeneCutoff
                  : "0"
              }
              disabled={
                !["reads", "RPKM", "TPM"].includes(defaultAnalysis.minGeneType)
              }
              onChange={onInputRadioChange}
            />
            <FormHelperText>{option.text}</FormHelperText>
          </FormControl>
        </Box>
      );
    default:
      return null;
  }
};
