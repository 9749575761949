import React from "react";
import { Chip, Grid } from "@mui/material";
import * as actions from "actions/ExperimentAdd";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import CancelIcon from "images/icons-close-x-icon-black-small.svg";
import CancelIconHover from "images/icons-close-x-icon-black-small-hover.svg";
import { TagsModal } from "./TagsModal";

const AssociatedTags = () => {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const { allTags, newTags } = experimentState;
  const handleNewTagDelete = (e) => {
    const tempNewTags = [...newTags];
    const tempAllTags = [...allTags];
    const movingTag = tempNewTags.splice(e.target.name, 1);
    dispatchEvent({ type: actions.SET_NEW_TAGS, payload: tempNewTags });
    tempAllTags.push(movingTag[0]);
    dispatchEvent({ type: actions.SET_ALL_TAGS, payload: tempAllTags });
    dispatchEvent({ type: actions.SET_DISABLE, payload: false });
  };

  return (
    <>
      <Grid item xs={2}>
        <p className="tags-header">
          ADDED TAGS ({newTags.length})
          <a
            className="blue-link"
            onClick={() =>
              dispatchEvent({
                type: actions.SET_OPEN_ADDED_TAGS,
                payload: true,
              })
            }
          >
            View All
          </a>
        </p>
        {newTags?.slice(0, 5).map((tag, index) => (
          <Chip
            className="tag-chip"
            key={index}
            label={tag}
            onDelete={handleNewTagDelete}
            deleteIcon={
              <img
                data-testid={`${tag}-delete-icon`}
                src={CancelIcon}
                name={index}
                style={{ height: "12px", width: "12px" }}
                id={index}
                onMouseOver={(e) => {
                  e.target.src = CancelIconHover;
                }}
                onMouseOut={(e) => {
                  e.target.src = CancelIcon;
                }}
              />
            }
          />
        ))}
      </Grid>
      <TagsModal mode={"Added Tags"} action={handleNewTagDelete} />
    </>
  );
};

export default AssociatedTags;
