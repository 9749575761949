import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useQuery } from 'react-query';
//import { handleLogout } from "../../../actions/Okta";
//import { useDispatch } from 'react-redux';
import { useState } from "react";

const getExperimentDetailById = async (queryExperiment) => {
    const experimentId = queryExperiment.queryKey[1];
    const headerConfig = setHeaders();

    if (!experimentId) return;
    const { data } = await axios.get(`${config.ENDPOINTS["main"]}/experiment/details/${experimentId}`, {
        headers: headerConfig
    });
    return data;
};

export default function useFetchExperimentDetailById(experimentId, pollingStatus, status) {
    //const dispatch = useDispatch();
    const [runStatus, setStatus] = useState(status);

    return useQuery(["ExperimentDetail", experimentId], getExperimentDetailById, {
        // pollingStatus is set in multiple palces to avoid constant refetches. This could use some refator to access and set it here ( in upcoming versions)
        enabled: experimentId !== undefined,
        refetchInterval: pollingStatus === 0 ? false : pollingStatus === undefined ? false : pollingStatus === false ? false : pollingStatus === true ? 30000 : runStatus === "Not Started" && pollingStatus === "pollQuick" ? 10 : 30000,

        onSuccess: (data) => {
            setStatus(data?.data?.experimentMeta?.status);
        },

        // eslint-disable-next-line no-unused-vars
        onError: (err) => {
            // if (err && err.response && err.response.status === 401) {
            //   dispatch(handleLogout())
            // }
        }
    });
}
