import React from 'react';
import { useImmerReducer } from "use-immer";
import 'scss/App.scss';
import { Link } from 'react-router-dom';
import StylesProvider from '@mui/styles/StylesProvider';
import { Box, FormControl, Grid, Modal, Paper } from "@mui/material";
import { Input } from "components/common/ReactHookForm/Input";
import { Form } from "components/common/ReactHookForm/Form";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitButton } from "components/common/ReactHookForm/SubmitButton";
import * as yup from "yup";
import useMutateAddUser from 'components/Hooks/NewUser/useMutateAddUser';
import TopNav from 'components/TopNav';
import { commonReducer, initNewUserState } from "reducers/localReducers/commonReducer";


const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    accountNo: yup.string().required("Account Number is required"),
    email: yup.string().email("Email is not valid").required("Email is required"),
    userName: yup.string().required("Username is required")
});

const NewUser = () => {
    const [state, setState] = useImmerReducer(commonReducer, initNewUserState);
    const handleOpen = () => setState({ type: "open", payload: true });
    const handleClose = () => setState({ type: "open", payload: false });
    const resolver = yupResolver(schema);

    const { register, handleSubmit, reset, errors, formState } = useForm({
        criteriaMode: "all",
        mode: "onChange",
        resolver
    });

    const addUser = useMutateAddUser()

    const onSubmit = (data, e) => {
        setState([{ type: "emailExists", payload: '' }, { type: "usernameExists", payload: '' }, { type: "accountExists", payload: '' }]);
        e.preventDefault();
        addUser.mutate(data, {
            onSuccess: (message) => {
                if (message.data) {
                    reset()
                    handleOpen()
                }
            },
            onError: (error) => {
                error.response.data.existingFields.map(function (item) {
                    if (item === 'email') {
                        setState({ type: 'emailExists', payload: 'That Email is taken. Please try another' });
                    }
                    else if (item === 'username') {
                        setState({ type: 'usernameExists', payload: 'That Username is taken. Please try another' });
                    }
                    else if (item === 'accountno') {
                        setState({ type: 'accountExists', payload: 'Account Number is invalid' });
                    }
                })
            }
        });
    }

    return (
        <StylesProvider injectFirst>
            <div id="wrapper">
                <TopNav hideIcon="true" />
                <div className='backdrop'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container style={{ height: "fit-content" }}>
                            <Grid item xs={3} />
                            <Grid item xs={6}>
                                <Box style={{ width: "100%" }}>
                                    <h1 id="newuser-header">New User</h1>
                                    <p id="newuser-text">Please complete the form below to gain access to SeqSense.
                                        <br />You&#39;ll receive a confirmation  email once your SeqSense account has been activated.
                                        <br /><b>All fields are required.</b></p>
                                </Box>
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={3} />
                            <Grid item xs={3}>
                                <Box>
                                    <FormControl variant='outlined' fullWidth={true}>
                                        <Input
                                            ref={register}
                                            className="newuser-field"
                                            inputProps={{ tabIndex: "1" }}
                                            style={{ height: "85px" }}
                                            size='small'
                                            type="text"
                                            label='First Name'
                                            name="firstName"
                                            variant='outlined'
                                            error={!!errors.firstName}
                                            helperText={errors?.firstName && errors.firstName.message}
                                            onChange={(text) => setState({ type: "disable", payload: !text.target.value })}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl variant='outlined' fullWidth={true}>
                                        <Input
                                            ref={register}
                                            className="newuser-field"
                                            inputProps={{ tabIndex: "3" }}
                                            style={{ height: "85px" }}
                                            size='small'
                                            type="text"
                                            label='Bio-Rad Account Number'
                                            name="accountNo"
                                            variant='outlined'
                                            error={state.accountExists ? state.accountExists : !!errors.accountNo}
                                            helperText={state.accountExists ? state.accountExists : (errors && errors.accountNo ? errors.accountNo.message : '(e.g. 0004-089911)')}
                                            onChange={(text) => {
                                                setState([
                                                    { type: "disable", payload: !text.target.value },
                                                    { type: "accountExists", payload: '' },
                                                ]);
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl variant='outlined' fullWidth={true}>
                                        <Input
                                            ref={register}
                                            className="newuser-field"
                                            inputProps={{ tabIndex: "5" }}
                                            style={{ height: "85px" }}
                                            size='small'
                                            type="text"
                                            label='Username from Bio-rad.com account'
                                            name="userName"
                                            variant='outlined'
                                            error={state.usernameExists ? state.usernameExists : !!errors.userName}
                                            helperText={state.usernameExists ? state.usernameExists : (errors?.userName ? errors.userName.message : '(Enter username  created from Bio-rad.com account page)')}
                                            onChange={(text) => {
                                                setState([
                                                    { type: "disable", payload: !text.target.value },
                                                    { type: "usernameExists", payload: '' },
                                                ]);
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={3} style={{ marginLeft: "65px" }}>
                                <Box>
                                    <FormControl variant='outlined' fullWidth={true}>
                                        <Input
                                            ref={register}
                                            className="newuser-field"
                                            inputProps={{ tabIndex: "2" }}
                                            style={{ height: "85px" }}
                                            size='small'
                                            type="text"
                                            label='Last Name'
                                            name="lastName"
                                            variant='outlined'
                                            error={!!errors.lastName}
                                            helperText={errors?.lastName && errors.lastName.message}
                                            onChange={(text) => setState({ type: 'disable', payload: !text.target.value })}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl variant='outlined' fullWidth={true}>
                                        <Input
                                            ref={register}
                                            className="newuser-field"
                                            inputProps={{ tabIndex: "4" }}
                                            style={{ height: "85px" }}
                                            size='small'
                                            type="text"
                                            label='E-Mail Address'
                                            name="email"
                                            variant='outlined'
                                            error={state.emailExists ? state.emailExists : !!errors.email}
                                            helperText={state.emailExists ? state.emailExists : (errors && errors.email ? errors.email.message : '')}
                                            onChange={(text) => {
                                                setState([
                                                    { type: "disable", payload: !text.target.value },
                                                    { type: "emailExists", payload: '' },
                                                ]);
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl variant='outlined' fullWidth={true}>
                                        <SubmitButton id="newuser-button" color="primary" disabled={state.disable || !formState.isValid} name="submit">
                                            GET ACCESS
                                        </SubmitButton>
                                    </FormControl>
                                    <Modal open={state.open} style={{ display: 'flex', height: 400 }} >
                                        <Paper className="newuser-modal">
                                            <Box>
                                                <p id="newuser-modal-header">Success!</p>
                                            </Box>
                                            <Box id="newuser-text">
                                                <p>Thank you for signing up for Bio-Rad SeqSense.
                                                    <br /><br />You will receive an email once your account is activated.</p>
                                            </Box>
                                            <Box id="newuser-footer">
                                                <Link to="/login" className="blue-link medium-text" onClick={handleClose}>OK</Link>
                                            </Box>
                                        </Paper>
                                    </Modal>
                                </Box>
                            </Grid>
                            <Grid item xs={3} />
                        </Grid>
                    </Form>
                </div>
            </div>
        </StylesProvider>
    );
}

export default NewUser;
