export const COMPLETE_TEXT = 'The SEQuoia Complete Stranded RNA Library Prep Kit \
            is a high-performance stranded RNA-Seq kit \
            that captures long and short RNAs in a single library, \
            even from limited and low-quality samples. Featuring SEQzyme,\
            a proprietary engineered enzyme that couples cDNA synthesis with \
            adapter addition in a continuous synthesis reaction. \
            The unique enzymatic properties effectively captures all types \
            and sizes of RNA species in a novel enzymatic reaction, \
            significantly improving the diversity and quality of RNA libraries, \
            even from limited or degraded RNA samples.'

export const EXPRESS_TEXT = 'The SEQuoia Express Stranded RNA Library Prep Kit \
            is a high-performance stranded RNA-Seq kit that captures long RNA transcripts, \
            including mRNA and long non-coding transcripts that are >200b . \
            The kit includes SEQzyme, a proprietary engineered enzyme that \
            combines cDNA synthesis with adapter addition in a one-tube \
            continuous synthesis reaction. The streamlined 3-tube workflow \
            is automation friendly and enables high-throughput library \
            construction from high-quality samples in less than 3 hours.'

export const OMNITION_TEXT = `The ddSEQ Single-Cell 3' RNA-Seq Kit and Omnition Analysis Software, 
                              together, enables streamlined whole transcriptome analysis (WTA) at the single-cell level. 
                              Leveraging Bio-Rad's ddSEQ droplet and deconvolution oligo (DO) technology, this assay can capture 
                              and profile hundreds to thousands of single cells with high sensitivity and recovery.`

export const UMI_PROCESSING_INFO = 'Indicate that only R1 has been provided and no UMI processing is required.'

export const READ_TRIMMING_INFO = 'Indicates whether or not trimming skipped on the reads.'

export const MIN_MAPQ_INFO = 'The minimum MapQ score for an aligned read to count toward a feature count'

export const MIN_BASE_INFO = 'Reads with fewer base pairs will be rejected'

export const THREE_PRIME_INFO = "The read quality below which bases will be trimmed on the 3' end"

export const FIVE_PRIME_INFO = "The read quality below which bases will be trimmed on the 5' end"

export const EXPRESS_SEQ_TYPE_INFO = 'Provide sequencing method used'

export const EXPRESS_MIN_GENE_TYPE = 'Provide metric to be used for quantifying gene expression'

export const EXPRESS_MIN_GENE_CUT_OFF = 'Provide double value to cutoff how many reads are minimally required for a gene to be counted'