import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import { SET_EXISTING_DUPLICATE_ERROR_FILE_DIALOG } from "actions/ExperimentAdd";

export default function ExistingDialog() {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const {
    existingDialog: { open } //filenames },
  } = experimentState;

  const handleClose = () => {
    dispatchEvent({
      type: SET_EXISTING_DUPLICATE_ERROR_FILE_DIALOG,
      payload: { open: false, filenames: [] },
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Existing Sample Files Dialog"
        aria-describedby="Duplicate File Name"
      >
        <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Select one unique biosample file to continue
          </DialogContentText>
          {/* <div style={{ marginTop: 20, whiteSpace: "pre" }}>
              {filenames.join("\r\n")}
            </div> */}
        </DialogContent>
        <DialogActions>
          <Button data-testid="existingDialog-btn" onClick={handleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
