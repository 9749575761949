import React from "react";
import 'scss/App.scss';
import { Link, useLocation } from 'react-router-dom';
import "./Login.css";
import { Grid, AppBar, Toolbar } from "@mui/material";
import StylesProvider from '@mui/styles/StylesProvider';
import { useMsal, } from "@azure/msal-react";
import Toaster from "components/common/Toaster";
import TitleLogo from "components/TitleLogo";
import loginPage from "images/login_page.svg";

const useLoginQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Login = () => {
  const query = useLoginQuery();
  const { instance } = useMsal();
  const errorState = query.get("state");

  let errorMessage;
  if (errorState) {
    errorMessage = errorState.split("=")[1];
  }

  const handleLogin = async () => {
    try {
      await instance.loginRedirect({
        scopes: ["openid", "email", "profile"],
        prompt: "login",
        redirectStartPage: window.location.origin + '/home',
      })
    }
    catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <StylesProvider injectFirst>
      {errorMessage && (
        <Toaster
          text={errorMessage}
          autoHideDuration={9000}
          type="error"
          message="You are not a member of the required user group"
        />
      )}
      <AppBar position="sticky" elevation={1} className={'nav'} >
        <Toolbar>
          <Grid container alignItems={'center'} justifyContent={"space-between"}>
            <TitleLogo />
            <Grid item>
              <Link to="/#" className={'nav-text'} onClick={handleLogin} data-testid="login-button" style={{ textDecoration: 'none', marginRight: '6px' }}>
                Login
              </Link>
              <span style={{ color: '#00A650' }}> | </span>
              <Link to='/newuser' className={'nav-text'} style={{ textDecoration: 'none', marginLeft: '6px' }}>
                New User Form
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className="login-container">
        <div className="login-svg-container">
          <img src={loginPage} alt="Your SVG description" className="login-svg-image" />
          <div className="login-text-container">
            <h1 className="login-heading-large">SeqSense Analysis Solution</h1>
            <p className="login-text-bold-large">Make sense of your sequencing data</p>
            <p className="login-text-bold-medium">The SeqSense Web Application offers a user-friendly interface that brings transparency and efficiency to data analysis.</p>
          </div>
        </div>
        <br />
        <div className="login-bg-gray">
          <div className="login-bg-gray-text-container">
            <h1 className="login-heading-small">Bringing Cloud Benefits to Data Analysis</h1>
            <p className="login-text-bold-small">Bio-Rad offers a simple-to-use cloud NGS platform to assist researchers in answering the most complex biological questions. The pipeline tools are optimized for libraries containing NGS RNA reads, facilitating mapping, aligning, counting,
              and normalizing reads, ensuring efficient data analysis.</p>
            <ul className="login-list">
              <li>Easy-to-use interface specifically tailored for reagent kits from Bio-Rad. Inc.</li>
              <li>No need for complex command lines</li>
              <li>View, manage, and analyze your FASTQ sample files online</li>
              <li>Analyze RNA-seq data on the cloud, anytime, anywhere</li>
            </ul>
          </div>
        </div>
      </div>
    </StylesProvider>
  );
};

export default Login;

// <StylesProvider injectFirst>
    //   <div style={{ margin: '0 auto', maxWidth: '90%' }}>
    //     {errorMessage && (
    //       <Toaster
    //         text={errorMessage}
    //         autoHideDuration={9000}
    //         type="error"
    //         message="You are not a member of the required user group"
    //       />
    //     )}
    //     <AppBar position="sticky" elevation={1} className={'nav'} >
    //       <Toolbar>
    //         <Grid container alignItems={'center'} justifyContent={"space-between"}>
    //           <TitleLogo />
    //           <Grid item>
    //             <Link className={'nav-text'} onClick={handleLogin} data-testid="login-button" style={{ textDecoration: 'none', marginRight: '6px' }}>
    //               Login
    //             </Link>
    //             <span style={{ color: '#00A650' }}> | </span>
    //             <Link to='/newuser' className={'nav-text'} style={{ textDecoration: 'none', marginLeft: '6px' }}>
    //               New User Form
    //             </Link>
    //           </Grid>
    //         </Grid>
    //       </Toolbar>
    //     </AppBar>
    //     <div style={{ position: 'relative', minHeight: 'calc(70% - 80px)' }}>
    //       <img src={loginPage} alt="Login Page SVG" style={{ width: '100%', height: '100%' }} />
    //       <div style={{ position: 'absolute', top: '50%', left: '10%', transform: 'translateY(-50%)', textAlign: 'left', maxWidth: '40%' }}>
    //         <h1 style={{ fontSize: '100px', color: '#5699FF' }} className='headline'>SeqSense Analysis Solution</h1>
    //         <p style={{ fontSize: '30px', fontWeight: '700', color: '#58595B' }} className='subhead'>
    //           Make sense of your sequencing data
    //         </p>
    //         <p style={{ fontSize: '25px', fontWeight: '550', color: '#58595B', margin: '0 250px 16px 0' }} className='subtext'>
    //           The SeqSense Web Application offers a user-friendly interface that brings transparency to data analysis so you can
    //           connect data to insights.
    //         </p>
    //       </div>
    //     </div>
    //     <br />
    //     <div style={{ backgroundColor: 'rgb(241,241,241)', padding: '16px', height: '30%' }}>
    //       <h1 style={{ fontSize: '24px', color: '#5699FF', margin: '0 0 8px 150px' }} className='subhead'>Bringing Cloud Benefits to Data Analysis</h1>
    //       <p style={{ fontSize: '19px', fontWeight: '400', color: '#58595B', margin: '0 840px 16px 150px' }} className='subtext'>
    //         Bio-Rad offers a simple-to-use cloud NGS platform to assist researchers in answering the most complex biological questions. The pipeline tools are optimized for libraries containing NGS RNA reads, facilitating mapping, aligning, counting,
    //         and normalizing reads, ensuring efficient data analysis.
    //       </p>
    //       <p style={{ fontSize: '19px', fontWeight: '400', color: '#58595B', margin: '0 0 16px 148px' }} className='subtext'>
    //         <ul>
    //           <li>Easy-to-use interface specifically tailored for reagent kits from Bio-Rad. Inc.</li>
    //           <li>No need for complex command lines.</li>
    //           <li>View, manage, and analyze your FASTQ sample files online.</li>
    //           <li>Analyze RNA-seq data on the cloud, anytime, anywhere.</li>
    //         </ul>
    //       </p>
    //     </div>
    //   </div>
    // </StylesProvider >
