import _ from "underscore";
import * as complete from "src/pipelineOptions/complete/parameters.settings.json";
import * as express from "src/pipelineOptions/express/parameters.settings.json";
import * as omnition from "src/pipelineOptions/Omnition/parameters.settings.json";

export const pipelineConfigs = {
  complete: {
    options: _.where(complete.parameters, { group: "options" }),
    advanced: _.where(complete.parameters, { group: "advanced" })
  },
  express: {
    options: _.where(express.parameters, { group: "options" }),
    advanced: _.where(express.parameters, { group: "advanced" }),
    advancedSettings: _.where(express.parameters, {
      group: "advancedSettings"
    })
  },
  omnition: {
    options: _.where(omnition.parameters, { group: "options" })
  }
};
