import { TableCell, TableRow, LinearProgress, Box } from "@mui/material";
import { CustomCheckbox } from "components/common/ExistingTab/CustomCheckbox";
import ReplayIcon from "@mui/icons-material/Replay";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FileIcon from "images/icons-table-generic.svg";
import CancelIcon from "images/icons-close-x-icon-black-small.svg";
import React from "react";
import {
  formatBytes,
  formatDate,
  getComparator,
  stableSort,
  handleAddDeleteCheckbox,
} from "utils/basic";
import { useExperimentsContext } from "contexts/ExperimentContext";
import PropTypes from "prop-types";

export function ExistingTabBody({ data, removeFile, addFile }) {
  const experimentState = useExperimentsContext();
  const { order, orderBy, page, rowsPerPage, selected, uploadFiles, retry } =
    experimentState;
  
  const handleClick = (row) => {
    const selectedIndex = selected.indexOf(row);
    
    const newSelected = handleAddDeleteCheckbox(
      selectedIndex,
      newSelected,
      row,
      selected
    );

    const found = selected.find((file) => file.fileId === row.fileId);
    if (found) {
      removeFile(row);
    } else {
      addFile(row, newSelected);
    }
  };

  const selectedNames = selected.map((f) => f.fileId);
  const isSelected = (fileId) => selectedNames.indexOf(fileId) !== -1;
  const isRetry = (name) => retry.indexOf(name) !== -1;
  return stableSort(data?.data || [], getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, index) => {
      const { size, name, createdDt, fileId } = row;
      const isItemSelected = isSelected(fileId);
      const labelId = `enhanced-table-checkbox-${index}`;
      const showRetry = isRetry(name);
      const centered = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#d3302f",
        cursor: "pointer",
      };

      return (
        <TableRow
          className="file-list-item"
          style={{ backgroundColor: "#f6f6f6" }}
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={fileId}
          selected={isItemSelected}
        >
          <TableCell padding="checkbox">
            <CustomCheckbox
              checked={isItemSelected}
              onClick={() => handleClick(row)}
              inputProps={{
                "aria-labelledby": labelId,
                "data-testid": `${labelId}-checkbox`,
              }}
            />
          </TableCell>
          <TableCell component="th" id={labelId} scope="row" padding="none">
            <img title={fileId} className="link-image" alt="File Icon" src={FileIcon} />
            {name}
          </TableCell>         
          <TableCell>{formatBytes(size)}</TableCell>
          <TableCell>{formatDate(createdDt)}</TableCell>
          <TableCell>
            {isItemSelected && uploadFiles.includes(fileId) && (
              <span style={centered}>
                <CheckCircleOutlineIcon color="success" />
              </span>
            )}
            {showRetry && (
              <span
                style={centered}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleClick(row);
                }}
              >
                <b>Retry</b> <ReplayIcon color="error" />
              </span>
            )}
            {isItemSelected && !uploadFiles.includes(fileId) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "100%", mr: 1 }}>
                  <LinearProgress color="success" />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <div
                    style={{ width: "50%" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      removeFile(row);
                    }}
                  >
                    <img
                      className="link-image"
                      alt="Cancel Icon"
                      src={CancelIcon}
                    />
                  </div>
                </Box>
              </Box>
            )}
          </TableCell>
        </TableRow>
      );
    });
}

ExistingTabBody.propTypes = {
  data: PropTypes.object,
  removeFile: PropTypes.func.isRequired,
  addFile: PropTypes.func.isRequired,
};

ExistingTabBody.defaultProps = {
  data: {},
};
