import React, { createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";

import {
  experimentAddReducer,
  expermentAddInitialState,
} from "reducers/localReducers/experimentAddReducer";

const ExpContext = createContext(null);
const ExpDispatchContext = createContext(null);

export function ExperimentContext({ children }) {
  const [state, dispatch] = useImmerReducer(
    experimentAddReducer,
    expermentAddInitialState
  );
  return (
    <ExpContext.Provider value={state}>
      <ExpDispatchContext.Provider value={dispatch}>
        {children}
      </ExpDispatchContext.Provider>
    </ExpContext.Provider>
  );
}

export function useExperimentsContext() {
  return useContext(ExpContext);
}

export function useExperimentsDispatchContext() {
  return useContext(ExpDispatchContext);
}
