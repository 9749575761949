import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { USER_MESSAGE } from "../../../actions/User";
import moment from "moment";

const deleteTag = async (variables) => {
    const { id } = variables;
    const headerConfig = setHeaders();
    const { data } = await axios
        .delete(`${config.ENDPOINTS["main"]}/tag/${id}`, {
            headers: headerConfig
        })
    return data;
}

const useMutateTagDelete = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(deleteTag, {
        onSuccess: () => {
            queryClient.invalidateQueries("tags");
            dispatch({
                type: USER_MESSAGE,
                msgType: "success",
                text: "tag.deleted",
                key: moment().unix()
            });
            queryClient.invalidateQueries("tags");
        },

        onError: () => {
            dispatch({
                type: USER_MESSAGE,
                msgType: "error",
                text: "tag.deleted",
                key: moment().unix()
            });
        }
    })
}

export default useMutateTagDelete;
