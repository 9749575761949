import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useQuery } from 'react-query';
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "actions/Azure";


const eulaStatus = async () => {
    const headerConfig = setHeaders();
    let eulaUrl = `${config.ENDPOINTS["main"]}/user/eula`;

    const { data } = await axios
        .get(eulaUrl, {
            headers: headerConfig
        })

    return data;
}

export default function useFetchEulaStatus(isAuthenticated) {


    // eslint-disable-next-line no-unused-vars
    const { instance } = useMsal();
    const accessToken = getAccessToken();
    return useQuery(["EULA", isAuthenticated], eulaStatus, {
        enabled: Boolean(isAuthenticated),
        retry: false,
        onError: (err) => {
            if (err?.response && err.response.status === 401) {
                instance.logoutRedirect({
                    idTokenHint: accessToken
                });
                //console.log(err);
            }
        }
    })
}