import React from 'react';

export const SelectInput = ({ register, options, name, error, label, id, ...inputProps }) => {

    return <>
        <label htmlFor={id}>{label}</label>
        <select
            name={name}
            ref={register}
            id={id}
            {...inputProps}>

            {options.map(value => (
                <option key={value} value={value}>
                    {value}
                </option>
            ))}
        </select>
        { error && <div>{error.message}</div>}
    </>
}
