import { CANCEL_PIPELINE } from "../actions/Pipelines";
import { produce } from 'immer';

const initialState = {
  pipelineCancelled: false
};

const pipelines = produce((draft = initialState, action) => {

  const { type, payload } = action;
  switch (type) {

    case CANCEL_PIPELINE: {
      draft.pipelineCancelled = payload;
      return draft;
    }

    default: {
      return draft;
    }
  }
});

export default pipelines;
