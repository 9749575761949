export const SET_ADD_TAG_DISABLED = "SET_ADD_TAG_DISABLED";
export const SET_FILE_NAME_ERROR = "SET_FILE_NAME_ERROR";
export const SET_ALL_FILES = "SET_ALL_FILES";
export const SET_ALL_TAGS = "SET_ALL_TAGS";
export const SET_ARROW_IMAGE = "SET_ARROW_IMAGE";
export const SET_CANCEL_IMAGE = "SET_CANCEL_IMAGE";
export const SET_DISABLE = "SET_DISABLE";
export const SET_DISABLE_PIPELINE = "SET_DISABLE_PIPELINE";
export const SET_DISABLE_UPLOAD = "SET_DISABLE_UPLOAD";
export const SET_DUPLICATE_EXPERIMENT_NAME = "SET_DUPLICATE_EXPERIMENT_NAME";
export const SET_DUPLICATE_TAG = "SET_DUPLICATE_TAG";
export const SET_EXPERIMENT_TITLE = "SET_EXPERIMENT_TITLE";
export const SET_FORM_STEP = "SET_FORM_STEP";
export const SET_NEW_TAGS = "SET_NEW_TAGS";
export const SET_OPEN = "SET_OPEN";
export const SET_OPEN_ADDED_TAGS = "SET_OPEN_ADDED_TAGS";
export const SET_PIPELINE_ID = "SET_PIPELINE_ID";
export const SET_PIPELINE_KIT = "SET_PIPELINE_KIT";
export const SET_PIPELINE_KIT_IMAGE = "SET_PIPELINE_KIT_IMAGE";
export const SET_PIPELINE_KIT_TEXT = "SET_PIPELINE_KIT_TEXT";
export const SET_POLLING_STATUS = "SET_POLLING_STATUS";
export const SET_ADDED_TAGS_LENGTH = "SET_ADDED_TAGS_LENGTH";
export const SET_TAB_VALUE = "SET_TAB_VALUE";
export const SET_ADD_IMAGE = "SET_ADD_IMAGE";
export const SET_EXPERIMENT_ID = "SET_EXPERIMENT_ID";
export const SET_DEFAULT_ANALYSIS = "SET_DEFAULT_ANALYSIS";
export const SET_COLLAPSE_IMAGE = "SET_COLLAPSE_IMAGE";
export const SET_EXPAND_IMAGE = "SET_EXPAND_IMAGE";
export const SET_EXPAND = "SET_EXPAND";
export const SET_ORDER = "SET_ORDER";
export const SET_ORDER_BY = "SET_ORDER_BY";
export const SET_SELECTED = "SET_SELECTED";
export const SET_PAGE = "SET_PAGE";
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";
export const SET_UPLOADED_FILES = "SET_UPLOADED_FILES";
export const SET_SHOW_ERROR = "SET_SHOW_ERROR";
export const SET_CANCEL_EVENTS = "SET_CANCEL_EVENTS";
export const SET_FILTER_UPLOADED_FILES = "SET_FILTER_UPLOADED_FILES";
export const SET_FILTER_ALL_FILES = "SET_FILTER_ALL_FILES";
export const SET_FILTER_SELECTED = "SET_FILTER_SELECTED";
export const SET_RETRY = "SET_RETRY";
export const SET_FILTER_RETRY = "SET_FILTER_RETRY";
export const SET_ALL_FILES_PUSH = "SET_ALL_FILES_PUSH";
export const SET_FILE_EXISTS = "SET_FILE_EXISTS";
export const SET_EXISTING_DUPLICATE_ERROR_FILE_DIALOG = "SET_EXISTING_DUPLICATE_ERROR_FILE_DIALOG";
export const REPLACE_UPLOADED_FILES = "REPLACE_UPLOADED_FILES";
export const SET_FILTER_ALL_FILES_BY_NAME = "SET_FILTER_ALL_FILES_BY_NAME";
