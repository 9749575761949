// Thiis file to be deleted once alll testing is completed and use azure.js files

import { OktaAuth } from "@okta/okta-auth-js";
import axios from "axios";
import { createBrowserHistory } from "history";
import config from "../app.config";


export const getToken = () => {
  const tokenStorage = localStorage.getItem("okta-token-storage");
  const parsedJson = JSON.parse(tokenStorage) || false;
  if (!parsedJson || parsedJson === "") {
    return false;
  }
  return parsedJson;
};

export const getUserId = () => {
  const tokenStorage = localStorage.getItem("okta-token-storage");
  const parsedJson = JSON.parse(tokenStorage) || false;
  if (!parsedJson) {
    return false;
  }
  const userId =
    (parsedJson &&
      parsedJson.idToken &&
      parsedJson.idToken.claims &&
      parsedJson.idToken.claims.sub) ||
    false;
  return userId;
};

export const getUserEmail = json => {
  const userEmail =
    json && json.idToken && json.idToken.claims && json.idToken.claims.email;
  return userEmail;
};

export const getUserInfo = () => {
  return () => {
    const userToken = getToken();
    const userEmail = getUserEmail(userToken);
    const headerConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `SSWS ${config.api_token}`
      }
    };

    return axios
      .get(`${config.url}/api/v1/users/${userEmail}`, headerConfig)
      .then(resp => {
        const userId = resp && resp.data && resp.data.id;
        if (!userId) {
          const history = createBrowserHistory();
          history.push({
            pathname: "/login",
            state: {}
          });
        }
      })
      .catch(err => {
        const history = createBrowserHistory();
        history.push({
          pathname: "/login",
          state: { err }
        });
      });
  };
};

export const getAccessToken = () => {
  const tokenStorage = localStorage.getItem("okta-token-storage");
  const parsedJson = JSON.parse(tokenStorage) || false;
  if (!parsedJson) {
    //no token stored!
    return false;
  }
  const accessToken =
    (parsedJson &&
      parsedJson.accessToken &&
      parsedJson.accessToken.accessToken) ||
    false;
  return accessToken;
};

export const sessionRefresh = id => {
  return () => {
    const headerConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `SSWS ${config.api_token}`
      }
    };

    return axios.post(
      `${config.url}/api/v1/sessions/${id}/lifecycle/refresh`,
      headerConfig
    );
  };
};

export function fetchExtendSession() {
  return () => {
    this.oktaAuth = new OktaAuth({
      url: config.url,
      clientId: config.client_id,
      redirectUri: config.redirect_uri
    });
    const userToken = getToken();
    var tokenToRenew = {
      idToken: userToken.idToken.idToken,
      claims: userToken.idToken.claims,
      scopes: userToken.idToken.scopes,
      authorizeUrl: userToken.accessToken.authorizeUrl,
      issuer: userToken.idToken.issuer,
      clientId: userToken.idToken.clientId
    };
    this.oktaAuth.token.renew(tokenToRenew);
  };
}

export function handleLogout() {
  return () => {
    localStorage.removeItem("okta-token-storage");
    localStorage.removeItem("pipeLineData");
  };
}
