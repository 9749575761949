
import { produce } from 'immer';
const initialState = {
  error: null,
  success: ""
};

const profile = produce((draft = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "CHANGE_PASSWORD_SUCCESS": {
      draft.success = payload;
      draft.error = null;
      return draft;
    }

    case "CHANGE_PASSWORD_ERROR": {
      draft.error = payload;
      draft.success = null;
      return draft;
    }

    default:
      return draft;
  }
});


export default profile;
