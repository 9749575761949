import React, {useState} from 'react';
import {Box} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function DisplayFiles({label, files}) {
    const [hide, setHide] = useState(true);
    return (
        <Box className="sample-files">
            <Box className="sample-files-label">
                <a
                    className="blue-link"
                    onClick={() =>
                        setHide(!hide)
                    }
                >
                    {hide ? "View" : "Hide"} {label}
                    <ArrowForwardIosIcon
                        className={
                            hide
                                ? "inline-image"
                                : "inline-image  rotate-90deg"
                        }
                        fontSize="small"
                    />
                </a>
            </Box>
            <Box className={hide ? "hidden" : "show"}>
                {files.map(
                    (file, index) => (
                        <p className="experiment-file" key={index}>{file}</p>
                    )
                )}
            </Box>
        </Box>
    );
}

export default DisplayFiles;
