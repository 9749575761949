import React from "react";
import { Box, FormControl, Grid, IconButton } from "@mui/material";
import { Input } from "components/common/ReactHookForm/Input";
import { Form } from "components/common/ReactHookForm/Form";
import { InputLabel } from "@material-ui/core";
import * as actions from "actions/ExperimentAdd";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import Toaster from "components/common/Toaster.jsx";
import { MAXTAGLIMIT } from "components/common/Constants/appConstants";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  tagName: yup
    .string()
    .trim()
    .max(20, "The tag name cannot be more than 20 characters.")
    .required(),
});

const AddTag = () => {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const {
    addTagDisabled,
    allTags,
    newTags,
    addedTagsLength,
    duplicateTag,
    addImage,
  } = experimentState;

  const resolver = yupResolver(schema);

  const {
    handleSubmit,
    reset,
    register,
    errors,
    formState: { isDirty, isValid },
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    resolver,
  });
  const onSubmit = (data) => {
    const newTagsCompare = newTags.some((element) => {
      return element.toLowerCase() === data.tagName.toLowerCase();
    });
    const allTagsCompare = allTags.some((element) => {
      return element.toLowerCase() === data.tagName.toLowerCase();
    });

    if (newTags.length > MAXTAGLIMIT) {
      dispatchEvent({
        type: actions.SET_ADDED_TAGS_LENGTH,
        payload: newTags.length + addedTagsLength,
      });
      return;
    }
    if (newTagsCompare || allTagsCompare) {
      dispatchEvent({ type: actions.SET_DUPLICATE_TAG, payload: true });
    } else {
      dispatchEvent({
        type: actions.SET_ADD_IMAGE,
        payload: "AddIconDisabled",
      });
      dispatchEvent({ type: actions.SET_ADD_TAG_DISABLED, payload: true });
      const tempRows = [...newTags];
      tempRows.push(data.tagName);
      dispatchEvent({ type: actions.SET_NEW_TAGS, payload: tempRows });
    }
    dispatchEvent({ type: actions.SET_DISABLE, payload: false });
    reset();
  };

  const enableAddButton = (e) => {
    dispatchEvent({ type: actions.SET_DUPLICATE_TAG, payload: false });
    if (
      e.currentTarget.value.trim() !== "" &&
      e.currentTarget.value.length <= 20
    ) {
      dispatchEvent({ type: actions.SET_ADD_IMAGE, payload: "AddIcon" });
      dispatchEvent({ type: actions.SET_ADD_TAG_DISABLED, payload: false });
    } else {
      dispatchEvent({
        type: actions.SET_ADD_IMAGE,
        payload: "AddIconDisabled",
      });
      dispatchEvent({ type: actions.SET_ADD_TAG_DISABLED, payload: true });
    }
  };
  return (
    <>
      <Grid container item xs={2}>
        <Form data-testid="add-tag-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs>
              <Box className="tags-field" display="inline">
                <FormControl style={{ width: "90%" }}>
                  <InputLabel className="experiment-title">ADD TAG</InputLabel>
                  <Input
                    ref={register}
                    size="small"
                    variant="outlined"
                    name="tagName"
                    inputProps={{ "data-testid": "add-tag-input" }}
                    onChange={enableAddButton}
                    error={!!errors.tagName}
                    helperText={
                      errors?.tagName?.message
                        ? errors.tagName.message
                        : "Max 20 characters"
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box display="inline">
                <IconButton
                  style={{ padding: "0px", backgroundColor: "#fff" }}
                  disabled={!isDirty || !isValid || addTagDisabled}
                  type="submit"
                  size="large"
                >
                  <img
                    src={addImage}
                    className="mini-margin-top"
                    onMouseOver={() =>
                      dispatchEvent({
                        type: actions.SET_ADD_IMAGE,
                        payload:
                          !isDirty || !isValid || addTagDisabled
                            ? "AddIconDisabled"
                            : "AddIconHover",
                      })
                    }
                    onMouseOut={() =>
                      dispatchEvent({
                        type: actions.SET_ADD_IMAGE,
                        payload:
                          !isDirty || !isValid || addTagDisabled
                            ? "AddIconDisabled"
                            : "AddIcon",
                      })
                    }
                  />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Grid>
      {addedTagsLength > MAXTAGLIMIT && (
        <Toaster
          text={"tag.max"}
          autoHideDuration={4000}
          type={"error"}
          key={addedTagsLength}
        />
      )}
      {duplicateTag && (
        <Toaster
          text={"tag.409"}
          autoHideDuration={4000}
          type={"error"}
          key={addedTagsLength + 1}
        />
      )}
    </>
  );
};

export default AddTag;
