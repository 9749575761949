import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useQuery } from 'react-query';

const getPipelineDetailById = async (queryPipeline) => {
    const pipelineId = queryPipeline.queryKey[1];
    const headerConfig = setHeaders();

    const { data } = await axios.get(`${config.ENDPOINTS["main"]}/pipelines/${pipelineId}`, {
        headers: headerConfig
    });
    return data;
};

export default function useFetchPipelineDataById(pipelineId) {

    return useQuery(["PipelineDetail", pipelineId], getPipelineDetailById, {
        enabled : pipelineId !== undefined && pipelineId !== '',
        onSuccess: () => {
            //success handler
        },

        onError: () => {
            //error handler
        }
    });
}