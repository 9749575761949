import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation } from 'react-query';

const saveExperiment = async (values) => {
    let allFilesArray = []
    let tagsArray = []
    let fileList = []
    const copyFileStatusUrl = `${config.WS_FILE_COPY_STATUS_LINK}`

    values?.allFiles?.forEach((element) => {
        allFilesArray.push({fileName: element.name, fileSize: element.size})
        fileList.push(element.name)
    });

    values?.tags?.forEach((element) => {
        tagsArray.push({tagName: element})
    });

    const headerConfig = setHeaders();
    const postData = {
        experimentId: values.experimentId,
        experimentName: values.experimentTitle,
        pipelineId: values.pipelineId,
        fileData: allFilesArray,
        notes: values.notes,
        tags: tagsArray,
        fileList: fileList,
        headers: headerConfig.Authorization
    };
    let saveUrl = `${config.ENDPOINTS["main"]}/create-experiment`

    const { data } = await axios
        .post(saveUrl, postData, {
            headers: headerConfig
        })

    const socket = new WebSocket(copyFileStatusUrl);

    socket.addEventListener('open', function () {
        console.log("socket open")
        sendMessage({ action: "sendMessage", message: postData })
    });

  // Event listener for incoming messages
    socket.addEventListener('message', function (event) {
        // Display an alert with the received message
        if(event["data"]) {
            console.log('Message received: ' + event.data, typeof event.data);
            let fileName = "Finished " + postData["fileList"][0];
            if (JSON.parse(event.data).toString() === fileName) {
                console.log("done")
            }
        }
    });

    // Function to send a message to the WebSocket server
    function sendMessage(message) {
        if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        } else {
            console.error('WebSocket connection is not open.');
        }
    }

    return data;
}

export default function useMutateSaveExperiment() {
    return useMutation(["saveExp"],saveExperiment, {
        onError: () => {
            //error handler
        }
    })
}