import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation } from "react-query";

const copyExistingFilesFromAWS = async (values) => {
  const {
    experimentId,
    fileNameList,
    pipelineId,
    fileId,
    onSuccess,
    onError,
    addCancelProgressBarToken,
  } = values;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  addCancelProgressBarToken(source);

  const headerConfig = setHeaders();
  const postData = {
    experimentId: experimentId,
    fileList: fileNameList,
    pipelineId: pipelineId,
    fileId: fileId,
    headers: headerConfig.Authorization
  };
  const copyExistingFilesFromAWSUrl = `${config.ENDPOINTS["main"]}/files/copy`;
  const copyFileStatusUrl = `${config.WS_FILE_COPY_STATUS_LINK}`;



  await axios
    .post(copyExistingFilesFromAWSUrl, {}, {
      headers: headerConfig,
      cancelToken: source.token,
    })
    .then(data => {return {"messageId":data?.data?.MessageId}})
    .catch(onError);


  const socket = new WebSocket(copyFileStatusUrl);

  socket.addEventListener('open', function () {
    console.log("socket open");
    sendMessage({ action: "sendMessage", message: postData })
  });

  // Event listener for incoming messages
    socket.addEventListener('message', function (event) {
        // Display an alert with the received message
        if(event["data"]) {
            let fileName = "Finished " + postData["fileList"][0];
            if (JSON.parse(event.data).toString() === fileName) {
                console.log("done")
                onSuccess();
            }
        }
    });

    // Function to send a message to the WebSocket server
    function sendMessage(message) {
        if (socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(message));
        } else {
            console.error('WebSocket connection is not open.');
        }
    }
};

export default function useMutateCopyExistingFilesFromAWS() {
  return useMutation(copyExistingFilesFromAWS);
}
