import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Chip } from '@mui/material';
import useMutateExperimentTagAdd from "../Hooks/Tags/useMutateExperimentTagAdd";
import useMutateExperimentTagDelete from "../Hooks/Experiment/useMutateExperimentTagDelete";
import useMutateTagDelete from "../Hooks/Tags/useMutateTagDelete";
import useFetchTags from "../Hooks/Tags/useFetchTags";


const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    padding: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(1)
  }
});

const ChipsArray = (props) => {
  const setTags = useFetchTags();
  const tags = setTags?.data?.data;

  const {
    classes,
    experimentTags,
    variant,
    color,
    mode,
    availableTags
  } = props;

  const renderTags =
    mode === "experimentEdit" ? availableTags : experimentTags || tags;

  const tagExperimentAdd = useMutateExperimentTagAdd();
  const tagExperimentDelete = useMutateExperimentTagDelete();
  const tagDelete = useMutateTagDelete();

  const handleDelete = data => () => {
    if (data) {
      tagDelete.mutate(data);
    }
  };

  const handleExperimentDetailTagDelete = data => () => {
    const { experimentId } = props;
    const variables = {
      experimentId: experimentId,
      ...data
    };
    if (data) {
      tagExperimentDelete.mutate(variables);
    }
  };

  const handleExperimentDetailTagAdd = data => () => {
    const { experimentId } = props;

    const variables = {
      experimentId: experimentId,
      ...data
    };
    if (data) {
      tagExperimentAdd.mutate(variables);
    }
  };
  return (
    <>
      <div className={classes.root}>
        {renderTags?.length !== 0 && renderTags.map(data => {
          return (
            <Chip
              key={data?.id}
              label={data?.tagName}
              variant={variant}
              color={color}
              disabled={tagDelete.status === 'loading'}
              onClick={
                mode === "experimentEdit"
                  ? handleExperimentDetailTagAdd(data)

                  : undefined
              }
              onDelete={
                mode === "experimentIsUsing"
                  ? handleExperimentDetailTagDelete(data)
                  : mode === "deletable"
                    ? handleDelete(data)
                    : undefined
              }
              className={classes.chip}
            />

          );

        })}

      </div>
      <div>
      </div>

    </>
  );
}

ChipsArray.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChipsArray);