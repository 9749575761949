import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Form } from "../../common/ReactHookForm/Form";
import { SubmitButton } from "../../common/ReactHookForm/SubmitButton";
import { Paper, FormControl } from '@mui/material';
import Chips from "../../common/Chips.jsx";
import { FormattedMessage } from "react-intl";
import useFetchTags from "../../Hooks/Tags/useFetchTags";
import Toaster from "../../common/Toaster.jsx";
import useMutateTagAdd from "../../Hooks/Tags/useMutateTagAdd";
import { makeStyles } from "@material-ui/core/styles";
import uuid from "react-uuid";
import TopNav from "../../TopNav";
import StylesProvider from '@mui/styles/StylesProvider';
import SideNav from "../../SideNav";
import 'scss/App.scss';
import { Input } from "../../common/ReactHookForm/Input";
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { USER_MESSAGE } from "../../../actions/User";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    padding: theme.spacing(3, 2)
  },
  button: {
    margin: theme.spacing()
  }
}));

const schema = yup.object().shape({
  tagName: yup.string().max(20, "The tag name cannot be more than 20 characters.").required(),
});

const TagsList = () => {

  const { data, error, isError, isSuccess } = useFetchTags();

  const tagAddMutation = useMutateTagAdd();
  const resolver = yupResolver(schema);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const styles = useStyles();
  const { register, handleSubmit, formState: { isSubmitting, isDirty, isValid }, errors } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    resolver
  });

  const onSubmit = (data, e) => {
    tagAddMutation.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries("tags");
        dispatch({
          type: USER_MESSAGE,
          msgType: "success",
          text: "tag.added",
          key: Math.random()
        });
      },
      onError: () => {
        dispatch({
          type: USER_MESSAGE,
          msgType: "error",
          text: "tag.409",
          key: Math.random()
        });
      }
    });
    e.target['tagName'].value = "";
  }

  return ((isSuccess && data !== []) ?
    <StylesProvider injectFirst>
      <div style={{ overflow: 'hidden' }} id='wrapper'>
        <TopNav />
        <div className='backdrop'>
          <SideNav page="Tags" />
          <div id="TagList" >
            <h3>
              <FormattedMessage id="tag.title" />
            </h3>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <Paper className={styles.root}>
                <div className="grid-x grid-margin-x">
                  <div className="cell medium-3">
                    <FormControl variant='outlined' fullWidth={true}>
                      <div>Add Tag</div>
                      <Input
                        ref={register}
                        style={{ marginTop: '5px', marginBottom: '20px' }}
                        size='small'
                        variant='outlined'
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        name="tagName"
                        error={!!errors.tagName}
                        helperText={errors && errors.tagName && errors.tagName.message ? errors.tagName.message : 'Max 20 characters'}
                      />
                    </FormControl>
                    <SubmitButton color="primary" disabled={!isDirty || !isValid} name="submit">
                      {tagAddMutation.isLoading ? 'Saving...' : tagAddMutation.isError ? 'Error!' : tagAddMutation.isSuccess ? 'Saved!' : 'Save'}
                    </SubmitButton>
                  </div>

                  <div className="cell medium-9">
                    <Chips mode="deletable" />
                  </div>
                </div>
              </Paper>
            </Form>
          </div >
        </div>
      </div>
    </StylesProvider>
    : isError ? (<Toaster
      text={`${error}`}
      key={uuid()}
      autoHideDuration={1000}
      type="error"
    />) : isError ? "Error Fetching Tags" : ""
  );

}

export default TagsList;
