import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import * as userActions from "../../../actions/User";
import Toaster from "../Toaster.jsx";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { initialize, pageview } from "react-ga";
import "../../../scss/global.scss";
import { parsePageTitle } from "../../../utils/basic";
import useFetchEulaStatus from "../../Hooks/Login/useFetchEulaStatus";
import Experiments from "../../Experiment/List/Experiments.jsx";
import ExperimentDetail from "components/Experiment/Details";
import ProfilePage from "../../Profile/index.jsx";
import Home from "../../Home";
import ExperimentAdd from "../../Experiment/Add/index.jsx";
import SampleFiles from "../../SampleFiles/List/index.jsx";
import TagsList from "../../Tags/List";
import { ExperimentContext } from "contexts/ExperimentContext";
import EulaForm from "components/Eula";
import { getAccessToken } from "actions/Azure";

const drawerWidth = 180;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
  },
  appBar: {
    background: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  titleText: {
    display: "inline",
    color: "#00a650",
    fontFamily: "Rubik, sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "no-wrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    overflowX: "hidden",
  },
  primary: {},
  icon: {},
});

const initialState = {
  upgradeMessage: {},
  authenticated: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "MESSAGE":
      return {
        ...state,
        upgradeMessage: action.payload,
        authenticated: true,
      };
    case "AUTH_STATUS":
      return {
        ...state,
        upgradeMessage: {},
        authenticated: action.payload,
      };

    default:
      return state;
  }
};
const AppWrapper = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();

  const { instance } = useMsal();
  const accessToken = getAccessToken();

  const isAuthenticated = useIsAuthenticated();


  const handleLogoutRedirect = () => {
    instance.logoutRedirect({
      idTokenHint: accessToken
    });
  }

  const { data, error, isError } = useFetchEulaStatus(isAuthenticated);

  if (error !== null && isError && error?.status?.response === 401) {
    return dispatch(handleLogoutRedirect());
  }

  useEffect(() => {
    if (isAuthenticated) {
      dispatch({ type: "AUTH_STATUS", payload: true });
    }

    const newContentHandler = () => {
      const message = {
        msg: "New features available. Please refresh your browser.",
        key: Math.floor(Math.random() * 99999999999999999),
        type: "success",
        duration: 60000,
      };
      dispatch({ type: "MESSAGE", payload: message });
    };

    window.addEventListener("newContentAvailable", newContentHandler);

    //initialize("UA-145421575-1");
    //initialize("G-9ZV3580YQB");// GA QA tag for testing. Will need to swap out with prod g-Tag
    initialize("UA-226610040-1");//UA-GA Dev tag
    pageview(location.pathname + location.search);

    return () =>
      window.removeEventListener("newContentAvailable", newContentHandler);
  }, [location, isAuthenticated]);

  const NavAuth = ({ val }) => {
    switch (val) {
      case "/home":
        return <Home />;
      case "/create-experiment/pipeline-selection":
        return (
          <ExperimentContext>
            <ExperimentAdd />
          </ExperimentContext>
        );
      case "/create-experiment/file-selection":
        return (
          <ExperimentContext>
            <ExperimentAdd />
          </ExperimentContext>
        );
      case "/create-experiment/configure-pipeline":
        return (
          <ExperimentContext>
            <ExperimentAdd />
          </ExperimentContext>
        );
      case "/experiments":
        return <Experiments mode="experimentsMode" />;
      case "/samples":
        return <SampleFiles />;
      case "/profile":
        return <ProfilePage />;
      case "/tag/list":
        return <TagsList />;
      case "/":
        return data !== undefined && <Home />;
      // return <Home />;
      default: {
        if (val.includes("experiment-detail")) {
          return <ExperimentDetail experimentId={val.split("/")[2]} mode="edit-experiment" />;
        } else if (val.includes("create-experiment/pipeline-selection/")) {
          return (<ExperimentContext> <ExperimentAdd experimentId={val.split("/")[3]} mode="edit-experiment" formStep="0" /> </ExperimentContext>);
        } else if (val.includes("create-experiment/file-selection/")) {
          return (<ExperimentContext> <ExperimentAdd experimentId={val.split("/")[3]} mode="edit-experiment" formStep="1" /> </ExperimentContext>);
        } else if (val.includes("create-experiment/configure-pipeline/")) {
          return (<ExperimentContext> <ExperimentAdd experimentId={val.split("/")[3]} mode="edit-experiment" formStep="2" /> </ExperimentContext>);
        } else {
          return <Home />;
        }
      }
    }
  };
  const { classes, children, message, actions } = props;
  const pageTitle = parsePageTitle(location.pathname);
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        {isAuthenticated ? (
          isError ? (
            error && error.message && error.message.includes('404') ? <EulaForm /> : null
          ) : (
            data ? <NavAuth val={location.pathname} /> : null
          )
        ) : (
          <div>{children}</div>
        )}
      </main>
      {message?.key && (
        <Toaster
          text={message.text}
          autoHideDuration={message.duration || 3000}
          type={message.msgType}
          key={message.key}
          actions={actions}
        />
      )}
      {state.upgradeMessage?.key && (
        <Toaster
          text={state.upgradeMessage.text}
          autoHideDuration={state.upgradeMessage.duration}
          type={state.upgradeMessage.msgType}
          key={state.upgradeMessage.key}
        />
      )}

      <Helmet>
        <title>SeqSense Platform | {pageTitle}</title>
      </Helmet>
    </div>
  );
};

AppWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  login: state.login,
  message: state.user?.message || {},
  serviceWorker: state.serviceWorker,
});

const dispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    Object.assign({}, userActions),
    dispatch
  ),
});

export default compose(
  connect(stateToProps, dispatchToProps),
  withStyles(styles, { withTheme: true })
)(AppWrapper);






















