import {
  SET_EXPERIMENT_STATUS,
  SET_EXPERIMENT_ID,
  SET_FILES,
  SET_CLEAR_ADD_EXPERIMENT,
} from "../actions/Experiments";
import moment from "moment";
import { produce } from 'immer';


const initialState = {
  experimentId: false,
  files: {},
  experiment: false,
  workflow: false,
  workflowStartedDt: false
};


const experiments = produce((draft = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_EXPERIMENT_STATUS: {
      const timeStamp = moment().format("YYYY-MM-DDTHH:mm:ss");
      draft.workflow = payload;
      draft.workflowStartedDt = timeStamp;
      return draft;
    }
    case SET_EXPERIMENT_ID: {
      draft.experimentId = action.experimentId;
      return draft;
    }
    case SET_FILES: {
      draft.files = action.files;
      return draft;
    }

    case SET_CLEAR_ADD_EXPERIMENT: {
      draft.experimentId = false;
      draft.files = {};
      draft.experiment = false;
      draft.workflow = false;
      draft.workflowStartedDt = false;
      return draft;
    }

    default: {
      return draft;
    }
  }
});

export default experiments;
