import React, { useState, useEffect } from "react";
import { Box, Paper, LinearProgress } from "@mui/material";
import StylesProvider from '@mui/styles/StylesProvider';
import { FormattedMessage } from "react-intl";
import makeStyles from '@mui/styles/makeStyles';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import TopNav from "../TopNav";
import SideNav from "../SideNav";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2)
  }
}));

const ProfilePage = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [userInfo, setUserInfo] = useState(null);
  const styles = useStyles;

  useEffect(() => {
    if (isAuthenticated) {
      setUserInfo(getUserData());
    } else {
      setUserInfo(null);
    }
  }, [isAuthenticated]);

  function getUserData() {
    const account = instance.getActiveAccount();
    return account.idTokenClaims;
  }

  return (
    <StylesProvider injectFirst>
      <div id='wrapper'>
        <TopNav />
        <div className='home-backdrop'>
          <SideNav />
          <Box style={{ width: '100%', padding: '15px' }}>
            {!userInfo &&
              <Box sx={{ width: '100%' }}>
                <LinearProgress style={{ marginTop: "50px" }} color="success" />
              </Box>
            }
            {userInfo &&
              <section className="user-profile" data-sub={userInfo.sub}>
                <h3>
                  <FormattedMessage id="profile.title" />
                </h3>
                <Paper className={styles.root} style={{ padding: '15px' }}>
                  <div className="profile-div">
                    <label className="profile-label-spacing">
                      <FormattedMessage id="profile.name" />:
                    </label>
                    <span>{userInfo.given_name}</span>
                  </div>
                  <div className="profile-div">
                    <label className="profile-label-spacing">
                      <FormattedMessage id="profile.username" />:
                    </label>
                    <span>{userInfo.signinname}</span>
                  </div>
                  <div className="profile-div">
                    <label className="profile-label-spacing">
                      <FormattedMessage id="profile.email" />:
                    </label>
                    <span>{userInfo.otherMails[0]}</span>
                  </div>
                </Paper>
                <Box style={{ marginBottom: "10px", marginTop: "30px" }} className="login-link">
                  <a target="_blank" href="https://commerce.bio-rad.com/forgotpassword/do" className="blue-link">Forgot Password?</a>
                </Box>
              </section>
            }
          </Box>
        </div>
      </div>
    </StylesProvider>
  );

};


export default ProfilePage;
