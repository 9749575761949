import React, { useEffect } from "react";
import "scss/App.scss";
import StylesProvider from "@mui/styles/StylesProvider";
import {
  Tab,
  Tabs,
  Paper,
  Box,
  FormControl,
  Grid,
  Button,
} from "@mui/material";
import TopNav from "components/TopNav";
import SideNav from "components/SideNav";
import UploadTab from "components/UploadTab";
import { Input } from "components/common/ReactHookForm/Input";
import { InputLabel } from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useMutateSaveExperiment from "components/Hooks/Experiment/useMutateSaveExperiment";
import useFetchPipelineDataById from "components/Hooks/Pipelines/useFetchPipelineDataById";
import { useQueryClient, useIsMutating } from "react-query";
import useFetchRecentExperiments from "components/Hooks/Experiment/useFetchRecentExperiment";
import * as actions from "actions/ExperimentAdd";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import TagsComponent from "components/common/Tags/TagsComponent";
import ExistingTab from "components/common/ExistingTab/ExistingTab";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "underscore";
import { useFetchExistingSampleFilesOnDemand } from "components/Hooks/Pipelines/useFetchExistingSampleFilesByPipeline";


const schema = yup.object().shape({
  experimentName: yup
    .string()
    .trim()
    .min(5, "The experiment title must contain a minimum of 5 characters."),
  tagName: yup
    .string()
    .trim()
    .max(20, "The tag name cannot be more than 20 characters.")
    .required(),
});

const UploadExperimentFiles = ({ mode, filesS3 }) => {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const isMutatingSaveExp = useIsMutating(['saveExp']);

  const {
    allFiles,
    disable,
    disableUpload,
    duplicateExperimentName,
    experimentId,
    experimentTitle,
    newTags,
    pipelineId,
    pipelineKit,
    arrowImage,
    tabValue,
    selected,
    uploadFiles,
  } = experimentState;
  const resolver = yupResolver(schema);
  const pipelineDetails = useFetchPipelineDataById(pipelineId);
  const saveExperiment = useMutateSaveExperiment();
  const queryClient = useQueryClient();
  const experimentsListData = useFetchRecentExperiments();

  const fetchExistingSampleFilesParams = { pipelineId, experimentId };

  const { data: sampleFilesData, refetch } = useFetchExistingSampleFilesOnDemand(fetchExistingSampleFilesParams);

  const handleSaveChanges = () => {
    dispatchEvent({
      type: actions.SET_DUPLICATE_EXPERIMENT_NAME,
      payload: "",
    });
    const pipelineId = pipelineDetails?.data?.data[0]?.id;
    const tags = newTags;
    const data = { experimentId, experimentTitle, pipelineId, allFiles, tags };

    saveExperiment.mutate(data, {
      onSuccess: () => {
        refetch().then(() => {
          queryClient.invalidateQueries("fetchExistingSampleFilesOnDemand");
          queryClient.invalidateQueries("existingSampleFilesByPipeline");
          queryClient.invalidateQueries("ExperimentDetail");
        });
      },
      onError: (error) => {
        queryClient.invalidateQueries("RECENTEXPERIMENTS");
        if (error.message.data === "Experiment Name is already taken") {
          dispatchEvent({
            type: actions.SET_DUPLICATE_EXPERIMENT_NAME,
            payload: true,
          });
        }
      },
    });
    dispatchEvent({
      type: actions.SET_DISABLE,
      payload: true,
    });
  };

  useEffect(() => {
    if (sampleFilesData) {
      // here get the uploaded files data after refetching from existing sample files
      // this way you will get the file id on the new file and set the "selected" state to mark that file id
      // selected.

      // to retriev the fileId, expermentName is used for mapping frontend with backend data,
      // becuase the uploaded file name can be different than whats returned by the backend.
      const getUploadedFileData = sampleFilesData?.data?.filter((el) => {
        return experimentTitle === el.experimentName;
      });

      dispatchEvent({
        type: actions.SET_SELECTED,
        payload: getUploadedFileData.map((f) => {
          return {
            createdDt: f.createdDt,
            name: f.name,
            size: f.size,
            fileId: f.fileId
          };
        }),
      });
      dispatchEvent({
        type: actions.REPLACE_UPLOADED_FILES,
        payload: getUploadedFileData.map((f) => {
          return f.fileId;
        })
      });
      dispatchEvent({
        type: actions.SET_ALL_FILES,
        payload: getUploadedFileData.map((f) => {
          return {
            createdDt: f.createdDt,
            name: f.name,
            size: f.size,
            fileId: f.fileId
          };
        })
      });
    }
  }, [sampleFilesData]);


  const handleTabChange = (e, newValue) => {
    dispatchEvent({
      type: actions.SET_TAB_VALUE,
      payload: newValue,
    });
  };

  const { register, errors } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    resolver,
  });

  const duplicateExpNameCheck = (value) => {
    dispatchEvent({ type: actions.SET_DUPLICATE_EXPERIMENT_NAME, payload: "" });

    experimentsListData.isSuccess &&
      experimentsListData?.data?.data?.map((dupName) => {
        if (
          value === dupName.experimentName &&
          experimentId !== dupName.experimentId
        ) {
          dispatchEvent({
            type: actions.SET_DUPLICATE_EXPERIMENT_NAME,
            payload:
              "Experiment Name is already taken, please use a different Experiment Name",
          });
        }
      });
  };

  useEffect(() => {
    dispatchEvent({ type: actions.SET_DUPLICATE_EXPERIMENT_NAME, payload: "" });

    if (experimentsListData.isSuccess) {
      experimentsListData?.data?.data?.map((dupName) => {
        if (
          experimentTitle === dupName.experimentName &&
          experimentId !== dupName.experimentId
        ) {
          dispatchEvent({
            type: actions.SET_DUPLICATE_EXPERIMENT_NAME,
            payload:
              "Experiment Name is already taken, please use a different Experiment Name",
          });
        }
      });
    }
  }, [
    experimentTitle,
    experimentsListData.isSuccess,
    experimentsListData?.data?.data,
  ]);

  const uploadComplete =
    (!_.isEmpty(filesS3) &&
      _.every(filesS3, function (file) {
        return file.percent === 100;
      })) ||
    false;

  const copyComplete =
    selected.length > 0 && uploadFiles.length < selected.length;

  return (
    <StylesProvider injectFirst>
      <div id="wrapper" className="fullHeight">
        <TopNav />
        <div className="backdrop">
          <SideNav step="125vh" page="Experiment" />
          <div style={{ height: "calc(100vh + 150px)" }} className="content">
            <Box>
              <h1 className="medium-header">CREATE NEW EXPERIMENT</h1>
              <h1 className="sample-kit-header">/ {pipelineKit === 'Omnition' ? `ddSEQ 3' scRNA-seq Omnition` : pipelineKit}</h1>
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    style={{ marginLeft: "32px" }}
                    className="settings-field"
                  >
                    <FormControl variant="outlined" fullWidth={true}>
                      <InputLabel className="experiment-title">
                        EXPERIMENT TITLE
                      </InputLabel>
                      <Input
                        ref={register}
                        name="experimentName"
                        className="experiment-field"
                        inputProps={{ "data-testid": "content-input" }}
                        size="small"
                        placeholder={
                          experimentTitle === "" ? "My New Experiment" : ""
                        }
                        variant="outlined"
                        defaultValue={experimentTitle}
                        error={
                          duplicateExperimentName
                            ? duplicateExperimentName
                            : !!errors.experimentName
                        }
                        helperText={
                          duplicateExperimentName
                            ? duplicateExperimentName
                            : errors?.experimentName?.message
                        }
                        onChange={(text) => {
                          duplicateExpNameCheck(text.target.value);
                          dispatchEvent({
                            type: actions.SET_DISABLE,
                            payload:
                              !text.target.value ||
                              !!errors.experimentName ||
                              text.target.value === "",
                          });
                          dispatchEvent({
                            type: actions.SET_EXPERIMENT_TITLE,
                            payload: text.target.value,
                          });
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <TagsComponent mode={mode} />
              </Grid>

              <Box className="content" style={{ marginLeft: "40px" }}>
                <h3 style={{ marginBottom: "0px" }} className="sub-header">
                  ADD SAMPLE FILES
                </h3>
                <p className="text">
                  To add samples to your experiment, upload local files  on the <strong>UPLOAD</strong> tab or choose existing files on the <strong>EXISTING SAMPLE FILES</strong> tab. <em>If you are uploading multiple large files, save your progress frequently to prevent loss of data if the application times out.</em>
                </p>
              </Box>

              <Box style={{ margin: "0px 40px" }}>
                <Paper className="upload-tabs">
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    textColor={"primary"}
                    TabIndicatorProps={{ className: "green-background" }}
                  >
                    <Tab label="UPLOAD" value="upload" />
                    <Tab
                      label="Existing Sample Files"
                      value={"existingSampleFiles"}
                    />
                  </Tabs>
                </Paper>
                <UploadTab index={"upload"} />
                {tabValue === "existingSampleFiles" && <ExistingTab />}
              </Box>
            </Box>
            <Box className="footer">
              <Box className="footer-content">
                <Button
                  onClick={() => {
                    dispatchEvent({
                      type: actions.SET_FORM_STEP,
                      payload: (cur) => cur - 1,
                    });
                    dispatchEvent({
                      type: actions.SET_DISABLE,
                      payload: false,
                    });
                  }}
                  style={{
                    textTransform: "none",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  disabled={
                    duplicateExperimentName !== "" || !!errors.experimentName
                  }
                  className="blue-link"
                  onMouseOver={() => {
                    dispatchEvent({
                      type: actions.SET_ARROW_IMAGE,
                      payload: "LeftArrowHover",
                    });
                  }}
                  onMouseOut={() => {
                    dispatchEvent({
                      type: actions.SET_ARROW_IMAGE,
                      payload: "LeftArrow",
                    });
                  }}
                  href=""
                >
                  <img className="link-image" alt="Back" src={arrowImage} />
                  Back: Edit Title & Prep Kit Type
                </Button>
              </Box>
              <Box
                style={{ position: "absolute", right: "240px" }}
                className="footer-content"
              >
                <Button
                  onClick={handleSaveChanges}
                  className="blue-button"
                  disabled={
                    disable ||
                    (!uploadComplete && filesS3.length > 0) ||
                    copyComplete ||
                    duplicateExperimentName !== "" ||
                    !!errors.experimentName
                  }
                  style={{ marginRight: "32px" }}
                >
                  SAVE CHANGES
                </Button>
                <Button
                  onClick={() => {
                    dispatchEvent({
                      type: actions.SET_FORM_STEP,
                      payload: (cur) => cur + 1,
                    });
                    window.history.pushState(
                      "Seqsense-2.0",
                      "Experiment Details",
                      experimentId !== undefined ? `/create-experiment/configure-pipeline/${experimentId}` : `/create-experiment/configure-pipeline`
                    );
                  }}
                  disabled={
                    disableUpload ||
                    (!uploadComplete && filesS3.length > 0) ||
                    copyComplete ||
                    duplicateExperimentName !== "" ||
                    !!errors.experimentName || isMutatingSaveExp !== 0
                  }
                  className="blue-button"
                >
                  NEXT: SET PARAMETERS
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </StylesProvider>
  );
};

const stateToProps = (state) => ({
  filesS3: state.fileReducer.filesS3,
});

const mapDispatchToProps = () => ({});

export default compose(connect(stateToProps, mapDispatchToProps))(UploadExperimentFiles);
