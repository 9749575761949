import React from "react";
import { useForm } from "react-hook-form";
import { NextFlowInputs } from "components/common/NextFlowInputs.jsx";
import { Box, FormControl, Grid } from "@mui/material";
import StylesProvider from "@mui/styles/StylesProvider";
import "scss/App.scss";
import { InputLabel } from "@material-ui/core";
import { Input } from "components/common/ReactHookForm/Input";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TopNav from "components/TopNav";
import SideNav from "components/SideNav";
import useFetchRecentExperiments from "components/Hooks/Experiment/useFetchRecentExperiment";
import TagsComponent from "components/common/Tags/TagsComponent";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import * as actions from "actions/ExperimentAdd";
import useGetPipelineDetails from "components/Hooks/Experiment/useGetPipelineDetails";

const schema = yup.object().shape({
  experimentName: yup
    .string()
    .trim()
    .min(5, "The experiment title must contain a minimum of 5 characters."),
});

const TwoDForm = ({ mode }) => {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const {
    pipelineKit,
    experimentTitle,
    duplicateExperimentName,
    experimentId,
    collapseImage,
    expandImage,
    expand,
  } = experimentState;
  const { pipelineConfigs, nextFlowInputMap } = useGetPipelineDetails();
  const resolver = yupResolver(schema);

  const pipelineName =
    pipelineKit && pipelineKit.split(" ").pop().toLowerCase();
  const experimentsListData = useFetchRecentExperiments();

  const { register, errors } = useForm({
    criteriaMode: "all",
    mode: "onChange",
    resolver,
  });

  const collapseSettings = (e) => {
    e.preventDefault();
    dispatchEvent({ type: actions.SET_EXPAND, payload: false });
  };

  const expandSettings = (e) => {
    e.preventDefault();
    dispatchEvent({ type: actions.SET_EXPAND, payload: true });
  };

  const duplicateExpNameCheck = (value) => {
    dispatchEvent({ type: actions.SET_DUPLICATE_EXPERIMENT_NAME, payload: "" });

    experimentsListData.isSuccess &&
      experimentsListData?.data?.data?.map((dupName) => {
        if (
          value === dupName.experimentName &&
          experimentId !== dupName.experimentId
        ) {
          dispatchEvent({
            type: actions.SET_DUPLICATE_EXPERIMENT_NAME,
            payload:
              "Experiment Name is already taken, please use a different Experiment Name",
          });
        }
      });
  };

  const advancedLink = expand ? (
    <a
      className="advanced-link"
      href="#"
      onClick={collapseSettings}
      onMouseOver={() =>
        dispatchEvent({
          type: actions.SET_COLLAPSE_IMAGE,
          payload: "CollapseBlueHover",
        })
      }
      onMouseOut={() =>
        dispatchEvent({
          type: actions.SET_COLLAPSE_IMAGE,
          payload: "CollapseBlue",
        })
      }
    >
      Hide{" "}
      <img
        className="inline-image"
        alt="Collapse"
        tabIndex="7"
        src={collapseImage}
      />
    </a>
  ) : (
    <a
      className="advanced-link"
      href="#"
      onClick={expandSettings}
      onMouseOver={() =>
        dispatchEvent({
          type: actions.SET_EXPAND_IMAGE,
          payload: "ExpandBlueHover",
        })
      }
      onMouseOut={() =>
        dispatchEvent({
          type: actions.SET_EXPAND_IMAGE,
          payload: "ExpandBlue",
        })
      }
    >
      Show{" "}
      <img
        className="inline-image"
        alt="Expand"
        tabIndex="7"
        src={expandImage}
      />
    </a>
  );

  return (
    <StylesProvider injectFirst>
      <div style={{ overflow: "hidden" }} id="wrapper">
        <TopNav />
        <div className="backdrop">
          <SideNav
            step={pipelineKit === "SEQuoia Express" ? "185vh" : "150vh"}
            page="Experiment"
          />
          <div
            style={{
              height: pipelineKit === "SEQuoia Express" ? "185vh" : "150vh",
            }}
            className="content"
          >
            <Box>
              <h1 className="medium-header">PIPELINE PARAMETERS</h1>
              <h1 className="sample-kit-header">/ {pipelineKit === 'Omnition' ? `ddSEQ 3' scRNA-seq Omnition` : pipelineKit}</h1>
              <Grid container>
                <Grid item xs={4}>
                  <Box style={{ marginLeft: "32px" }}>
                    <FormControl variant="outlined" fullWidth={true}>
                      <InputLabel className="experiment-title">
                        EXPERIMENT TITLE
                      </InputLabel>
                      <Input
                        id="experimentName"
                        ref={register}
                        name="experimentName"
                        className="experiment-field"
                        size="small"
                        placeholder={
                          experimentTitle === "" ? "My New Experiment" : ""
                        }
                        variant="outlined"
                        defaultValue={experimentTitle}
                        error={
                          duplicateExperimentName
                            ? duplicateExperimentName
                            : !!errors.experimentName
                        }
                        helperText={
                          duplicateExperimentName
                            ? duplicateExperimentName
                            : errors &&
                            errors.experimentName &&
                            errors.experimentName.message
                        }
                        onChange={(text) => {
                          duplicateExpNameCheck(text.target.value);
                          dispatchEvent({
                            type: actions.SET_EXPERIMENT_TITLE,
                            payload: text.target.value,
                          });
                          dispatchEvent({
                            type: actions.SET_DISABLE,
                            payload:
                              !!errors.experimentName ||
                              !text.target.value ||
                              text.target.value === "",
                          });
                          dispatchEvent({
                            type: actions.SET_DISABLE_PIPELINE,
                            payload: !!errors.experimentName,
                          });
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <TagsComponent mode={mode} />
              </Grid>
            </Box>
            <Box className={expand ? "settings-expanded" : "settings"}>
              <h4 className="sub-header">REFERENCE GENOME</h4>
              <Grid container>
                <Grid container spacing={2}>
                  {pipelineConfigs[pipelineName].options?.map(
                    (option, index) => (
                      <Grid key={index} item xs={4}>
                        <NextFlowInputs
                          key={option.name}
                          option={option}
                          component={nextFlowInputMap[option.renderer]}
                        />
                      </Grid>
                    )
                  )}
                </Grid>

                {pipelineConfigs[pipelineName]?.advanced ?
                  <>
                    <Grid container item id="advanced-row">
                      <Grid item xs={4}>
                        <Box>
                          <p className="sub-header">SECONDARY ANALYSIS SETTINGS</p>
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Box className="advanced">{advancedLink}</Box>
                      </Grid>
                    </Grid>
                    <Grid
                      className={
                        expand ? "settings-field" : "settings-field hidden"
                      }
                      container
                      spacing={2}
                    >
                      {pipelineConfigs[pipelineName].advanced?.map(
                        (option, index) =>
                          option.renderer === "check-box" && (
                            <Grid key={index} item xs={3}>
                              <NextFlowInputs
                                key={option.name}
                                option={option}
                                component={nextFlowInputMap[option.renderer]}
                              />
                            </Grid>
                          )
                      )}
                    </Grid>
                    <Grid
                      style={{ paddingRight: "0px" }}
                      className={
                        expand ? "settings-field" : "settings-field hidden"
                      }
                      container
                      spacing={2}
                    >
                      {pipelineConfigs[pipelineName].advanced?.map(
                        (option, index) =>
                          option.renderer === "range" && (
                            <Grid key={index} item xs={6}>
                              <NextFlowInputs
                                key={option.name}
                                option={option}
                                component={nextFlowInputMap[option.renderer]}
                              />
                            </Grid>
                          )
                      )}
                    </Grid>
                  </> : null
                }
                {pipelineConfigs[pipelineName]?.advancedSettings && (
                  <Grid
                    style={{ paddingBottom: "0px" }}
                    className={
                      expand ? "settings-field" : "settings-field hidden"
                    }
                    container
                    item
                    id="advanced-row"
                  >
                    <Grid item xs={6}>
                      <Box>
                        <p className="sub-header">
                          SECONDARY ANALYSIS ADVANCED SETTINGS
                        </p>
                      </Box>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                )}
                <Grid
                  className={
                    expand ? "settings-field" : "settings-field hidden"
                  }
                  container
                  spacing={2}
                >
                  {pipelineConfigs[pipelineName].advancedSettings?.map(
                    (option, index) => (
                      <Grid key={index} item xs={4}>
                        <NextFlowInputs
                          key={option.name}
                          option={option}
                          component={nextFlowInputMap[option.renderer]}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </StylesProvider>
  );
};

export default TwoDForm;
