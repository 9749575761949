import * as config from "../app.config";
import { USER_MESSAGE } from "./User";
import { handleLogout } from "./Okta";
import { setHeaders } from "../utils/basic";
import axios from "axios";
import moment from "moment";
import * as complete from "../pipelineOptions/complete/parameters.settings.json";
import * as express from "../pipelineOptions/express/parameters.settings.json";
import * as omnition from "../pipelineOptions/Omnition/parameters.settings.json";
import * as utils from "../utils/basic";
export const SET_EXPERIMENT_ID = "SET_EXPERIMENT_ID";
export const SET_EXPERIMENT_STATUS = "SET_EXPERIMENT_STATUS";
export const SET_CLEAR_ADD_EXPERIMENT = "SET_CLEAR_ADD_EXPERIMENT";
export const SET_FILES = "SET_FILES";
export const SET_CLEAR_FILES = "SET_CLEAR_FILES";
export const FILE_UPLOAD_COMPLETED = "FILE_UPLOAD_COMPLETED";
export const FILES_S3 = "FILES_S3";
export const FILES_S3_SIGNED_UPLOADED = "FILES_S3_SIGNED_UPLOADED";
export const REMOVE_FILE_S3 = "REMOVE_FILE_S3";

export const START_FILE_UPLOAD = "START_FILE_UPLOAD";
export const UPDATE_UPLOAD_PROGRESS = "UPDATE_UPLOAD_PROGRESS";
export const FILE_UPLOAD_FAILED = "FILE_UPLOAD_FAILED";
export const MARK_FILE_SAVED = "MARK_FILE_SAVED";
export const RESET_FILE_UPLOAD_STATE = "RESET_FILE_UPLOAD_STATE";

export const UPDATE_UPLOAD_SPEED = "UPDATE_UPLOAD_SPEED";
export const REMOVE_FILE_UPLOAD = "REMOVE_FILE_UPLOAD";
export const SET_INITIAL_UPLOAD_DELAY_MESSAGE = "SET_INITIAL_UPLOAD_DELAY_MESSAGE";
export const SET_FILE_REUPLOAD = "SET_FILE_REUPLOAD";
export const MULTI_PART_UPLOAD_DATABANK_COMPLETED = "MULTI_PART_UPLOAD_DATABANK_COMPLETED";

function _handleErrors(err) {
  return dispatch => {
    const status = (err?.response?.status) || 401;
    switch (status) {
      case 401:
        dispatch({
          type: USER_MESSAGE,
          msgType: "error",
          text: "user.401",
          key: moment().unix()
        });
        dispatch(handleLogout());
        break;
      case 409:
        dispatch({
          type: USER_MESSAGE,
          msgType: "error",
          text: "experiment.409",
          key: moment().unix()
        });
        break;
      case 404:
        dispatch({
          type: USER_MESSAGE,
          msgType: "error",
          text: "experiment.404",
          key: moment().unix()
        });
        break;
      case 403:
        dispatch(handleLogout());
        break;
      default:
    }
  };
}

function _configurePostData(expTitle, values, experimentId, pipelineData) {
  let postData = {
    pipeline_arn: pipelineData?.pipelineArn ? pipelineData?.pipelineArn : pipelineData?.stateMachineArn,
    region: pipelineData?.awsRegion,
    input: {
      pipeline_name: pipelineData?.pipelineName,
      pipeline_version: pipelineData?.pipelineRev,
      experimentId: experimentId,
      experimentName: expTitle,
      pipelineId: pipelineData?.id,
      pipeline_configs: {
        global: {
          s3envfolder: config.S3ENVFOLDER,
          s3rootfolder: config.S3ROOTFOLDER,
          baseDataDir: `s3://${config.S3ROOTFOLDER}`,
          pipelineDir: `s3://${config.S3ROOTFOLDER}/${config.S3ENVFOLDER}/${experimentId}`, //no ending slash!
          refGenomeDir: `s3://${config.S3ROOTFOLDER}/ref_data`,
          userParams: {}
        }
      }
    }
  };

  if (values?.createCopy) {
    //copy experiments logic is here
    postData.input.pipeline_configs.global.pipelineDir =
      (values.copyexperimentId &&
        `s3://${config.S3ROOTFOLDER}/${config.S3ENVFOLDER}/${values.copyexperimentId}`) ||
      false;
    postData.input.pipeline_configs.global.sampleCellThreshold =
      values.sampleCellThreshold || false;
  }

  let currentPipeline = pipelineData?.pipelineName.split(" ").pop().toLowerCase();

  switch (currentPipeline) {

    case "express": {
      let filteredERCCConfig = express.parameters.filter(function (input) {
        return input.group !== "invisible";
      });

      filteredERCCConfig.forEach(input => {
        return (postData.input.pipeline_configs.global.userParams[
          input.name
        ] = values[input.name] || input.default_value);
      });
      break;
    }

    case "complete": {
      let filteredConfig = complete.parameters.filter(function (input) {
        return input.group !== "invisible";
      });

      filteredConfig.forEach(input => {
        return (postData.input.pipeline_configs.global.userParams[
          input.name
        ] = values[input.name] || input.default_value);
      });
      break;
    }

    case "omnition": {
      // currently omnition does not have any invisible fields - but this is for the future versions in case
      let filteredConfig = omnition.parameters.filter(function (input) {
        return input.group !== "invisible";
      });

      filteredConfig.forEach(input => {
        return (postData.input.pipeline_configs.global.userParams[
          input.name
        ] = values[input.name] || input.default_value);
      });
      break;
    }

    default:
  }

  return postData;


}


export function setexperimentId(id) {
  return dispatch => {
    dispatch({ type: SET_EXPERIMENT_ID, experimentId: id ? id : utils.guid() });
  };
}
// EDIT: Might need File type Validations and Null Validations
export function setFiles(files) {
  return dispatch => {
    dispatch({ type: SET_FILES, files });
  };
}

export function removeFilesS3(file) {
  return dispatch => {
    dispatch({ type: REMOVE_FILE_S3, file });
  };
}

export function executeWorkflow(expTitle, values, pipelineData, allFiles, newTags) {
  return (dispatch, getState) => {
    const {
      experiments: { experimentId }
    } = getState();
    // EDIT BUG: not working if experimentId is an blank space " "
    if (!experimentId || experimentId.trim(" ") === "") {
      return false;
    }

    let postData = _configurePostData(expTitle, values, experimentId, pipelineData);

    return axios
      .put(`${config.ENDPOINTS["main"]}/execute`, postData, {
        headers: setHeaders()
      })
      .then(resp => {
        dispatch({ type: SET_EXPERIMENT_STATUS, payload: resp.data });
        dispatch(addFileInfo(resp.data, expTitle, values, pipelineData, allFiles, newTags, true));
      })
      .catch(err => {
        dispatch(_handleErrors(err));
      });
  };
}


export function addFileInfo(workflowData, expTitle, values, pipelineData, allFiles, newTags, pipelineStarted) {
  return (dispatch, getState) => {

    const {
      experiments: { experimentId }
    } = getState();


    if (!experimentId || experimentId === "") {
      return false;
    }

    const headerConfig = setHeaders();

    let allFilesArray = []

    allFiles.forEach((element) => {
      allFilesArray.push({ fileName: element.name, fileSize: element.size })
    });

    let tagsArray = []

    newTags?.forEach((element) => {
      tagsArray.push({ tagName: element })
    });

    const postData = {
      experimentId: experimentId,
      experimentName: expTitle,
      pipelineId: pipelineData?.id.toString(),
      fileData: allFilesArray,
      tags: tagsArray,
      pipelineStarted: pipelineStarted
    };

    let experimentDate = values.experimentDateProcessed;
    values.experimentDateProcessed = moment(experimentDate).format("YYYY-MM-DDTHH:mm:ss");

    axios
      .post(`${config.ENDPOINTS["main"]}/create-experiment`, postData, {
        headers: headerConfig
      })
      .catch(err => {
        dispatch(_handleErrors(err));
      });
  };
}



export function clearAddExperiment() {
  return dispatch => {
    dispatch({ type: SET_CLEAR_ADD_EXPERIMENT });
  };
}

export function clearUploadFiles() {
  return dispatch => {
    dispatch({ type: SET_CLEAR_FILES });// this can be removed after testing - old code for file upload
    dispatch({ type: RESET_FILE_UPLOAD_STATE });
  }
}

export function fileUploadCompletedStatus(experimentId, isUploadComplete) {
  return dispatch => {
    dispatch({ type: FILE_UPLOAD_COMPLETED, experimentId, payload: isUploadComplete });
  };
}

export function filesS3(experimentId, files) {
  return dispatch => {
    dispatch({
      type: FILES_S3, experimentId,
      payload: files
    });
  }
}

export function filesS3SignedUploaded(experimentId, files, thisFileIndex, thisPartIndex, progressEvent) {
  return dispatch => {
    dispatch({
      type: FILES_S3_SIGNED_UPLOADED, experimentId, thisFileIndex, thisPartIndex, progressEvent,
      payload: files
    });
  }
}
