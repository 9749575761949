import React, { useState, useEffect } from "react";
import "scss/App.scss";
import { Box, Paper, Modal } from "@mui/material";
//import UploadFile from "components/common/UploadFile";
import FileUpload from "./common/UploadFile/FileUpload";
import { useExperimentsContext, useExperimentsDispatchContext } from "contexts/ExperimentContext"; import * as ExperimentAddActions from "actions/ExperimentAdd";

function UploadTab(props) {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const { tabValue, fileNameError } = experimentState;
  const { index } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatchEvent({
      type: ExperimentAddActions.SET_FILE_NAME_ERROR,
      payload: false,
    });
    setOpen(false);
  }

  useEffect(() => {
    if (fileNameError) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [fileNameError]);

  return (
    <div>
      {tabValue === index && (
        <Paper style={{ display: "flex" }}>
          <Box style={{ flex: "1", width: "50%", margin: "8px 16px" }}>
            <Box style={{ display: "inline-block", height: "55px" }}>
              <p className="inline-text">
                Click or drop FASTQ files on the group boxes to upload them to
                your experiment.
                <br />
                Be sure to use{" "}
                <a data-testid="modalOpen" onClick={handleOpen} className="blue-link">
                  standard file naming conventions
                </a>{" "}
                to keep R1 and R2 files as sample pairs.
              </p>
              <Modal open={open} onClose={handleClose}>
                <Paper className="edit-notes">
                  <Box className="edit-notes-header">
                    {fileNameError ? (
                      <p className="medium-text">
                        Error! File name is not valid. Please use the standard
                        naming convention
                      </p>
                    ) : (
                      <p className="medium-text">
                        Standard File Naming Convention for SeqSense
                      </p>
                    )}
                  </Box>
                  <Box>
                    <p>
                      Use the following file naming convention so that SeqSense
                      knows to pair R1 and R2 .gz files as one sample. The application will concatenate the uploaded files to generate reports upon completion of the analysis.
                    </p>
                    <Box id="file-extension">
                      <p>
                        <strong>SampleName_S1_L001_R1_001</strong>.fastq.gz{" "}
                        <br />
                        <strong>SampleName_S1_L001_R2_001</strong>.fastq.gz
                        <br /> OR <br />
                        <strong>SampleName_S1_L001_R1_001</strong>.fq.gz <br />
                        <strong>SampleName_S1_L001_R2_001</strong>.fq.gz
                      </p>
                    </Box>
                  </Box>
                  <Box className="edit-notes-footer">

                    <br />
                    <p>Note: The application allows both .gz and .fastq. However, if files containing both .gz and .fastq extensions are uploaded into the same experiment, then there may be issues when the output (report) files are generated.</p>
                    <a
                      className="blue-link right medium-text"
                      onClick={handleClose}
                    >
                      OK
                    </a>
                  </Box>
                </Paper>
              </Modal>
            </Box>
            {/* <UploadFile /> */}
            <FileUpload />
          </Box>
        </Paper>
      )}
    </div>
  );
}

export default UploadTab;
