import React, { forwardRef } from "react";
import TextField from "@material-ui/core/TextField";



export const Input = forwardRef((props, ref) => {
    return (
        <TextField
            variant="outlined"
            margin="normal"
            inputRef={ref}
            fullWidth
            {...props}
        />
    );
});

// solution to eslint rule - Prevent missing displayName in a React component definition (react/display-name)
Input.displayName = "Input";
