import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation } from 'react-query';

const deleteExistingFile = async(fileinfo) => {
    const filedata = fileinfo.split('**');
    const headerConfig = setHeaders();
    let deleteUrl = `${config.ENDPOINTS["main"]}/experiment/${filedata[0]}/file/${filedata[1]}`;

    const { data } = await axios
        .delete(deleteUrl,{
            headers: headerConfig
    })

    return data;
}

function useDeleteExistingFiles(experimentId) {

    return useMutation(["DELETEEXISTINGFILE", experimentId ], deleteExistingFile, {
        onSuccess: () => {
        },

        onError: () => {
            //error handlers
        }
    })
}

export default useDeleteExistingFiles;