import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useQuery, useIsMutating } from 'react-query';

const getExistingSampleFilesByPipeline = async (queryPipeline) => {   
    const pipelineId = queryPipeline.queryKey[1].pipelineId;
    const experimentId = queryPipeline.queryKey[1].experimentId;  
    const headerConfig = setHeaders();

    const { data } = await axios.get(`${config.ENDPOINTS["main"]}/files/pipeline/${pipelineId}/exp/${experimentId}`, {
        headers: headerConfig
    });
    return data;
};

export default function useFetchExistingSampleFilesByPipeline(pipelineId, experimentId) {   
    const isMutatingSaveExp = useIsMutating(['saveExp']); 
    return useQuery(["existingSampleFilesByPipeline", pipelineId , experimentId], getExistingSampleFilesByPipeline, {
        enabled: pipelineId !== undefined && pipelineId !== '' && isMutatingSaveExp == 0,
        onSuccess: () => {
            //success handler           
        },

        onError: () => {
            //error handler
        }
    });
}

// this function was added to make pipeline call after the experiment is saved successfully so that we get the fileIds.
// the enabled flag is intentionally set to false so that this call doesnt trigger on page load
export function useFetchExistingSampleFilesOnDemand(pipelineId , experimentId) {
    return useQuery(["fetchExistingSampleFilesOnDemand", pipelineId, experimentId], getExistingSampleFilesByPipeline, {
        enabled : false,
        onSuccess: () => {
            //success handler            
        },

        onError: () => {
            //error handler
        }
    });
}