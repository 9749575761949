import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { USER_MESSAGE } from "../../../actions/User";
import moment from "moment";


const registerUser = async (values) => {
    const postData = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        accountNo: values.accountNo,
        userName: values.userName
    };
    const headerConfig = setHeaders();
    let userRegisterUrl = `${config.ENDPOINTS["main"]}/user/registration`;

    const { data } = await axios
        .post(userRegisterUrl, postData, {
            headers: headerConfig
        })
        
    return data;
}

export default function useMutateAddUser() {
    const dispatch = useDispatch();
    return useMutation(registerUser, {
        onSuccess: (data) => {
            if (data.data) {
                dispatch({
                    type: USER_MESSAGE,
                    msgType: "success",
                    text: "user.registered",
                    key: moment().unix()
                });
            }
            else {
                dispatch({
                    type: USER_MESSAGE,
                    msgType: "error",
                    text: "User cannot be registered - " + data.meta.error,
                    key: moment().unix()
                });
            }
        },
        onError: (error) => {
            if(error.response.status !== 409) {
                dispatch({
                    type: USER_MESSAGE,
                    msgType: "error",
                    text: "Registration Failed - "+error.response.status,
                    key: moment().unix()
                });
            }
        }
    })
}