import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import * as actions from "actions/ExperimentAdd";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import useFetchTags from "components/Hooks/Tags/useFetchTags";
import useFetchExperimentDetailById from "components/Hooks/Experiment/useFetchExperimentDetailById";
import AddTag from "components/common/Tags/AddTag";
import AssociatedTags from "components/common/Tags/AssociatedTags";
import AvailableTags from "components/common/Tags/AvailableTags";

const TagsComponent = (props) => {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const { mode } = props;
  const { experimentId } = experimentState;
  const tagsData = useFetchTags();
  const experimentDetails =
    mode === "edit-experiment" && useFetchExperimentDetailById(experimentId);

  useEffect(() => {
    if (mode === "edit-experiment") {
      dispatchEvent({
        type: actions.SET_ALL_TAGS,
        payload:
          experimentDetails?.data?.data?.experimentMeta?.availableTags.map(
            (element) => element.tagName
          ),
      });
      dispatchEvent({
        type: actions.SET_NEW_TAGS,
        payload:
          experimentDetails?.data?.data?.experimentMeta?.associatedTags.map(
            (element) => element.tagName
          ),
      });
    } else if (tagsData.isSuccess)
      dispatchEvent({
        type: actions.SET_ALL_TAGS,
        payload: tagsData?.data?.data.map((element) => element.tagName),
      });
  }, [tagsData?.data?.data, mode]);

  return (
    <>
      <AddTag />
      <Grid item xs />
      <AssociatedTags />
      <Grid item xs />
      <AvailableTags />
    </>
  );
};

export default TagsComponent;