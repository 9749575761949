import React, { useEffect } from "react";
import { useImmerReducer } from "use-immer";
import "scss/App.scss";
import StylesProvider from "@mui/styles/StylesProvider";
import {
  Alert,
  IconButton,
  Collapse,
  Box,
  FormControl,
  Grid,
  Modal,
  Tab,
  Tabs,
  Paper,
  Chip,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TopNav from "../../TopNav";
import SideNav from "../../SideNav";
import CancelIcon from "../../../images/icons-close-x-icon-black-small.svg";
import CancelIconHover from "../../../images/icons-close-x-icon-black-small-hover.svg";
import AddIcon from "../../../images/add-circle-big.svg";
import AddIconHover from "../../../images/add-circle-big-hover.svg";
import LoopIcon from "@mui/icons-material/Loop";
import AlertIcon from "../../../images/alert-circle-red.svg";
import Info from "../../../images/icons-table-info.svg";
import InfoActive from "../../../images/icons-table-info-active.svg";
import HTMLReports from "../../HTMLReports";
import OtherFiles from "../../OtherFiles";
import { useForm } from "react-hook-form";
import { Input } from "../../common/ReactHookForm/Input";
import { InputLabel } from "@material-ui/core";
import useMutateSaveExperiment from "../../Hooks/Experiment/useMutateSaveExperiment";
import useFetchPipelineDataById from "../../Hooks/Pipelines/useFetchPipelineDataById";
import useFetchExperimentDetailById from "../../Hooks/Experiment/useFetchExperimentDetailById";
import useFetchExperimentReportsById from "../../Hooks/Experiment/useFetchExperimentReportsById";
import { Form } from "../../common/ReactHookForm/Form";
import { SubmitButton } from "../../common/ReactHookForm/SubmitButton";
import Toaster from "../../common/Toaster.jsx";
import { MAXTAGLIMIT } from "../../common/Constants/appConstants";
import DisplayFiles from "./DisplayFiles";

const reducer = (draft, action) => {
  if (action.length === undefined) {
    draft[action.type] = action.value;
  } else {
    action.forEach((act) => {
      draft[act.type] = act.value;
    });
  }
};

const initialState = {
  isError: false,
  disable: true,
  open: false,
  openError: false,
  allTags: [],
  newTags: [],
  openAlert: true,
  openAddedTags: false,
  openAvailableTags: false,
  value: "reports",
  notes: "",
  pollingStatus: true,
  files: false,
  expTitle: "",
  addedTagsMaxLimit: 0,
  details: [
    {
      header: "Status",
      description: "Pipeline Run In-Progress",
    },
    {
      header: "Created",
      description: "",
    },
    {
      header: "Pipeline",
      description: "",
    },
  ],
  inputFiles: [],
  outputFiles: []
};

function ExperimentDetail({ experimentId, status, pollingStatus }) {

  const experimentDetails = useFetchExperimentDetailById(experimentId, pollingStatus, status);

  const pipelineKit =
    experimentDetails?.data?.data?.experimentMeta.pipelineName;
  const experimentTitle =
    experimentDetails?.data?.data?.experimentMeta.experimentName;
  const pipelineId = experimentDetails?.data?.data?.experimentMeta.id;

  const [state, setState] = useImmerReducer(reducer, initialState);
  const pipelineDetails = useFetchPipelineDataById(pipelineId);
  const saveExperiment = useMutateSaveExperiment();
  const experimentReports = useFetchExperimentReportsById(
    experimentId,
    state.pollingStatus
  );

  useEffect(() => {
    if (experimentDetails?.isSuccess) setState({
      type: "allTags",
      value: experimentDetails?.data?.data?.experimentMeta?.availableTags.map(
        (element) => element.tagName
      ),
    });
    if (experimentDetails?.isSuccess) setState({
      type: "newTags",
      value:
        experimentDetails?.data?.data?.experimentMeta?.associatedTags.map(
          (element) => element.tagName
        ),
    });
    if (experimentDetails?.isSuccess) setState({
      type: "notes",
      value: experimentDetails?.data?.data?.experimentMeta?.notes,
    });
  }, [experimentDetails?.isSuccess, experimentDetails?.data?.data]);

  useEffect(() => {
    setState({ type: "expTitle", value: experimentTitle });
    const filesInfo =
      experimentDetails?.isSuccess &&
      experimentDetails?.data?.data?.experimentData?.fileData;

    if (experimentDetails?.isError) {
      setState([
        { type: "pollingStatus", value: false },
        { type: "openError", value: true },
      ]);
      return;
    }

    if (experimentDetails?.isSuccess && JSON.stringify(filesInfo) !== "{}") {
      setState({ type: "files", value: true });
      let baseFiles = experimentDetails?.data?.data?.experimentData?.fileData.map(
        function (d) {
          return d?.baseFiles.map(f => f)
        });
      let _inputFiles = baseFiles.flat(1);
      setState({ type: "inputFiles", value: _inputFiles });

      let _outputFiles = experimentDetails?.data?.data?.experimentData?.fileData.map(d => d?.fileName);
      setState({ type: "outputFiles", value: _outputFiles });
    }

    if (
      experimentDetails?.data?.data?.experimentMeta.status === "FAILED" ||
      experimentDetails?.data?.data?.experimentMeta.status === "SUCCEEDED"
    ) {
      setState({ type: "pollingStatus", value: false });
    }

    if (experimentDetails?.isSuccess) {
      setState({
        type: "details",
        value: [
          {
            header: "Status",
            description:
              experimentDetails?.data?.data?.experimentMeta?.status ===
                "SUCCEEDED"
                ? "COMPLETED"
                : experimentDetails?.data?.data?.experimentMeta?.status,
          },
          {
            header: "Created",
            description:
              experimentDetails?.data?.data?.experimentMeta?.createdAt,
          },
          {
            header: "Pipeline",
            description: pipelineKit === 'Omnition' ? `ddSEQ 3' scRNA-seq Omnition` : pipelineKit,
          },
        ],
      });
    }
  }, [
    state.pollingStatus,
    experimentDetails?.data,
    state.expTitle,
    experimentTitle,
  ]);

  const handleTagDelete = (e) => {
    setState({ type: "disable", value: false });
    let tempRows = [...state.newTags];
    let tempAll = [...state.allTags];
    let movingTag = tempRows.splice(e.target.name, 1);
    setState({ type: "newTags", value: tempRows });

    tempAll.push(movingTag[0]);
    setState({ type: "allTags", value: tempAll });
  };

  const addExperimentTag = (e) => {
    if (state.newTags.length > MAXTAGLIMIT) {
      setState([
        {
          type: "addedTagsMaxLimit",
          value: state.newTags.length + state.addedTagsMaxLimit,
        },
        { type: "disable", value: true },
      ]);
      return;
    }
    setState({ type: "disable", value: false });
    let tempRows = [...state.allTags];
    let tempNew = [...state.newTags];
    let movingTag = tempRows.splice(e.target.name, 1);
    setState({ type: "allTags", value: tempRows });

    tempNew.push(movingTag[0]);
    setState({ type: "newTags", value: tempNew });
  };

  const handleTabChange = (e, newValue) => {
    setState({ type: "value", value: newValue });
  };

  let column2;
  if (state.pollingStatus) {
    column2 = (
      <Grid container item xs={6}>
        <Box id="pipeline-progress-box">
          <p>
            <LoopIcon
              style={{ marginRight: "15px" }}
              className="inline-image loop"
            />
            Pipeline Run In-Progress.
          </p>
        </Box>
        <Box style={{ marginTop: "150px" }} className="progress-label">
          <CircularProgress color="success" />
        </Box>
        <Box style={{ height: "60%" }}></Box>
      </Grid>
    );
  } else if (
    !state.pollingStatus &&
    experimentDetails?.data?.data?.experimentMeta.status !== "FAILED"
  ) {
    column2 = (
      <Grid className="files-and-reports" container item xs={6}>
        <Grid item xs={10}>
          <Paper style={{ width: "320px", height: "100%", boxShadow: "none" }}>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={state.value}
                onChange={handleTabChange}
                textColor={"primary"}
                TabIndicatorProps={{ className: "green-background" }}
              >
                <Tab icon={
                  <Tooltip
                    placement="top"
                    title="Find a collection of your reports here"
                  >
                    <img
                      style={{ marginLeft: "5px" }}
                      src={Info}
                      onMouseOver={(e) => (e.currentTarget.src = InfoActive)}
                      onMouseOut={(e) => (e.currentTarget.src = Info)}
                    />
                  </Tooltip>
                }
                  iconPosition="end"
                  label="Reports"
                  value="reports" />
                <Tab icon={
                  <Tooltip placement="top" title="All Other Files are here">
                    <img
                      style={{ marginLeft: "5px" }}
                      src={Info}
                      onMouseOver={(e) => (e.currentTarget.src = InfoActive)}
                      onMouseOut={(e) => (e.currentTarget.src = Info)}
                    />
                  </Tooltip>
                }
                  iconPosition="end"
                  label="Other Files"
                  value="other" />
              </Tabs>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={10}>
          <Paper style={{ height: "100%", boxShadow: "none" }}>
            {JSON.stringify(experimentReports?.data?.data) !== "{}" ? (
              <div>
                <HTMLReports
                  value={state.value}
                  values={experimentReports?.data?.data?.report}
                  index="reports"
                  isLoading={experimentReports?.isLoading}
                />
                <OtherFiles value={state.value}
                  values={experimentReports?.data?.data}
                  index="other" />
              </div>
            ) : (
              <div
                style={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  backgroundColor: "#fafafa",
                }}
              >
                No Reports and Files are generated.
              </div>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }

  const { handleSubmit, register, errors } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });

  const onSubmit = (data, e) => {
    const pipelineId = pipelineDetails?.data?.data[0]?.id;
    const tags = state.newTags;
    const { notes } = data;
    const saveData = { experimentId, experimentTitle, pipelineId, notes, tags };
    saveExperiment.mutate(saveData);
    setState({ type: "disable", value: true });
  };

  return (
    <StylesProvider injectFirst>
      <div>
        <TopNav />
        <div className="backdrop">
          <SideNav step={window.innerHeight + "vh"} page="Experiment" />
          <Form onSubmit={handleSubmit(onSubmit)} className="content">
            <Box className="experiment-details-content">
              <h1 className="medium-header">EXPERIMENT DETAIL</h1>
              <h1 className="sample-kit-header">/ {pipelineKit === 'Omnition' ? `ddSEQ 3' scRNA-seq Omnition` : pipelineKit}</h1>
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    style={{ marginLeft: "10px" }}
                    className="settings-field"
                  >
                    <FormControl variant="outlined" fullWidth={true}>
                      <InputLabel className="experiment-title">
                        EXPERIMENT TITLE
                      </InputLabel>
                      <Input
                        ref={register}
                        name="experimentName"
                        className="experiment-field"
                        size="small"
                        placeholder={
                          experimentTitle === "" ? "My New Experiment" : ""
                        }
                        variant="outlined"
                        disabled={true}
                        value={experimentTitle || state.expTitle}
                        defaultValue={experimentTitle || state.expTitle}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={5}>
                  {!state.pollingStatus && (
                    <Box sx={{ width: "100%" }}>
                      <Collapse in={state.openAlert}>
                        <Alert
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() =>
                                setState({
                                  type: "openAlert",
                                  value: !state.openAlert,
                                })
                              }
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          sx={{ mb: 2 }}
                        >
                          Pipeline Run Complete.
                        </Alert>
                      </Collapse>
                    </Box>
                  )}
                </Grid>
                <Grid item xs />
              </Grid>
              <Grid container item>
                <Grid container item xs={6}>
                  <Paper
                    style={{
                      height: "0",
                      width: "100%",
                      background: "none",
                      boxShadow: "none",
                    }}
                  >
                    {state.details.map((detail, index) => (
                      <Grid container key={index} item xs={12}>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              float: "right",
                              paddingRight: "16px",
                              color: "#a9a9a9",
                              fontFamily: "Rubik",
                            }}
                          >
                            <p>{detail.header}</p>
                          </Box>
                        </Grid>
                        <Grid item xs={8}>
                          <Box sx={{ fontFamily: "Rubik" }}>
                            <p>{detail.description}</p>
                          </Box>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid container item xs={12}>
                      <Grid item xs={3} />
                      <Grid item xs={8}>
                        {state?.outputFiles.length > 0 ?
                          (<DisplayFiles files={state.outputFiles} label="Output File(s)" />) : null
                        }
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} />
                      <Grid item xs={8}>
                        {state?.inputFiles.length > 0 ?
                          (<DisplayFiles files={state.inputFiles} label="Input File(s)" />) : null
                        }
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3}>
                        <Box
                          sx={{
                            float: "right",
                            paddingRight: "16px",
                            color: "#a9a9a9",
                            fontFamily: "Rubik",
                          }}
                        >
                          <p>Associated Tags({state.newTags.length})</p>
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Box sx={{ fontFamily: "Rubik" }}>
                          {experimentDetails?.isSuccess &&
                            state.newTags.slice(0, 5).map((tag, index) => (
                              <Chip
                                className="tag-chip"
                                key={index}
                                label={tag}
                                onDelete={handleTagDelete}
                                deleteIcon={
                                  <img
                                    src={CancelIcon}
                                    name={index}
                                    style={{ height: "12px", width: "12px" }}
                                    onMouseOver={(e) => {
                                      e.target.src = CancelIconHover;
                                    }}
                                    onMouseOut={(e) => {
                                      e.target.src = CancelIcon;
                                    }}
                                  />
                                }
                              />
                            ))}
                          <p>
                            <a
                              className="blue-link"
                              onClick={() =>
                                setState({
                                  type: "openAssociatedTags",
                                  value: true,
                                })
                              }
                            >
                              View All
                            </a>
                          </p>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3}>
                        <Box
                          sx={{
                            float: "right",
                            paddingRight: "16px",
                            color: "#a9a9a9",
                            fontFamily: "Rubik",
                          }}
                        >
                          <p>Available Tags({state.allTags.length})</p>
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Box sx={{ fontFamily: "Rubik" }}>
                          {experimentDetails?.isSuccess &&
                            state.allTags
                              .slice(0, 5)
                              .map((tag, index) => (
                                <Chip
                                  className="tag-chip"
                                  key={index}
                                  label={tag}
                                  onDelete={addExperimentTag}
                                  deleteIcon={
                                    <img
                                      src={AddIcon}
                                      name={index}
                                      onMouseOver={(e) =>
                                        (e.target.src = AddIconHover)
                                      }
                                      onMouseOut={(e) =>
                                        (e.target.src = AddIcon)
                                      }
                                    />
                                  }
                                />
                              ))}
                          <p>
                            <a
                              className="blue-link"
                              onClick={() =>
                                setState({
                                  type: "openAvailableTags",
                                  value: true,
                                })
                              }
                            >
                              View All
                            </a>
                          </p>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={3} />
                      <Grid item xs={8}>
                        <Box>
                          <FormControl variant="outlined" fullWidth={true}>
                            <Input ref={register}
                              id="experiment-notes"
                              name="notes"
                              multiline
                              rows={10}
                              maxRows={10}
                              margin="normal"
                              size="small"
                              label="Experiment Notes"
                              placeholder="Typing a note here would look like this."
                              value={state.notes}
                              variant="standard"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                disableUnderline: true,
                                style: { border: "1px solid lightgray" },
                              }}
                              onChange={(text) => {
                                setState([
                                  {
                                    type: "disable",
                                    value: !text.target.value,
                                  },
                                  { type: "notes", value: text.target.value },
                                ]);
                              }} />
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} />
                  </Paper>
                </Grid>
                {column2}
              </Grid>
            </Box>
            <Box className="footer">
              <Box
                style={{ position: "absolute", right: "240px" }}
                className="footer-content"
              >
                <SubmitButton
                  name="submit"
                  color="primary"
                  disabled={state.disable}
                >
                  SAVE CHANGES
                </SubmitButton>
              </Box>
            </Box>
            <Modal
              open={state.openError || false}
              onClose={() => setState({ type: "openError", value: false })}
              style={{ display: "flex" }}
            >
              <Paper id="experiment-error-modal">
                <Box id="delete-header-box">
                  <img className="inline-image" src={AlertIcon} />
                  <p className="delete-header">Error. Pipeline Timeout.</p>
                </Box>
                <Box sx={{ marginBottom: "30px" }}>
                  <p>
                    Something went wrong. The pipeline has timed out.
                    <br />
                    <br />
                    Please re-open this experiment and try again.
                  </p>
                </Box>
                <Box id="addtag-footer">
                  <a
                    href="/experiments"
                    className="blue-link medium-text"
                    onClick={() =>
                      setState({ type: "openError", value: false })
                    }
                  >
                    OK
                  </a>
                </Box>
              </Paper>
            </Modal>
            <Modal
              open={state.openAvailableTags || false}
              onClose={() =>
                setState({ type: "openAvailableTags", value: false })
              }
              style={{ display: "flex" }}
            >
              <Paper className="all-tags-modal">
                <Box>
                  <p className="eula-header">Available Tags</p>
                </Box>
                <Box className="top-border small-padding-top">
                  <Paper elevation={0} className="tags-box">
                    {experimentDetails?.isSuccess &&
                      state.allTags.map((tag, index) => (
                        <Chip
                          className="tag-chip"
                          key={index}
                          label={tag}
                          onDelete={addExperimentTag}
                          deleteIcon={
                            <img
                              src={AddIcon}
                              name={index}
                              onMouseOver={(e) => (e.target.src = AddIconHover)}
                              onMouseOut={(e) => (e.target.src = AddIcon)}
                            />
                          }
                        />
                      ))}
                  </Paper>
                </Box>
                <Box id="addtag-footer">
                  <a
                    className="blue-link medium-text"
                    onClick={() =>
                      setState({ type: "openAvailableTags", value: false })
                    }
                  >
                    OK
                  </a>
                </Box>
              </Paper>
            </Modal>
            <Modal
              open={state.openAssociatedTags || false}
              onClose={() =>
                setState({ type: "openAssociatedTags", value: false })
              }
              style={{ display: "flex" }}
            >
              <Paper className="all-tags-modal">
                <Box>
                  <p className="eula-header">Assosciated Tags</p>
                </Box>
                <Box className="top-border small-padding-top">
                  <Paper elevation={0} className="tags-box">
                    {experimentDetails?.isSuccess &&
                      state.newTags.map((tag, index) => (
                        <Chip
                          className="tag-chip"
                          key={index}
                          label={tag}
                          onDelete={handleTagDelete}
                          deleteIcon={
                            <img
                              src={CancelIcon}
                              name={index}
                              style={{ height: "12px", width: "12px" }}
                              onMouseOver={(e) => {
                                e.target.src = CancelIconHover;
                              }}
                              onMouseOut={(e) => {
                                e.target.src = CancelIcon;
                              }}
                            />
                          }
                        />
                      ))}
                  </Paper>
                </Box>
                <Box id="addtag-footer">
                  <a
                    className="blue-link medium-text"
                    onClick={() =>
                      setState({ type: "openAssociatedTags", value: false })
                    }
                  >
                    OK
                  </a>
                </Box>
              </Paper>
            </Modal>
            {state.addedTagsMaxLimit > MAXTAGLIMIT && (
              <Toaster
                text={"tag.max"}
                autoHideDuration={4000}
                type={"error"}
                key={state.addedTagsMaxLimit}
              />
            )}
          </Form>
        </div>
      </div>
    </StylesProvider>
  );
}

export default ExperimentDetail;
