import * as actions from "actions/ExperimentAdd";
import { useExperimentsDispatchContext } from "contexts/ExperimentContext";
import { useEffect } from "react";
//import uuid from "react-uuid";
import _ from "underscore";

export const useExperimentFileData = (experimentData) => {
  const dispatch = useExperimentsDispatchContext();

  useEffect(() => {
    if (experimentData?.data?.data?.experimentData?.fileData) {
      const { fileData } = experimentData.data.data.experimentData;
      if (!_.isEmpty(fileData)) {
        fileData.forEach((i) => {
          dispatch({
            type: actions.SET_UPLOADED_FILES,
            payload: i.userExpFileId,
          });

          dispatch({
            type: actions.SET_ALL_FILES_PUSH,
            payload: {
              fileId: i.userExpFileId,
              size: Math.trunc(i.fileSize),
              name: i.fileName,
              timeLeft: "",
              percent: 100,
              speed: "999",
              startedAt: new Date(i.createdDt).getTime() / 1000,
              parts: [
                {
                  partName: i.fileName,
                  selectedloaded: Math.trunc(i.fileSize),
                },
              ],
            },
          });
        });

        dispatch({
          type: actions.SET_SELECTED,
          payload: fileData.map((f) => {
            return {
              createdDt: f.createdDt,
              name: f.fileName,
              size: f.fileSize,
              fileId: f.userExpFileId
            };
          }),
        });

        dispatch({ type: actions.SET_TAB_VALUE, payload: "existingSampleFiles" });
      }
    }
  }, [experimentData?.data?.data?.experimentData]);
};
