import React from "react";
import Experiments from "components/Experiment/List/Experiments.jsx";
import WelcomeScreen from "./WelcomeScreen";
import LoadingScreen from "./LoadingScreen";
import useFetchRecentExperiments from '../Hooks/Experiment/useFetchRecentExperiment';
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "actions/Azure";


const Home = () => {

  const experimentList = useFetchRecentExperiments();
  const { instance } = useMsal();
  if (experimentList?.isSuccess && (experimentList?.data && !experimentList?.data.data.length)) return (<WelcomeScreen />)
  const accessToken = getAccessToken();


  return (
    <>
      {experimentList?.isSuccess ?
        (<Experiments mode="homeMode" />)
        : experimentList?.isFetching ? (
          <LoadingScreen />
        ) : instance.logoutRedirect({
          idTokenHint: accessToken
        })
      }
    </>
  );
}

export default Home;
