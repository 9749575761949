import { Checkbox } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(() => ({
  root: {
    "&$checked": {
      color: "#707070",
    },
  },
  checked: {},
}));

export const CustomCheckbox = ({ ...props }) => {
  const styles = useStyles();

  return <Checkbox classes={styles} {...props} />;
};
