import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useExperimentsContext, useExperimentsDispatchContext } from "contexts/ExperimentContext";
import * as actions from "actions/ExperimentAdd";

export function  EnhancedTableHead({ headerCells, classes }) {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const { order, orderBy } = experimentState;

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatchEvent({ type: actions.SET_ORDER, payload: isAsc ? "desc" : "asc" });
    dispatchEvent({ type: actions.SET_ORDER_BY, payload: property });
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headerCells.map((headCell) => {
          const { id, disable, label } = headCell;
          const hideSortIcon = disable ? true : false;
          const onClick = disable ? null : createSortHandler(id);
          const style =
            orderBy === id ? { color: "#003156" } : { color: "#6d7687" };
          const sortDirection = orderBy === id ? order : false;
          const direction = orderBy === id ? order : "asc";
          const ariaLabel =
            order === "desc" ? "sorted descending" : "sorted ascending";
          return (
            <TableCell key={id} padding="normal" sortDirection={sortDirection}>
              <TableSortLabel
                active={orderBy === id}
                hideSortIcon={hideSortIcon}
                style={style}
                direction={direction}
                onClick={onClick}
                data-testid={`sort-${id}`}
              >
                {label}
                {orderBy === id ? (
                  <span className={classes.visuallyHidden}>{ariaLabel}</span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  headerCells: PropTypes.array.isRequired,
};
