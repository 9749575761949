import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useMutation, useQueryClient } from 'react-query';

const addTag = async (variables) => {
    const { tagName } = variables;
    const postData = {
        name: tagName.toLowerCase()
    };
    const headerConfig = setHeaders();
    const { data } = await axios
        .post(`${config.ENDPOINTS["main"]}/tag`, postData, {
            headers: headerConfig
        })

    return data;
}

const useMutateTagAdd = () => {
    const queryClient = useQueryClient();
    return useMutation(addTag, {
        onSuccess: (variables) => {
            queryClient.invalidateQueries("tags");
            queryClient.invalidateQueries(["ExperimentDetail", variables.experimentId]);
        }

    });

}

export default useMutateTagAdd;
