/* eslint-disable no-unused-vars */
import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { getAccessToken } from "actions/Azure";
import { useQuery } from 'react-query';
import { useMsal } from "@azure/msal-react";

const getTags = async () => {

    const headerConfig = setHeaders();
    const { data } = await axios
        .get(`${config.ENDPOINTS["main"]}/tag/list`, {
            headers: headerConfig
        });

    return data;
};

export default function useFetchTags() {
    const { instance } = useMsal();
    const accessToken = getAccessToken();

    return useQuery("tags", getTags, {
        onError: (err) => {
            if (err?.response && err.response.status === 401) {
                instance.logoutRedirect({
                    idTokenHint: accessToken
                });
            }
        }
    });
}
