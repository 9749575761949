// import axios from "axios";
// import { createBrowserHistory } from "history";
// import config from "../app.config";
// eslint-disable-next-line no-unused-vars
import {MSAL_CONFIG} from "../app.config";

export const clientId = MSAL_CONFIG.auth.clientId;
export const policy = MSAL_CONFIG.system.misc.policy;
export const tenantId = MSAL_CONFIG.system.misc.tenantId;


export const getActiveAccount = () => {
    const activeAccount = localStorage.getItem(`msal.${clientId}.active-account`);
    return activeAccount;
}

export const getAllAzureKeys = () => {
    const keys = localStorage.getItem(`msal.token.keys.${clientId}`);
    return keys;
}

export const getUserEmail = () => {
    const claimsKey = `${getActiveAccount()}-${MSAL_CONFIG.system.misc.policy}.${MSAL_CONFIG.system.misc.tenantId}-${MSAL_CONFIG.system.misc.domainName}-`;
    const claimsValue = JSON.parse(localStorage.getItem(claimsKey));
    const userEmail =
        claimsValue?.idTokenClaims?.otherMails[0];
    return userEmail;
};

export const getAccessToken = () => {

    const allTokenKeys = localStorage.getItem(`msal.token.keys.${clientId}`);
    const idTokenKey = JSON.parse(allTokenKeys);
    const idTokenValue = idTokenKey?.idToken[0];
    const token = localStorage.getItem(idTokenValue);
    const parsedData = JSON.parse(token);
    return parsedData?.secret;
//    console.log({tokenData});

   // or throw new Error('Secret not found');
};

// export const getAccessTokens = () => {
//     const clientId = msalConfig.auth.clientId;
//     for (let i = 0; i < localStorage.length; i++) {
//         const key = localStorage.key(i);
//         const value = JSON.parse(localStorage.getItem(key));

//         if (value.homeAccountId === clientId && value.credentialType === 'IdToken') {
//             console.log(value.secret); // logs the value of the "secret" key
//             break; // stop searching after the first match is found
//         }
//     }

//     const tokenStorage = localStorage.getItem("okta-token-storage");
//     const parsedJson = JSON.parse(tokenStorage) || false;
//     if (!parsedJson) {
//         //no token stored!
//         return false;
//     }
//     const accessToken =
//         (parsedJson &&
//             parsedJson.accessToken &&
//             parsedJson.accessToken.accessToken) ||
//         false;
//     return accessToken;
// };