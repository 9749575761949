import { produce } from 'immer';

const initialState = {
  sessionToken: false,
  error: false
};

const login = produce((draft = initialState, action) => {
  const { type, payload } = action;
  switch (type) {

    case "LOGOUT": {
      draft.sessionToken = null;
      draft.error = null;
      return draft;
    }

    case "LOGIN_ERROR": {
      draft.sessionToken = null;
      draft.error = payload;
      return draft;
    }

    case "LOGIN_SUCCESS": {
      draft.sessionToken = payload;
      draft.error = null;
      return draft;
    }

    default:
      return draft;
  }

});

export default login;
