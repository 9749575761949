import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import experiments from "./Experiments";
import fileReducer from "./Files";
import form from "./Form";

import login from "./Login";
import profile from "./Profile";
import user from "./User";
import pipelines from "./Pipelines";
import serviceWorker from "./serviceWorker";

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    login,
    profile,
    experiments,
    fileReducer,
    form,
    user,
    pipelines,
    serviceWorker
  });

export default rootReducer;