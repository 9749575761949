import React from 'react';
import 'scss/App.scss';
import { Box, Paper, CircularProgress } from '@mui/material';


function HTMLReports({ value, index, values, isLoading }) {

    return (
        <div>
            {isLoading ? (
                <Box className="progress-label">
                    <CircularProgress color="success" />
                </Box>
            ):
                (value === index && (
                    values?.length > 0 ? (
                    <Paper style={{ display: "flex", height: '100%' }}>
                        <Box id="html-report">
                            <p className="large-text">HTML Reports for each sample file will open in a new browser tab</p>
                            {values.map((htmlReport, index) => (
                                    <Box key={index} className="report-group">
                                        <Box className="report">
                                            <a style={{ wordBreak: "break-all" }} className="blue-link" target="_blank" href={htmlReport.preSignedUrl} rel="noreferrer" ><p style={{ fontSize: "14px" }}>{htmlReport.file.substring(htmlReport.file.lastIndexOf('/') + 1)}</p></a>
                                        </Box>
                                    </Box>
                            ))}
                        </Box>
                    </Paper>): (
                        <div className="no-reports">No reports available</div>
                    )))
            }
        </div>
    );
}

export default HTMLReports;
