import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { USER_MESSAGE } from "../../../actions/User";
import moment from "moment";
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';

const addTagBySample = async (variables) => {
    const { fileName, pipelineName, tagName } = variables;
    const postData = {
        fileName: fileName,
        pipelineName: pipelineName,
        tagName: tagName
    };
    const headerConfig = setHeaders();

    const { data } = await axios
    .post(`${config.ENDPOINTS["main"]}/file/tag`, postData, {
        headers: headerConfig
    })

    return data;
}

const useMutateSampleTagAdd = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation(addTagBySample, {
        onSuccess: () => {
            queryClient.invalidateQueries("tags");
            queryClient.invalidateQueries("ALLSAMPLEFILES");
            dispatch({
                type: USER_MESSAGE,
                msgType: "success",
                text: "sample.tagAdded",
                key: moment().unix()
            });

        },
        onError: () => {
            dispatch({
                type: USER_MESSAGE,
                msgType: "error",
                text: "tag.409",
                key: moment().unix()
            });
        }
    })
}

export default useMutateSampleTagAdd;
