import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
    fullColumn: {
        flexBasis: "100%"
    },
}));

export const Form = ({ children, ...props }) => {
    const styles = useStyles();

    return (
        <form {...props} className={styles.fullColumn} noValidate>
            {children}
        </form>
    );
};
