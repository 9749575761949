import axios from "axios";
import * as config from "../../../app.config";
import { setHeaders } from "../../../utils/basic";
import { useQuery } from 'react-query';
import { useMsal } from "@azure/msal-react";


const fetchRecentExperiments = async () => {
    const headerConfig = setHeaders();
    let listUrl = `${config.ENDPOINTS["main"]}/experiments/list`;

    const { data } = await axios
        .get(listUrl, {
            headers: headerConfig
        })

    return data;
}

export default function useFetchRecentExperiments() {

  // eslint-disable-next-line no-unused-vars
  const { instance } = useMsal();
  

    return useQuery(["RECENTEXPERIMENTS"], fetchRecentExperiments, {
     //  enabled: Boolean(isAuthenticated),

       
        // onError: (err) => {
        //     if (err?.response && err.response.status === 401) {
        //         instance.logoutRedirect();
        //     }
        // }
    })
}
