import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { useForm, FormProvider } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { Box, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TwoDForm from "./2DForm.jsx";
import { Form } from "components/common/ReactHookForm/Form";
import { SubmitButton } from "components/common/ReactHookForm/SubmitButton";
import * as experimentActions from "actions/Experiments";
import * as pipelineActions from "actions/Pipelines";
import * as oktaActions from "actions/Okta";
import "scss/App.scss";
import moment from "moment";
import _ from "underscore";
import { useQueryClient } from "react-query";
import { flattenObject } from "utils/basic";
import * as config from "src/app.config";
import CreateOrEditExperiments from "./CreateOrEditExperiments";
import UploadExperimentFiles from "./UploadExperimentFiles";
import useFetchPipelineDataById from "components/Hooks/Pipelines/useFetchPipelineDataById.js";
import useMutateSaveExperiment from "components/Hooks/Experiment/useMutateSaveExperiment.js";
import ExperimentDetail from "../Details/index.jsx";
import * as actionsAdd from "actions/ExperimentAdd";

import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  fullColumn: {
    flexBasis: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    margin: theme.spacing(),
  },
  progress: {
    margin: theme.spacing(),
  },
}));

const ExperimentAdd = (props) => {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();

  const {
    formStep,
    pipelineId,
    experimentTitle,
    pollingStatus,
    arrowImage,
    disable,
    disablePipeline,
    duplicateExperimentName,
    allFiles,
    newTags,
  } = experimentState;

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const methods = useForm();
  const styles = useStyles();
  const { formState, handleSubmit } = methods;
  const pipelineValues = useFetchPipelineDataById(pipelineId);
  const saveExperiment = useMutateSaveExperiment();

  const {
    mode,
    actions,
    submitSucceeded,
    executionStatus,
    experiments: { experimentId },
  } = props;
  const validPendingStatuses = ["RUNNING", "INITIALIZING"];
  const pipelineData = config.PIPELINEDATA;

  const onSubmit = (data) => {
    let initData = {
      experimentDateProcessed: new Date(
        `${moment().format("YYYY-MM-DD")}T12:00:00Z`
      ),
      pipelineName: "SEQuoia Complete ERCC||0.1.13",
    };
    const remove2DdObj = _.omit(data, "2d");
    const tempData = { remove2DdObj, ...initData };
    const experimentData = flattenObject(tempData, {});
    const pipelineData = pipelineValues?.data?.data[0];
    dispatch(
      experimentActions.executeWorkflow(
        experimentTitle,
        experimentData,
        pipelineData,
        allFiles,
        newTags
      )
    );
  };

  useEffect(() => {
    const pendingPipeline =
      (executionStatus &&
        validPendingStatuses.includes(executionStatus.toUpperCase())) ||
      false;

    if (experimentId) {
      dispatchEvent({
        type: actionsAdd.SET_EXPERIMENT_ID,
        payload: experimentId,
      });
    }

    if (submitSucceeded && pendingPipeline) {
      dispatchEvent({ type: actionsAdd.SET_POLLING_STATUS, payload: true });
    } else {
      dispatchEvent({ type: actionsAdd.SET_POLLING_STATUS, payload: false });
    }
  }, [pollingStatus, executionStatus, pipelineData, experimentId]);

  useEffect(() => {
    // run on mount
    clearAddExperiment();
    actions.setexperimentId(props.experimentId);
    return () => {
      // run on unmount
      clearAddExperiment();
    };
  }, []);

  const clearAddExperiment = (e) => {
    if (e) {
      e.preventDefault();
    }
    actions.clearAddExperiment();
    actions.clearUploadFiles();
    actions.resetPipelines();
    dispatchEvent({ type: actionsAdd.SET_POLLING_STATUS, payload: false });
  };

  const handleSaveChanges = () => {
    // here by directly dispatching to reducer like in the above example we can get rid of the action file totally or call functions like below
    // left this as is for now
    dispatchEvent({
      type: actionsAdd.SET_DUPLICATE_EXPERIMENT_NAME,
      payload: "",
    });

    const pipelineId = pipelineValues?.data?.data[0]?.id;
    const tags = newTags;
    const data = { experimentId, experimentTitle, pipelineId, allFiles, tags };

    saveExperiment.mutate(data, {
      onError: (error) => {
        queryClient.invalidateQueries("RECENTEXPERIMENTS");
        if (error.message.data === "Experiment Name is already taken") {
          dispatchEvent({
            type: actionsAdd.SET_DUPLICATE_EXPERIMENT_NAME,
            payload: true,
          });
        }
      },
    });
    dispatchEvent({ type: actionsAdd.SET_DISABLE, payload: true });
  };

  return (
    <>
      {formStep === 0 && <CreateOrEditExperiments mode={mode} />}
      {formStep === 1 && <UploadExperimentFiles mode={mode} />}
      {formStep === 2 && (
        <StyledEngineProvider injectFirst>
          <div id="wrapper">
            {!formState.isSubmitSuccessful && (
              <span>
                <FormProvider {...methods}>
                  <Box>
                    <Grid container>
                      <div className={styles.fullColumn}>
                        <TwoDForm mode={mode} />
                      </div>
                    </Grid>
                  </Box>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Box style={{ marginLeft: "135px" }} className="footer">
                      <Box className="footer-content">
                        <Button
                          style={{
                            textTransform: "none",
                            textDecoration: "none",
                            cursor: "pointer",
                            color: "#0064b0"
                          }}
                          disabled={
                            experimentTitle.length < 5 ||
                            duplicateExperimentName !== ""
                          }
                          className="blue-link"
                          onClick={() =>
                            dispatchEvent({
                              type: actionsAdd.SET_FORM_STEP,
                              payload: (cur) => cur - 1,
                            })
                          }
                          onMouseOver={() =>
                            dispatchEvent({
                              type: actionsAdd.SET_ARROW_IMAGE,
                              payload: "LeftArrowHover",
                            })
                          }
                          onMouseOut={() =>
                            dispatchEvent({
                              type: actionsAdd.SET_ARROW_IMAGE,
                              payload: "LeftArrow",
                            })
                          }
                          href=""
                        >
                          <img
                            className="link-image"
                            alt="Back"
                            src={arrowImage}
                          />
                          Back: Add Sample Files
                        </Button>
                      </Box>

                      <Box
                        style={{ position: "absolute", right: "240px" }}
                        className="footer-content"
                      >
                        <Button
                          onClick={handleSaveChanges}
                          id="blue-button"
                          //className="blue-button"
                          disabled={disable || duplicateExperimentName !== ""}
                          style={{ marginRight: "32px" }}
                        >
                          SAVE CHANGES
                        </Button>
                        <SubmitButton
                          color="primary"
                          id="pipeline-button"
                          disabled={
                            duplicateExperimentName !== "" || disablePipeline
                          }
                        >
                          Start Pipeline
                        </SubmitButton>
                      </Box>
                    </Box>
                  </Form>
                </FormProvider>
              </span>
            )}
            {formState.isSubmitSuccessful && (
              <ExperimentDetail experimentId={experimentId} status="Not Started" pollingStatus="pollQuick" />
            )}
          </div>
        </StyledEngineProvider>
      )}
    </>
  );
};

const stateToProps = (state) => ({
  fileReducer: state.fileReducer,
  experiments: state.experiments,
  pipelines: state.pipelines,
  executionStatus: state.experiments?.sample?.data?.status || "Initializing",
});

const dispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    Object.assign({}, experimentActions, oktaActions, pipelineActions),
    dispatch
  ),
});

export default compose(connect(stateToProps, dispatchToProps))(ExperimentAdd);
