export const RecentExperimentsTableOptions = {
    options: {
        selectableRows: 'none',
        pagination: false,
        toolbar: false,
        displaySelectToolbar: false
    },
    themeOptions: {
        overrides: {
            MUIDataTable: {
                tableRoot: {
                  marginBottom: '0'
                },
                responsiveBase: {
                    height: '470px'
                },
            },
            MuiTableHead: {
                root: {
                    backgroundColor: '#fff !important'
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    backgroundColor: '#fff !important'
                }
            },
            MuiToolbar: {
                root: {
                    display: 'none'
                }
            }
        }
    }
}

export const TableOptions = {
    options: {
        filterType: 'textField',
        rowsPerPage: localStorage.getItem('rowsPerPage') ? localStorage.getItem('rowsPerPage'): 5,
        rowsPerPageOptions: [5, 10, 15],
        selectableRows: 'none',
        print: true,
        viewColumns: true,
        searchAlwaysOpen:true,
        searchPlaceholder: 'Search',
        downloadOptions: {
            filterOptions: {
                useDisplayedColumnsOnly: true
            },
        },
        sortOrder: {
            name: 'createdAt',
            direction: 'desc',
        },
        onChangeRowsPerPage: (numberOfRows) => {
            localStorage.setItem('rowsPerPage', numberOfRows);
        },
        onColumnSortChange: (changedColumn, direction) => {
            localStorage.setItem('sortOrder', JSON.stringify({ name: changedColumn, direction: direction }));
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            data.map(dataitem => {
                dataitem.data.forEach((item, idx) => {
                    let str = '', tailEnd;
                    if(Array.isArray(item)) {
                        item.forEach((itemx, index) => {
                            index === item.length - 1 ? tailEnd = '' : tailEnd = ', ';
                            str += `${itemx.tagName}${tailEnd}`
                        });
                        dataitem.data[idx] = str;
                    }
                })
            })
            return "\uFEFF" + buildHead(columns) + buildBody(data);
        }
    },
    themeOptions: {
        overrides: {
            MUIDataTable: {
                tableRoot: {
                    marginBottom: '0'
                },
                responsiveBase: {
                    height: '485px !important',
                    overflowX: 'hidden'
                }
            },
            MuiTableHead: {
                root: {
                    background: '#fff',
                    border: 'none !important'
                }
            },
            MuiTableCell: {
                head: {
                    fontWeight: '700'
                }
            },
            MUIDataTableHeadCell: {
                toolButton: {
                    fontWeight: '700'
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    backgroundColor: '#fff !important'
                }
            },
            MUIDataTableToolbar: {
                left: {
                position: 'absolute',
                right: '230px'
                }
            },
            MuiTableSortLabel: {
                root: {
                    position: 'relative',
                }
            },
            MuiTablePagination: {
                selectRoot: {
                    width: '45px'
                },
                select: {
                    paddingLeft: '0',
                    paddingBottom: '11px'
                }
            },
            MUIDataTableSearch: {
                main: {
                    width: '200px',
                    border: '1px solid #c2c2c2',
                    borderRadius: '5px',
                    padding: '0 0 0 10px'
                },
                clearIcon: {
                    display: 'none'
                },
                searchIcon: {
                    marginBottom: '10px'
                }
            },
            MuiInputBase: {
                root: {
                    marginTop: '5px',
                '&::before': {
                    border: 'none !important'
                },
                '&::after': {
                    border: 'none !important'
                },
                },
                searchText: {
                    input: {
                        marginTop: '12px',
                        boxShadow: 'none',
                        width: '200px',
                        '&[type=text]:focus': {
                            border: 'none !important',
                            boxShadow: 'none'
                        }
                    }
                }
            }
        }
    }
    
}

export const ExistingFilesTableOptions = {
    themeOptions: {
        overrides: {
            MUIDataTable: {
                tableRoot: {
                  marginBottom: '0'
                },
                responsiveBase: {
                    height: '270px !important'
                }
            },
            MuiToolbar: {
                root: {
                    display: 'none'
                }
            },
            MUIDataTableToolbarSelect: {
                root: {
                    display: 'none'
                }
            },
            MuiPaper: {
                elevation4: {
                    boxShadow: 'none'
                }
            },
            MUIDataTableBodyRow: {
                root: {
                    backgroundColor: '#f1f1f1 !important'
                }
            }
            
        }
    }
}