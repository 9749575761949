import { produce } from 'immer';
import { USER_MESSAGE } from "../actions/User";

const initialState = {
  message: { msgType: "", text: "", key: "" }
};

const users = produce((draft = initialState, action) => {
  const { type } = action;
  switch (type) {

    case USER_MESSAGE: {

      draft.message = { msgType: action.msgType, text: action.text, key: action.key };
      return draft;
    }
    default: {
      return draft;
    }
  }
});

export default users;
