import { LinearProgress, TablePagination } from "@mui/material";
import React from "react";
import {
  useExperimentsContext,
  useExperimentsDispatchContext,
} from "contexts/ExperimentContext";
import PropTypes from "prop-types";
import * as actions from "actions/ExperimentAdd";
import DoneIcon from "@material-ui/icons/Done";
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from "@material-ui/icons/Warning";
import { FormattedMessage } from "react-intl";
import _ from "underscore";
import { useIsMutating } from "react-query";

export function ExistingTabFooter({ classes, data }) {
  const experimentState = useExperimentsContext();
  const dispatchEvent = useExperimentsDispatchContext();
  const isMutatingSaveExp = useIsMutating(['saveExp']);
  const { selected, page, rowsPerPage, uploadFiles, retry } = experimentState;

  const handleChangePage = (event, newPage) => {
    dispatchEvent({ type: actions.SET_PAGE, payload: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatchEvent({
      type: actions.SET_ROWS_PER_PAGE,
      payload: parseInt(event.target.value, 10),
    });
    dispatchEvent({ type: actions.SET_PAGE, payload: 0 });
  };

  const callOutFailure = _.size(retry) > 0 && (
    <div className="callout alert">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-1">
          <WarningIcon />
        </div>
        <div className="cell auto">
          <FormattedMessage
            id="experiment.add.uploadFailure"
            values={{ num: _.size(retry) }}
          />
        </div>
      </div>
    </div>
  );
  const callOutSuccess = _.size(uploadFiles) > 0 && isMutatingSaveExp  === 0 && (
    <div className="callout success">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-1">
          <DoneIcon />
        </div>
        <div className="cell auto">
          <FormattedMessage
            id="experiment.add.copySuccess"
          //  values={{ num: _.size(uploadFiles) }}
          />
        </div>
      </div>
    </div>
  );

  const callOutSaving = isMutatingSaveExp !==0 && (

    <div className="callout primary">
      <div className="grid-x grid-margin-x align-middle">
        <div className="cell small-1">
          <CircularProgress />
        </div>
        <div className="cell auto">
          <FormattedMessage
            id="experiment.save.in.progress"        
          />
        </div>
      </div>
    </div> 
  );

  const showLoading =
    selected.length > 0 && uploadFiles.length < selected.length;

  const callOutCopy = (
    <div
      style={{
        display: "flex",
        flex: "1",
        padding: "10px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {showLoading && "SeqSense is preparing the sample files for your next experiment."}
    </div>
  );

  return (
    <div>
      {callOutCopy}
      {callOutFailure}
      {callOutSuccess}
      {callOutSaving}
      {showLoading && (
        <div style={{ textAlign: "center" }}>
          <LinearProgress color="success" />
        </div>
      )}
      <div className={classes.tableFooter}>
        <TablePagination
          style={{
            border: "0px",
          }}
          component="div"
          className={"existingTabPagination"}
          count={data?.data?.length || 0}
          page={page}
          onPageChange={handleChangePage}
          SelectProps={{
            "data-testid": "rowsPerPage",
          }}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`
          }
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

ExistingTabFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
};

ExistingTabFooter.defaultProps = {
  data: {},
};
