
import * as ExperimentAddActions from "actions/ExperimentAdd";
import * as experimentActions from "actions/Experiments";

export function handleFileUploadError(error, dispatchEvent, dispatchAction, file) {
    if (error?.response?.status === 409) {
        // File already exists in the databank
        console.error("File already exists:", error.response.data);
        dispatchEvent({
            type: ExperimentAddActions.SET_FILE_EXISTS,
            payload: true,
        });
        dispatchAction({
            type: experimentActions.FILE_UPLOAD_FAILED,
            payload: file.name,
        });
    } else if (error?.response?.status === 401) {
        console.error("Token Refreshed while Uploading:", error);
        dispatchAction({
            type: experimentActions.FILE_UPLOAD_FAILED,
            payload: file.name,
            errorCode: 401 // Indicating that this failure was due to auth error
        });
    } else if (error?.response?.status === 404) {
        // File not found in the databank
        console.error("File not found:", error.response.data);
        dispatchEvent({
            type: experimentActions.SET_FILE_REUPLOAD,
            payload: error.response.data.File, // Get the 'File' field from the response data
        });
        dispatchAction({
            type: experimentActions.FILE_UPLOAD_FAILED,
            payload: file.name,
        });
    } else {
        // Any other errors from the API's
        console.error("Error during file upload:", error);
        dispatchAction({
            type: experimentActions.FILE_UPLOAD_FAILED,
            payload: file.name,
        });
    }
}